import { novaApi } from '../../services/novaApi'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getIllustrations: builder.query({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth } = api.getState()

				const params = new URLSearchParams()

				if (auth.isFassUser === true && arg.agent !== '') {
					params.append('agent', arg.agent)
				} else {
					params.append('agent', auth.agent)
				}

				params.append('page', arg.page)
				params.append('per_page', arg.per_page)
				params.append('search', arg.search)

				try {
					const response = await baseQuery(
						{ url: `/illustrations?${params.toString()}`, method: 'GET' },
						api,
						extraOptions,
					)

					return response
				} catch (error) {
					return error
				}
			},
			providesTags: (result, error, arg) => {
				const data = [{ type: 'Illustration', id: 'LIST' }]

				if (!error) {
					return [...data, ...result.data.map(({ qid }) => ({ type: 'Illustration', id: qid }))]
				}

				return data
			},
		}),
	}),
})

export const { useGetIllustrationsQuery } = extendedApiSlice
