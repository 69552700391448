import React from 'react'
import { Container, Divider, Header } from 'semantic-ui-react'
import ProductList from '../product/ProductList'

const Home = () => {
	return (
		<Container className='page'>
			<Header size='huge'>Products</Header>
			<Divider hidden section />
			<ProductList />
		</Container>
	)
}

export default Home
