import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	Button,
	Divider,
	Grid,
	Header,
	List,
	Segment,
	SegmentGroup,
	Table,
} from 'semantic-ui-react'
import SummaryActions from '../../components/SummaryActions'
import {
	useCopyIllustrationMutation,
	useLazyCheckUnderwritingRequirementsQuery,
	useOpenUnderwritingRequirementsMutation,
	useSaveIllustrationMutation,
	useSubmitAsApplicationMutation,
	useUploadIllustrationMutation,
} from '../../services/novaApi'
import {
	formatCurrency,
	generateAdmedPdf,
	getAdmedDeathBenefitExtraRating,
	getAnnualPremiumCovered,
	getGender,
	getPaymentFrequencyText,
	getSmokerText,
	getSurgicalPackageText,
	getWpExtraRating,
	onOpenUnderwritingRequirements,
	useQuoteNotFound,
	version,
} from '../../utils'
import { admedQuoteUpdated } from './admedSlice'

const AdmedSummary = ({ personal, values, onBack }) => {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const { quote, plan, benefits, ratings, is_saved } = values
	const { appkey, swdate } = useSelector(state => state.auth)

	const [saveIllustration, { isLoading: isSaving }] = useSaveIllustrationMutation()
	const [uploadIllustration, { isLoading: isUploading }] = useUploadIllustrationMutation()
	const [copyIllustration, { isLoading: isCopying }] = useCopyIllustrationMutation()
	const [initializeUW, { isLoading: isInitializingUW }] = useOpenUnderwritingRequirementsMutation()
	const [submitAsApplication, { isLoading: isSubmitting }] = useSubmitAsApplicationMutation()
	const [checkRequirements, { isFetching: isCheckingReqs }] =
		useLazyCheckUnderwritingRequirementsQuery()

	const hasOccFprem = Number(quote?.occfprem) > 0
	const hasMedFprem = Number(quote?.medfprem) > 0
	const hasAvoFprem = Number(quote?.avofprem) > 0

	const isNewIllustration = !!swdate && new Date() >= new Date(swdate)

	const isChildIllustration = Number(personal.issue_age) < 18

	const isCompanyIllustration = personal.applicant_sex === 'C'

	const isAnnuityIllustration = false

	const disableStart = !isAnnuityIllustration && (isChildIllustration || isCompanyIllustration)

	const disableSubmit = isAnnuityIllustration || isNewIllustration

	const handleCopyIllustration = async () => {
		if (values.is_saved === false) {
			await handleSave()
		}

		try {
			await copyIllustration({ ids: quote?.qid, agent: personal.agent }).unwrap()

			toast.success('Illustration copied')
		} catch (err) {
			if (!!err?.data?.msg) {
				toast.error(err.data.msg)
				return
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleSave = async () => {
		if (!!values.is_saved) {
			return
		}
		try {
			await saveIllustration({ qid: quote?.qid }).unwrap()

			dispatch(admedQuoteUpdated({ is_saved: true }))

			toast.success('Illustration saved.')
		} catch (error) {
			if (!!error?.data?.msg) {
				toast.error(error.data.msg)

				throw error
			}
			toast.error('Server error, please contact technology services')
			throw error
		}
	}

	const handleSaveAndClose = async () => {
		try {
			if (!is_saved) {
				await handleSave()
			}
			navigate('/')
		} catch (error) {}
	}

	const handleStart = async () => {
		try {
			let res = await checkRequirements(quote?.qid).unwrap()
			if (res.uwreqs === false) {
				return toast.error('Underwriting requirements must be completed first!!')
			}

			// upload the illustration to the server
			const doc = generateAdmedPdf({ ...personal, ...values })

			const blob = doc.output('blob')

			const file = new Blob([blob], { type: 'application/pdf' })

			await uploadIllustration({ file, qid: quote?.qid }).unwrap()

			// save the illustration if it isn't saved
			handleSave()

			// open eapps in a new tab
			window.open(
				`${process.env.REACT_APP_EAPPS_V2_URL}?agtno=${personal.agent}&cltno=${personal.client}&appclient=${personal.applicant_client}&qid=${quote.qid}&token=${appkey}&isNew=true`,
			)

			// go back to the plan screen
			navigate('/product/admed/plan')
		} catch (error) {
			if (!!error?.data?.msg) {
				return toast.error(error.data.msg)
			}
			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleDownload = async () => {
		const doc = generateAdmedPdf({ ...personal, ...values })

		const blob = doc.output('blob')

		const file = new Blob([blob], { type: 'application/pdf' })

		try {
			toast.info('Uploading illustration')

			await uploadIllustration({ file, qid: quote?.qid })
		} catch (err) {
			if (err?.data?.msg) {
				toast.error(err.data.msg)
			} else {
				toast.error('Error uploading illustration')
			}
		}

		doc.save(`Admed - ${quote?.qid || ''}${version}`)

		toast.success('Illustration downloaded.')
	}

	const handleOpenUnderwritingRequirements = async () => {
		let qid = quote?.qid
		try {
			await initializeUW({ qid }).unwrap()
			onOpenUnderwritingRequirements(qid)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const onSubmitAsApplication = async () => {
		try {
			const { msg } = await submitAsApplication({ qid: quote?.qid }).unwrap()
			toast.info(msg)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Sever error, please contact technology services')
		}
	}

	const handleOpen = () => {
		window.open(
			`${process.env.REACT_APP_EAPPS_V2_URL}?policy=${quote?.policy}&resume=true&token=${appkey}`,
		)
	}

	useQuoteNotFound(quote?.qid)

	return (
		<div className='summary'>
			<SummaryActions
				isLoading={
					isSaving || isUploading || isCopying || isInitializingUW || isSubmitting || isCheckingReqs
				}
				isSaved={is_saved}
				disableSubmit={disableSubmit}
				disableStart={disableStart}
				hasApplication={quote?.is_app === '1'}
				handleSave={handleSave}
				handleSaveAndClose={handleSaveAndClose}
				handleDownload={handleDownload}
				handleStart={handleStart}
				handleOpen={handleOpen}
				handleCopy={handleCopyIllustration}
				handleUnderwritingReqirements={handleOpenUnderwritingRequirements}
				handleSubmitAsApp={onSubmitAsApplication}
			/>
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Personal Information</Header>
				</Segment>
				<Segment padded>
					<Header size='small'>
						{personal.first_name} {personal.last_name}
					</Header>
					<Divider hidden />
					<Grid columns={4} divided stackable>
						<Grid.Column>
							<Header sub>Sex</Header>
							<span>{getGender(personal.sex)}</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Smoker</Header>
							<span>{getSmokerText(personal.smoker)}</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Age</Header>
							<span>{personal.issue_age} years</span>
						</Grid.Column>
					</Grid>
				</Segment>
			</SegmentGroup>
			<Divider hidden />
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Plan Information</Header>
				</Segment>
				<Segment padded>
					<List divided relaxed className='summary-list'>
						<List.Item>
							<List.Content floated='right'>
								{getSurgicalPackageText(plan?.surgical_package)}
							</List.Content>
							<List.Content>Surgical Package</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(plan.death_benefit, 0)}</List.Content>
							{!!getAdmedDeathBenefitExtraRating(quote) && (
								<List.Content floated='right'>
									<span>
										<em>{getAdmedDeathBenefitExtraRating(quote)}</em>
										<span style={{ marginLeft: '10px' }}>|</span>
									</span>
								</List.Content>
							)}
							<List.Content>Death Benefit</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(plan.dread_disease, 0)}</List.Content>

							<List.Content floated='right'>
								<em style={{ marginRight: '10px' }}>
									Standard Rate {getWpExtraRating(quote?.ratings?.adm?.dread)}
								</em>
								<span>|</span>
							</List.Content>

							<List.Content>Dread Disease</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.illustration_values?.benefits?.LIMITAE, 0)}
							</List.Content>
							<List.Content>Accident &amp; Emergency Benefit Limit</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.illustration_values?.benefits?.LIMITHD, 0)}
							</List.Content>
							<List.Content>Hospital Diagnostics Benefit Limit</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.illustration_values?.benefits?.LIMIT5YEAR, 0)}
							</List.Content>
							<List.Content>Surgical 5 year limit</List.Content>
						</List.Item>
					</List>
					<Divider section />
					<List divided relaxed className='summary-list'>
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.illustration_values?.benefits?.DEDUCTIBLE)}
							</List.Content>
							<List.Content>Deductible</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>
								{getPaymentFrequencyText(personal.payment_frequency)}
							</List.Content>
							<List.Content>Payment Frequency</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(quote?.standard_fprem)}</List.Content>
							<List.Content>Standard Frequency Premium</List.Content>
						</List.Item>
						{hasOccFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.occfprem)}</List.Content>
								<List.Content>Occupational Extra Premium</List.Content>
							</List.Item>
						)}
						{hasMedFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.medfprem)}</List.Content>
								<List.Content>Medical Extra Premium</List.Content>
							</List.Item>
						)}
						{hasAvoFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.avofprem)}</List.Content>
								<List.Content>Avocation Extra Premium</List.Content>
							</List.Item>
						)}
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.frequency_premium)}
							</List.Content>
							<List.Content>Total Frequency Premium</List.Content>
						</List.Item>
					</List>
				</Segment>
			</SegmentGroup>

			<>
				<Divider hidden />
				<SegmentGroup>
					<Segment basic style={{ padding: 0 }}>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Supplementary Benefit</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
									<Table.HeaderCell>Extra Rating</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell>ADmed Accident and Emergency Services Benefit</Table.Cell>
									<Table.Cell>{`Deductible:
										${formatCurrency(quote?.illustration_values?.benefits?.DEDUCTIBLE, 0)} | Limit ${formatCurrency(
										quote?.illustration_values?.benefits?.LIMITAE,
										0,
									)}`}</Table.Cell>
									<Table.Cell>Standard Rate {getWpExtraRating(ratings.adm.accident)}</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell>ADmed Hospital Diagnostics Benefit</Table.Cell>
									<Table.Cell>
										{`Deductible:
										${formatCurrency(quote?.illustration_values?.benefits?.DEDUCTIBLE, 0)} | Limit ${formatCurrency(
											quote?.illustration_values?.benefits?.LIMITHD,
											0,
										)}`}
									</Table.Cell>
									<Table.Cell>Standard Rate</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell>ADmed Surgical Benefit</Table.Cell>
									<Table.Cell>Package: {getSurgicalPackageText(plan.surgical_package)}</Table.Cell>
									<Table.Cell>Standard Rate {getWpExtraRating(ratings.adm.surgical)}</Table.Cell>
								</Table.Row>
								{!!benefits.rr.applicant_age && (
									<Table.Row>
										<Table.Cell>Retrenchment Rider</Table.Cell>
										<Table.Cell>
											{`Annual Premium Covered: ${getAnnualPremiumCovered(
												benefits.rr.additional_premium,
												quote?.frequency_premium,
												personal.payment_frequency,
											)}`}
										</Table.Cell>
										<Table.Cell>Standard Rate</Table.Cell>
									</Table.Row>
								)}
							</Table.Body>
						</Table>
					</Segment>
				</SegmentGroup>
			</>

			<Divider hidden />
			<Button negative floated='right' onClick={onBack}>
				Back
			</Button>
		</div>
	)
}

export default AdmedSummary
