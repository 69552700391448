import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import { useGetDDROptionsQuery } from '../../services/novaApi'

const DDRRate3 = ({ qid, age, sex, smoker, frequency, product, applicationDate: app_date }) => {
	const { control, watch, setValue } = useFormContext()

	const rate1 = watch('benefits.ddr.rate_1', '0')
	const rate2 = watch('benefits.ddr.rate_2', '0')
	const base_sa = watch('plan.sum_assured', '0')
	const clife_sa = watch('benefits.cl.sum_assured', '0')

	const { data, isLoading, isFetching } = useGetDDROptionsQuery(
		{
			qid,
			age,
			sex,
			smoker,
			frequency,
			base_sa: !!!base_sa ? '0' : base_sa,
			clife_sa: !!!clife_sa ? '0' : clife_sa,
			ddr_total: Number(rate1) + Number(rate2),
			app_date,
			product,
			rate: 3,
		},
		{ refetchOnMountOrArgChange: true },
	)

	return (
		<Controller
			control={control}
			name='benefits.ddr.rate_3'
			render={({ value, onChange, onBlur }) => (
				<Form.Select
					label='Acceleration'
					value={value}
					onChange={(event, data) => {
						onChange(data.value)
						setValue('benefits.ddr.rate_4', '')
					}}
					onBlur={onBlur}
					options={data || []}
					fluid
					loading={isLoading || isFetching}
					search
					clearable
				/>
			)}
		/>
	)
}

export default DDRRate3
