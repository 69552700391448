import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { defaultsDeep, merge } from 'lodash'
import { resetProduct } from '../../app/actions'
import { novaApi } from '../../services/novaApi'
import { logout } from '../auth/authSlice'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getTriflexAnnuityIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth, triflexAnnuity } = api.getState()
				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent
				const qid = triflexAnnuity?.quote?.qid || ''
				try {
					const response = await baseQuery(
						{
							url: 'triflex-annuity',
							method: 'POST',
							body: { ...arg, qid, agent, current_agent, is_fass_user },
						},
						api,
						extraOptions,
					)
					return response
				} catch (error) {
					return error
				}
			},
		}),
	}),
})

export const { useGetTriflexAnnuityIllustrationMutation } = extendedApiSlice

const initialState = {
	plan: {
		premium: '',
		type: '',
		pay_type: '',
		year_start: '',
		year_end: '',
		retirement_age: '',
		additional_deposit: '',
	},
	benefits: {
		wp: false,
		rr: {
			applicant_age: '',
			additional_premium: '',
		},
	},
	ratings: {
		wp: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
	},
	quote: null,
	is_saved: false,
}

const triflexAnnuitySlice = createSlice({
	name: 'triflexAnnuity',
	initialState,
	reducers: {
		triflexAnnuityFormUpdated: (state, action) => {
			let newState = cloneDeep(defaultsDeep(action.payload, initialState))

			newState.benefits.wp = newState.benefits?.wp || false

			// prevents resetting quote when illustration is in an error state
			if (!!!newState.quote && !!state.quote) {
				newState.quote = { ...state.quote }
			}

			return merge(state, newState)
		},
		triflexAnnuityQuoteUpdated: (state, action) => {
			return merge(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(resetProduct, () => {
				return initialState
			})
			.addCase(logout, state => initialState)
	},
})

export const { triflexAnnuityFormUpdated, triflexAnnuityQuoteUpdated } = triflexAnnuitySlice.actions
export const selectTriflexAnnuity = state => state.triflexAnnuity

export default triflexAnnuitySlice.reducer
