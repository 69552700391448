import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'
import { extraRatingsType2 } from '../../utils'

const WddaRider = ({ issueAge }) => {
	const { control, errors, watch } = useFormContext()

	const applicantAge = watch('benefits.wdda.applicant_age')
	const duration1 = 25 - Number(issueAge)
	const duration2 = 60 - Number(applicantAge)
	const durationLower = 10
	const durationUpper = duration1 > duration2 ? duration1 : duration2

	return (
		<Benefit header='WDDA'>
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='benefits.wdda.applicant_age'
					rules={{
						required: 'Please enter applicant age',
						pattern: {
							value: /^\d+$/,
							message: 'Decimals not allowed',
						},
						min: { value: 20, message: 'Must be between 20 and 50 inclusive' },
						max: { value: 50, message: 'Must be between 20 and 50 inclusive' },
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Field error={!!errors?.benefits?.wdda?.applicant_age}>
							<label htmlFor='benefits.wdda.applicant_age'>Applicant Age</label>
							<Input
								name='benefits.wdda.applicant_age'
								id='benefits.wdda.applicant_age'
								type='number'
								step='1'
								fluid
								value={value}
								onChange={onChange}
								onBlur={onBlur}
							/>
							{!!!errors?.benefits?.wdda?.applicant_age && (
								<Label pointing>Must be between 20 and 50 inclusive</Label>
							)}
							<FieldError errors={errors} name='benefits.wdda.applicant_age' />
						</Form.Field>
					)}
				/>
				<Controller
					control={control}
					name='benefits.wdda.duration'
					rules={{
						required: 'Please enter the duration',
						pattern: {
							value: /^\d+$/,
							message: 'Decimal numbers not allowed',
						},
						min: {
							value: durationLower,
							message: `Must be between ${durationLower} and ${durationUpper}`,
						},
						max: {
							value: durationUpper,
							message: `Must be between ${durationLower} and ${durationUpper}`,
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Field error={!!errors?.benefits?.wdda?.duration}>
							<label htmlFor='benefits.wdda.duration'>Duration</label>
							<Input
								name='benefits.wdda.duration'
								id='benefits.wdda.duration'
								type='number'
								step='1'
								fluid
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								error={!!errors?.benefits?.wdda?.duration && errors.benefits.wdda.duration.message}
							/>
							{!!!errors?.benefits?.wdda?.duration && (
								<Label pointing>{`Must be between ${durationLower} and ${durationUpper}`}</Label>
							)}
							<FieldError errors={errors} name='benefits.wdda.duration' />
						</Form.Field>
					)}
				/>
			</Form.Group>
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.wdda.occrate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							label='Occupational Rate'
							options={extraRatingsType2}
							onChange={(event, data) => onChange(data.value)}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.wdda.medrate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							label='Medical Rate'
							options={extraRatingsType2}
							onChange={(event, data) => onChange(data.value)}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.wdda.avorate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							label='Avocation Rate'
							options={extraRatingsType2}
							onChange={(event, data) => onChange(data.value)}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default WddaRider
