import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import { useScrollOnErrors } from '../../utils'
import PersonalForm from '../personal/PersonalForm'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import ProductLayout from '../product/ProductLayout'
import LevelTermPlan from './LevelTermPlan'
import { levelTermPlanUpdated, levelTermQuoteUpdated, selectLevelTerm } from './levelTermSlice'
import { useGetLevelTermIllustrationMutation } from './levelTermSlice'
import LevelTermSummary from './LevelTermSummary'

const LevelTerm = () => {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const personalFormValues = useSelector(selectPersonal)

	const planValues = useSelector(selectLevelTerm)

	const [errors, setErrors] = useState([])

	const productName = 'term'

	const [illustrate, { isLoading, isError }] = useGetLevelTermIllustrationMutation({
		fixedCacheKey: 'term-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/term/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/term')
	}

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		setErrors([])

		dispatch(levelTermPlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(levelTermQuoteUpdated({ quote }))

			navigate('/product/term/summary')
		} catch (err) {
			if (err?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (err?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (err?.data) {
				const { messages, qid, frequency_premium, annual_premium } = err.data

				if (qid) {
					dispatch(levelTermQuoteUpdated({ qid, frequency_premium, annual_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onSummaryBack = () => {
		navigate('/product/term/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Level Term'
						breadcrumbTitle='Level Term'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<LevelTermPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>

				<Route
					path='summary'
					element={
						<LevelTermSummary
							personal={personalFormValues}
							values={planValues}
							onBack={onSummaryBack}
						/>
					}
				/>
				<Route path='*' element={<Navigate to='/product/term' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default LevelTerm
