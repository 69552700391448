import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep, defaultsDeep, merge } from 'lodash'
import { resetProduct } from '../../app/actions'
import { novaApi } from '../../services/novaApi'
import { logout } from '../auth/authSlice'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getAdmedIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth, admed } = api.getState()
				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent
				const qid = admed?.quote?.qid || ''
				try {
					const response = await baseQuery(
						{
							url: 'admed',
							method: 'POST',
							body: { ...arg, qid, agent, current_agent, is_fass_user },
						},
						api,
						extraOptions,
					)
					return response
				} catch (error) {
					return error
				}
			},
		}),
	}),
})

export const { useGetAdmedIllustrationMutation } = extendedApiSlice

const initialState = {
	is_saved: false,
	plan: {
		surgical_package: '1',
		maximum_benefit: '1',
		death_benefit: '',
		dread_disease: '',
		accident_emergency: '0',
		hospital_diagnostic: '0',
	},
	benefits: {
		rr: {
			applicant_age: '',
			additional_premium: '',
		},
	},
	ratings: {
		adm: {
			surgical: {
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
			accident: {
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
			death: {
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
			dread: {
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
		},
	},
}

const admedSlice = createSlice({
	name: 'admed',
	initialState,
	reducers: {
		admedPlanUpdated: (state, action) => {
			const newState = cloneDeep(defaultsDeep(action.payload, initialState))

			// prevents resetting quote when illustration is in an error state
			if (!!!newState.quote && !!state.quote) {
				newState.quote = { ...state.quote }
			}

			return merge(state, newState)
		},
		admedQuoteUpdated: (state, action) => {
			return merge(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(resetProduct, () => {
				return initialState
			})
			.addCase(logout, state => initialState)
	},
})

export const { admedPlanUpdated, admedQuoteUpdated } = admedSlice.actions
export const selectAdmed = state => state.admed
export default admedSlice.reducer
