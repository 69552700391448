import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { result } from 'lodash'
import { toast } from 'react-toastify'
import { REHYDRATE } from 'redux-persist'
import { logout } from '../features/auth/authSlice'

// add token to every request
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const { token } = getState().auth
		if (token) {
			headers.set('Authorization', `Bearer ${token}`)
		}
		return headers
	},
})

const customQuery = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions)

	// logout user if token is expired
	if (result.error && result.error.status === 401) {
		api.dispatch(logout())
		toast.error('Session Expired!')
	}
	return result
}

export const novaApi = createApi({
	reducerPath: 'novaApi',
	baseQuery: customQuery,
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === REHYDRATE && !!action.payload) {
			return action.payload[reducerPath]
		}
	},
	tagTypes: ['Illustration'],
	endpoints: builder => ({
		uploadIllustration: builder.mutation({
			query(arg) {
				const body = new FormData()
				body.append(`${arg.qid}`, arg.file, `${arg.qid}.pdf`)
				return {
					url: `pdf/${arg.qid}`,
					method: 'POST',
					body,
				}
			},
		}),
		saveIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth } = api.getState()

				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent

				try {
					const response = await baseQuery({
						url: `illustration/${arg.qid}/save`,
						method: 'POST',
						body: { ...arg, agent, current_agent, is_fass_user },
						api,
						extraOptions,
					})

					return response
				} catch (err) {
					return err
				}
			},
		}),
		copyIllustration: builder.mutation({
			query: arg => ({
				url: 'illustrations/copy',
				method: 'POST',
				body: arg,
			}),
			invalidatesTags: ['Illustration'],
		}),
		deleteIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth } = api.getState()

				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent

				try {
					const response = await baseQuery({
						url: 'illustrations',
						method: 'DELETE',
						body: {
							ids: arg.ids?.join(','),
							agent,
							current_agent,
							is_fass_user,
							page: arg.page,
							per_page: arg.per_page,
						},
						api,
						extraOptions,
					})

					return response
				} catch (err) {
					return err
				}
			},
			invalidatesTags: ['Illustration'],
		}),
		openUnderwritingRequirements: builder.mutation({
			query: arg => ({
				url: `/illustration/${arg.qid}/underwriting`,
				method: 'POST',
				body: arg,
			}),
		}),
		checkUnderwritingRequirements: builder.query({
			query: qid => ({
				url: `/illustration/${qid}/check-uwreqs`,
				method: 'GET',
			}),
		}),
		submitAsApplication: builder.mutation({
			query: arg => ({
				url: `illustration/${arg.qid}/submit`,
				method: 'POST',
				body: arg,
			}),
		}),
		login: builder.mutation({
			query: arg => ({
				url: 'login',
				method: 'POST',
				body: arg,
			}),
			invalidatesTags: ['Illustration'],
		}),
		validateSumAssured: builder.query({
			queryFn: async (args, api, extraOptions, baseQuery) => {
				let params = new URLSearchParams()

				for (const key in args) {
					params.append(key, args[key])
				}

				const url = `premium/validate/${args.product}?${params.toString()}`
				try {
					let response = await baseQuery({
						url,
						method: 'GET',
					})

					if (response.error && result.error.status === 400) {
						toast.error(response.error.msg)
					}

					if (
						response.error &&
						(result.error.status === 500 || result.error.status === 'FETCH_ERROR')
					) {
						toast.error('Something went wrong, contact technology services')
					}

					return response
				} catch (error) {
					toast.error('Something went wrong, contact technology services')
					return error
				}
			},
			keepUnusedDataFor: 0,
		}),
		validateAirLimit: builder.query({
			query: arg => ({
				url: `air/limit/validate?sum_assured=${arg.sum_assured}&ad_sum_assured=${arg.ad_sum_assured}`,
				method: 'GET',
			}),
		}),
		validateAdLimit: builder.query({
			query: arg => ({
				url: `ad/limit/validate?sum_assured=${arg.sum_assured}&units=${arg.units}`,
				method: 'GET',
			}),
		}),
		validateTriflexAdditionalDeposit: builder.mutation({
			query: ({ sum_assured, age, frequency }) => {
				let params = new URLSearchParams()
				params.append('sum_assured', sum_assured)
				params.append('age', age)
				params.append('frequency', frequency)

				return {
					url: `deposits/triflex/validate?${params.toString()}`,
					method: 'GET',
				}
			},
		}),
		getDDROptions: builder.query({
			query: arg => ({
				url: `ddr/${arg.product}?${new URLSearchParams(arg).toString()}`,
			}),
			extraOptions: { maxRetries: 3 },
		}),
	}),
})

export const {
	useUploadIllustrationMutation,
	useSaveIllustrationMutation,
	useDeleteIllustrationMutation,
	useCopyIllustrationMutation,
	useOpenUnderwritingRequirementsMutation,
	useSubmitAsApplicationMutation,
	useLoginMutation,
	useValidateSumAssuredQuery,
	useLazyValidateSumAssuredQuery,
	useGetDDROptionsQuery,
	useValidateAdLimitQuery,
	useValidateAirLimitQuery,
	useValidateTriflexAdditionalDepositMutation,
	useLazyCheckUnderwritingRequirementsQuery,
} = novaApi
