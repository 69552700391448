import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import { useScrollOnErrors } from '../../utils'
import PersonalForm from '../personal/PersonalForm'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import ProductLayout from '../product/ProductLayout'
import OlnpPlan from './OlnpPlan'
import { olnpPlanUpdated, olnpQuoteUpdated, selectOlnp } from './olnpSlice'
import { useGetOlnpIllustrationMutation } from './olnpSlice'
import OlnpSummary from './OlnpSummary'

const Olnp = () => {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const personalFormValues = useSelector(selectPersonal)

	const planValues = useSelector(selectOlnp)

	const [errors, setErrors] = useState([])

	const productName = 'olnp'

	const [illustrate, { isLoading, isError }] = useGetOlnpIllustrationMutation({
		fixedCacheKey: 'olnp-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))

		navigate('/product/olnp/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/olnp')
	}

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		setErrors([])

		dispatch(olnpPlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(olnpQuoteUpdated({ quote }))

			navigate('/product/olnp/summary')
		} catch (err) {
			if (err?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (err?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (err?.data) {
				const { messages, qid, frequency_premium, annual_premium } = err.data

				if (qid) {
					dispatch(olnpQuoteUpdated({ qid, frequency_premium, annual_premium }))
				}
				setErrors(messages ?? [])
			}
		}
	}

	const onSummaryBack = () => {
		navigate('/product/olnp/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Ordinary Life Non-Par'
						breadcrumbTitle='OLNP'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<OlnpPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>

				<Route
					path='summary'
					element={
						<OlnpSummary personal={personalFormValues} values={planValues} onBack={onSummaryBack} />
					}
				/>
				<Route path='*' element={<Navigate to='/product/olnp' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default Olnp
