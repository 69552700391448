import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './app/store'
import App from './App'
import './assets/arial-normal'
import './assets/arial-bold'
import './assets/arial-italic'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.querySelector('#root'),
)
