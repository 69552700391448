import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Dropdown, Image, Menu } from 'semantic-ui-react'
import { logout } from '../auth/authSlice'
import './Nav.css'

const Nav = () => {
	const dispatch = useDispatch()

	const handleLogout = () => dispatch(logout())

	const name = useSelector(state => state.auth.name)

	return (
		<Menu inverted size='huge' className='nav maritime' fixed='top'>
			<Container>
				<Menu.Item as={Link} to='/' header>
					<Image size='mini' src={`${process.env.REACT_APP_BASENAME}/logo.png`} className='logo' />
					NOVA
				</Menu.Item>
				<Menu.Menu position='right'>
					<Menu.Item as={Link} to='/illustrations'>
						Illustrations
					</Menu.Item>
					<Dropdown item simple text={name}>
						<Dropdown.Menu>
							<Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
							<Dropdown.Item as={Link} to='/help'>
								Help
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Menu.Menu>
			</Container>
		</Menu>
	)
}

export default Nav
