import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Form, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'
import { extraRatingsType1_9, termOptions } from '../../utils'

const TermRider = ({ issueAge }) => {
	const { control, errors } = useFormContext()

	const validTermOptions = termOptions.map(v => {
		if (!v.validate(issueAge)) {
			return { key: v.key, text: v.text, value: v.value, disabled: true }
		}

		return { key: v.key, text: v.text, value: v.value, disabled: false }
	})

	return (
		<Benefit header='Level Term'>
			<Controller
				control={control}
				name='benefits.lt.sum_assured'
				rules={{
					required: 'Enter sum assured',
					pattern: {
						value: /^\d+$/,
						message: 'Decimals not allowed',
					},
					min: {
						value: 75000,
						message: 'Must be between $75,000 and $4,999,999',
					},
					max: {
						value: 4999999,
						message: 'Must be between $75,000 and $4,999,999',
					},
				}}
				render={({ value, onChange, onBlur }) => (
					<Form.Field error={!!errors?.benefits?.lt?.sum_assured}>
						<label for='benefits.lt.sum_assured'>Sum Assured</label>
						<Input
							name='benefits.lt.sum_assured'
							id='benefits.lt.sum_assured'
							type='number'
							step='.25'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
						/>
						{!!!errors?.plan?.sum_assured && (
							<Label pointing>Must be between $75,000 and $4,999,999</Label>
						)}
						<FieldError errors={errors} name='benefits.lt.sum_assured' />
					</Form.Field>
				)}
			/>
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='benefits.lt.term'
					rules={{
						required: 'Select a plan',
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							label='Plan'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={validTermOptions}
							fluid
							search
							clearable
							error={!!errors?.benefits?.lt?.term && { content: errors.benefits.lt.term.message }}
						/>
					)}
				/>
				<Controller
					control={control}
					name='benefits.lt.type'
					rules={{
						required: 'Select a type',
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Field error={!!errors.benefits?.lt?.type}>
							<label>Type</label>
							<Button.Group fluid>
								<Button
									role='button'
									type='button'
									primary={value === '2'}
									onClick={() => {
										if (value === '2') {
											return onChange('')
										}
										onChange('2')
									}}
									onBlur={onBlur}
								>
									T-Life
								</Button>
								<Button
									role='button'
									type='button'
									primary={value === '1'}
									onClick={() => {
										if (value === '1') {
											return onChange('')
										}
										onChange('1')
									}}
									onBlur={onBlur}
								>
									E-Life
								</Button>
							</Button.Group>
							<FieldError errors={errors} name='benefits.lt.type' />
						</Form.Field>
					)}
				/>
			</Form.Group>

			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.lt.occrate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							type='number'
							step='.25'
							label='Occupational Rate'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors?.ratings?.lt?.occrate && errors.ratings.lt.occrate.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.lt.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							label='Medical Rate'
							options={extraRatingsType1_9}
							onChange={(event, data) => onChange(data.value)}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.lt.avorate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							type='number'
							step='.25'
							label='Avocation Rate'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors?.ratings?.lt?.avorate && errors.ratings.lt.avorate.message}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default TermRider
