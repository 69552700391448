import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import Benefit from '.'

const GuaranteedDeathBenefit = () => {
	const { control, setValue } = useFormContext()

	useEffect(() => {
		let initial = false

		if (!initial) {
			setValue('benefits.gdb', true, { shouldDirty: true })
		}

		return () => {
			initial = true
		}
	}, [setValue])

	return (
		<Benefit header='Guaranteed Death Benefit'>
			<Controller
				control={control}
				name='benefits.gdb'
				render={({ value, onChange, onBlur }) => (
					<Form.Checkbox
						label='This rider guarantees that the death benefit at any time shall not be less than the sum assured of the policy.'
						checked={value}
						onChange={onChange}
						onBlur={onBlur}
					/>
				)}
			/>
		</Benefit>
	)
}

export default GuaranteedDeathBenefit
