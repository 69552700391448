import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Form, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'
import { useValidateAdLimitQuery } from '../../services/novaApi'
import { extraRatingsType2, formatCurrency } from '../../utils'

const AccidentalDeathRider = () => {
	const { control, watch, errors } = useFormContext()

	const sum_assured = watch('plan.sum_assured', '0')
	const ad_sum_assured = watch('benefits.ad.sum_assured', '0')
	const units = watch('benefits.air.units', '0')

	const { isLoading, isFetching, data, isError, error } = useValidateAdLimitQuery(
		{
			sum_assured: Number(sum_assured).toString(),
			units: Number(units).toString(),
		},
		{ skip: !!ad_sum_assured },
	)

	useEffect(() => {
		if ((!isLoading || !isFetching) && isError) {
			let msg = error?.msg || 'Could not validate accidental death sum assured'
			toast.error(msg)
		}
	}, [isLoading, isFetching, error, isError])

	return (
		<Benefit header='Accidental Death'>
			<Controller
				control={control}
				name='benefits.ad.sum_assured'
				rules={{
					required: 'Enter sum assured',
					pattern: {
						value: /^\d+$/,
						message: 'Decimals not allowed',
					},
					max: {
						value: data?.limit,
						message: `Less than or equal to ${formatCurrency(data?.limit, 0)}`,
					},
				}}
				render={({ value, onChange, onBlur }) => (
					<Form.Field error={!!errors?.benefits?.ad?.sum_assured}>
						<label htmlFor='benefits.ad.sum_assured'>Sum Assured</label>
						<Input
							name='benefits.ad.sum_assured'
							id='benefits.ad.sum_assured'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							loading={isLoading || isFetching}
						/>
						{!!!errors?.benefits?.ad?.sum_assured && !!data && !isLoading && !isFetching && (
							<Label pointing>Must be less than or equal to {data?.limit}</Label>
						)}

						{(isLoading || isFetching) && <Label pointing>Loading...</Label>}

						<FieldError errors={errors} name='benefits.ad.sum_assured' />
					</Form.Field>
				)}
			/>

			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.ad.occrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Occupational Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType2}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.ad.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Medical Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType2}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.ad.avorate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Avocation Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType2}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default AccidentalDeathRider
