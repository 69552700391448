import React from 'react'
import { useMatch } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'

const ProductBreadcrumb = ({ product, qid, title }) => {
	const isPersonalPage = useMatch({ path: `/product/${product}`, end: true })
	const isPlanPage = useMatch({ path: `/product/${product}/plan`, end: true })
	const isSummaryPage = useMatch({ path: `/product/${product}/summary`, end: true })
	return (
		<Breadcrumb size='large'>
			<Breadcrumb.Section link>{title}</Breadcrumb.Section>
			<>
				<Breadcrumb.Divider icon='right chevron' />
				<Breadcrumb.Section link={!!isPlanPage || !!isSummaryPage} active={!!isPersonalPage}>
					{!!!isPersonalPage && 'Personal Information'}
					{!!isPersonalPage && 'Personal Information'}
				</Breadcrumb.Section>
			</>
			{(!!isPlanPage || !!isSummaryPage) && (
				<>
					<Breadcrumb.Divider icon='right chevron' />
					<Breadcrumb.Section link={!!isPersonalPage || !!isSummaryPage} active={!!isPlanPage}>
						{!!!isPlanPage && 'Plan Information'}
						{!!isPlanPage && 'Plan Information'}
					</Breadcrumb.Section>
				</>
			)}
			{!!isSummaryPage && (
				<>
					<Breadcrumb.Divider icon='right chevron' />
					<Breadcrumb.Section active>{!!qid ? qid : 'Plan Summary'}</Breadcrumb.Section>
				</>
			)}
		</Breadcrumb>
	)
}

export default ProductBreadcrumb
