import { configureStore } from '@reduxjs/toolkit'
import {
	persistReducer,
	persistStore,
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage'
import { novaApi } from '../services/novaApi'
import reducer from './reducers'

const persistConfig = {
	key: 'nova-v2',
	version: 1.6,
	storage: storage('nova-v2'),
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(novaApi.middleware)
	},
})

export const persistor = persistStore(store)

if (module.hot) {
	module.hot.accept('./reducers', () => {
		const nextRootReducer = require('./reducers').default
		store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
	})
}

export default store
