import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import { useScrollOnErrors } from '../../utils'
import PersonalForm from '../personal/PersonalForm'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import ProductLayout from '../product/ProductLayout'
import AdmedPlan from './AdmedPlan'
import {
	admedPlanUpdated,
	admedQuoteUpdated,
	selectAdmed,
	useGetAdmedIllustrationMutation,
} from './admedSlice'
import AdmedSummary from './AdmedSummary'

const Admed = () => {
	const navigate = useNavigate()

	const dispatch = useDispatch()

	const personalFormValues = useSelector(selectPersonal)

	const planValues = useSelector(selectAdmed)

	const [errors, setErrors] = useState([])

	const productName = 'admed'

	const [illustrate, { isLoading, isError }] = useGetAdmedIllustrationMutation({
		fixedCacheKey: 'admed-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/admed/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		// clear errors
		setErrors([])

		// update store
		dispatch(admedPlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(admedQuoteUpdated({ quote }))

			navigate('/product/admed/summary')
		} catch (error) {
			if (error?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (error?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (error?.data) {
				const { messages, qid, frequency_premium, annual_premium } = error.data

				if (qid) {
					dispatch(admedQuoteUpdated({ qid, frequency_premium, annual_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/admed')
	}

	const onSummaryBack = () => {
		navigate('/product/admed/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Admed'
						breadcrumbTitle='Admed'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<AdmedPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>
				<Route
					path='summary'
					element={
						<AdmedSummary
							personal={personalFormValues}
							values={planValues}
							onBack={onSummaryBack}
						/>
					}
				/>
				<Route path='*' element={<Navigate to='/product/admed' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default Admed
