import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	useCopyIllustrationMutation,
	useLazyCheckUnderwritingRequirementsQuery,
	useOpenUnderwritingRequirementsMutation,
	useSaveIllustrationMutation,
	useSubmitAsApplicationMutation,
	useUploadIllustrationMutation,
} from '../../services/novaApi'
import SummaryActions from '../../components/SummaryActions'
import {
	Button,
	Divider,
	Grid,
	Header,
	List,
	Segment,
	SegmentGroup,
	Table,
} from 'semantic-ui-react'
import {
	getGender,
	formatCurrency,
	getSmokerText,
	getPaymentFrequencyText,
	getWpExtraRating,
	getTermType,
	getTermPlan,
	generateOlnpPdf,
	getAirRatingText,
	onOpenUnderwritingRequirements,
	useQuoteNotFound,
	getExtraRating,
	getAnnualPremiumCovered,
	version,
} from '../../utils'
import { olnpQuoteUpdated } from './olnpSlice'

function hasBenefits(benefits) {
	return (
		!!benefits.ddr.rate_1 ||
		!!benefits.ad.sum_assured ||
		!!benefits.rr.applicant_age ||
		!!benefits.air.units ||
		!!benefits.wp ||
		!!benefits.lt.sum_assured ||
		!!benefits.cl.sum_assured ||
		!!benefits.wdda.applicant_age
	)
}

const OlnpSummary = ({ personal, values, onBack }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { quote, plan, benefits, ratings, is_saved } = values

	const { appkey, swdate } = useSelector(state => state.auth)

	const [saveIllustration, { isLoading: isSaving }] = useSaveIllustrationMutation()
	const [uploadIllustration, { isLoading: isUploading }] = useUploadIllustrationMutation()
	const [copyIllustration, { isLoading: isCopying }] = useCopyIllustrationMutation()
	const [initializeUW, { isLoading: isInitializingUW }] = useOpenUnderwritingRequirementsMutation()
	const [submitAsApplication, { isLoading: isSubmitting }] = useSubmitAsApplicationMutation()
	const [checkRequirements, { isFetching: isCheckingReqs }] =
		useLazyCheckUnderwritingRequirementsQuery()

	const hasOccFprem = Number(quote?.occfprem) > 0
	const hasMedFprem = Number(quote?.medfprem) > 0
	const hasAvoFprem = Number(quote?.avofprem) > 0

	const isNewIllustration = !!swdate && new Date() >= new Date(swdate)

	const isChildIllustration = Number(personal.issue_age) < 18

	const isCompanyIllustration = personal.applicant_sex === 'C'

	const isAnnuityIllustration = false

	const disableStart = !isAnnuityIllustration && (isChildIllustration || isCompanyIllustration)

	const disableSubmit = isAnnuityIllustration || isNewIllustration

	const handleCopyIllustration = async () => {
		if (values.is_saved === false) {
			await handleSave()
		}

		try {
			await copyIllustration({ ids: quote?.qid, agent: personal.agent }).unwrap()

			toast.success('Illustration copied')
		} catch (err) {
			if (!!err?.data?.msg) {
				toast.error(err.data.msg)

				return
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleSave = async () => {
		if (!!values.is_saved) {
			return
		}
		try {
			await saveIllustration({ qid: quote?.qid }).unwrap()

			dispatch(olnpQuoteUpdated({ is_saved: true }))

			toast.success('Illustration saved.')
		} catch (error) {
			if (!!error?.data?.msg) {
				toast.error(error.data.msg)

				throw error
			}

			toast.error('Server error, please contact technology services')

			throw error
		}
	}

	const handleSaveAndClose = async () => {
		try {
			if (!is_saved) {
				await handleSave()
			}

			navigate('/')
		} catch (error) {}
	}

	const handleStart = async () => {
		try {
			let res = await checkRequirements(quote?.qid).unwrap()
			if (res.uwreqs === false) {
				return toast.error('Underwriting requirements must be completed first!!')
			}

			// upload the illustration to the server
			const doc = generateOlnpPdf({ ...personal, ...values })

			const blob = doc.output('blob')

			const file = new Blob([blob], { type: 'application/pdf' })

			await uploadIllustration({ file, qid: quote?.qid }).unwrap()
			// save the illustration if it isn't saved
			handleSave()

			// open eapps in a new tab
			window.open(
				`${process.env.REACT_APP_EAPPS_V2_URL}?agtno=${personal.agent}&cltno=${personal.client}&appclient=${personal.applicant_client}&qid=${quote.qid}&token=${appkey}&isNew=true`,
			)

			// go back to the plan screen
			navigate('/product/olnp/plan')
		} catch (error) {
			if (!!error?.data?.msg) {
				toast.error(error.data.msg)
				return
			}
			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleDownload = async () => {
		const doc = generateOlnpPdf({ ...personal, ...values })

		const blob = doc.output('blob')

		const file = new Blob([blob], { type: 'application/pdf' })

		try {
			toast.info('Uploading illustration')

			await uploadIllustration({ file, qid: quote?.qid })
		} catch (err) {
			if (err?.data?.msg) {
				return toast.error(err.data.msg)
			}
			toast.error('Error uploading illustration')
		}

		doc.save(`OLNP - ${quote?.qid || ''}${version}`)

		toast.success('Illustration downloaded.')
	}

	const handleOpenUnderwritingRequirements = async () => {
		let qid = quote?.qid
		try {
			await initializeUW({ qid }).unwrap()
			onOpenUnderwritingRequirements(qid)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const onSubmitAsApplication = async () => {
		try {
			const { msg } = await submitAsApplication({ qid: quote?.qid }).unwrap()
			toast.info(msg)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Sever error, please contact technology services')
		}
	}

	const handleOpen = () => {
		window.open(
			`${process.env.REACT_APP_EAPPS_V2_URL}?policy=${quote?.policy}&resume=true&token=${appkey}`,
		)
	}

	useQuoteNotFound(quote?.qid)

	return (
		<div className='summary'>
			<SummaryActions
				isLoading={
					isSaving || isUploading || isCopying || isInitializingUW || isSubmitting || isCheckingReqs
				}
				isSaved={is_saved}
				disableSubmit={disableSubmit}
				disableStart={disableStart}
				hasApplication={quote?.is_app === '1'}
				handleSave={handleSave}
				handleSaveAndClose={handleSaveAndClose}
				handleDownload={handleDownload}
				handleStart={handleStart}
				handleOpen={handleOpen}
				handleCopy={handleCopyIllustration}
				handleUnderwritingReqirements={handleOpenUnderwritingRequirements}
				handleSubmitAsApp={onSubmitAsApplication}
			/>
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Personal Information</Header>
				</Segment>
				<Segment padded>
					<Header size='small'>
						{personal.first_name} {personal.last_name}
					</Header>
					<Divider hidden />
					<Grid columns={3} divided>
						<Grid.Column>
							<Header sub>Sex</Header>
							<span>{getGender(personal.sex)}</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Smoker</Header>
							<span>{getSmokerText(personal.smoker)}</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Age</Header>
							<span>{personal.issue_age} years</span>
						</Grid.Column>
					</Grid>
				</Segment>
			</SegmentGroup>
			<Divider hidden />
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Plan Information</Header>
				</Segment>
				<Segment padded>
					<List divided relaxed className='summary-list'>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(plan.sum_assured, 0)}</List.Content>
							<List.Content>Sum Assured</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>
								{getPaymentFrequencyText(personal.payment_frequency)}
							</List.Content>
							<List.Content>Payment Frequency</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{getExtraRating(quote?.ratings?.ol)}</List.Content>
							<List.Content>Base Plan Extra Rating</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(quote.standard_fprem)}</List.Content>
							<List.Content>Standard Frequency Premium</List.Content>
						</List.Item>
						{hasOccFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.occfprem)}</List.Content>
								<List.Content>Occupational Extra Premium</List.Content>
							</List.Item>
						)}
						{hasMedFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.medfprem)}</List.Content>
								<List.Content>Medical Extra Premium</List.Content>
							</List.Item>
						)}
						{hasAvoFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.avofprem)}</List.Content>
								<List.Content>Avocation Extra Premium</List.Content>
							</List.Item>
						)}
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.frequency_premium)}
							</List.Content>
							<List.Content>Total Frequency Premium</List.Content>
						</List.Item>
					</List>
				</Segment>
			</SegmentGroup>
			{hasBenefits(benefits) && (
				<>
					<Divider hidden />
					<SegmentGroup>
						<Segment basic style={{ padding: 0 }}>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Supplementary Benefit</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
										<Table.HeaderCell>Extra Rating</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{!!benefits.ddr.rate_1 && (
										<Table.Row>
											<Table.Cell>OLNP Dread Disease</Table.Cell>
											<Table.Cell>
												Acceleration:{' '}
												{`${benefits.ddr.rate_1}%${
													!!benefits.ddr.rate_2 ? ', ' + benefits.ddr.rate_2 + '%' : ''
												}${!!benefits.ddr.rate_3 ? ', ' + benefits.ddr.rate_3 + '%' : ''}${
													!!benefits.ddr.rate_4 ? ', ' + benefits.ddr.rate_4 + '%' : ''
												}`}
											</Table.Cell>
											<Table.Cell> Standard Rate {getWpExtraRating(ratings.ddr)}</Table.Cell>
										</Table.Row>
									)}
									{!!benefits.wdda.applicant_age && (
										<Table.Row>
											<Table.Cell>
												Waiver of Premium on Death or Disability - {benefits.wdda.duration} years
											</Table.Cell>
											<Table.Cell>Applicant Age: {benefits.wdda.applicant_age}</Table.Cell>
											<Table.Cell>Standard Rate {`${getWpExtraRating(ratings.wdda)}`}</Table.Cell>
										</Table.Row>
									)}
									{!!benefits.ad.sum_assured && (
										<Table.Row>
											<Table.Cell>Accidental Death</Table.Cell>
											<Table.Cell>
												Sum Assured: {formatCurrency(benefits.ad.sum_assured, 0)}
											</Table.Cell>
											<Table.Cell>Standard Rate {getWpExtraRating(ratings.ad)}</Table.Cell>
										</Table.Row>
									)}

									{!!benefits.rr.applicant_age && (
										<Table.Row>
											<Table.Cell>Retrenchment Rider</Table.Cell>
											<Table.Cell>
												{`Annual Premium Covered: ${getAnnualPremiumCovered(
													benefits.rr.additional_premium,
													quote?.frequency_premium,
													personal.payment_frequency,
												)}`}
											</Table.Cell>
											<Table.Cell>Standard Rate</Table.Cell>
										</Table.Row>
									)}

									{!!benefits.air.units && (
										<Table.Row>
											<Table.Cell>Accidental Indemnity Rider</Table.Cell>
											<Table.Cell>Number of Units: {benefits.air.units}</Table.Cell>
											<Table.Cell>
												{`Class ${[
													getAirRatingText(ratings.air.occrate),
													getAirRatingText(ratings.air.medrate),
													getAirRatingText(ratings.air.avorate),
												].join(', ')}`}
											</Table.Cell>
										</Table.Row>
									)}

									{!!benefits.wp && (
										<Table.Row>
											<Table.Cell>Waiver Preimum</Table.Cell>
											<Table.Cell></Table.Cell>
											<Table.Cell>Standard Rate {getWpExtraRating(ratings.wp)}</Table.Cell>
										</Table.Row>
									)}

									{!!benefits.lt.sum_assured && (
										<Table.Row>
											<Table.Cell>
												{getTermType(benefits.lt.type)} - {getTermPlan(benefits.lt.term)}
											</Table.Cell>
											<Table.Cell>
												Sum Assured: {formatCurrency(benefits.lt.sum_assured, 0)}
											</Table.Cell>
											<Table.Cell>{getExtraRating(quote?.ratings?.lt)}</Table.Cell>
										</Table.Row>
									)}

									{!!benefits.cl.sum_assured && (
										<Table.Row>
											<Table.Cell>C-Life</Table.Cell>
											<Table.Cell>
												Sum Assured: {formatCurrency(benefits.cl.sum_assured, 0)}
											</Table.Cell>
											<Table.Cell>{getExtraRating(quote?.ratings?.cl)}</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							</Table>
						</Segment>
					</SegmentGroup>
				</>
			)}
			<Divider hidden />
			<Button negative floated='right' onClick={onBack}>
				Back
			</Button>
		</div>
	)
}

export default OlnpSummary
