import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Grid,
	Header,
	Icon,
	Input,
	Label,
	List,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	extraRatingsType2,
	isSelectedBenefits,
	surgicalPackageOptions,
	useBenefits,
	useScrollOnPlanErrors,
	maximumBenefitOptions,
	deductibleOptions,
	extraRatingsType1,
	isRetrenchmenRiderInvalid,
	useValidateProduct,
	DREAD_REDUCTION,
} from '../../utils'
import BenefitsContainer from '../benefits/BenefitsContainer'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import { products } from '../product/ProductList'

const initialBenefits = {
	rr: false,
}

const AdmedPlan = ({
	personal,
	plan,
	onSubmit,
	onPrevious,
	isLoading,
	errors: serverErrors,
	clearErrors,
}) => {
	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const surgicalPackage = methods.watch('plan.surgical_package')
	const maximumBenefit = methods.watch('plan.maximum_benefit')

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'admed')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	return (
		<>
			{!!serverErrors.length && (
				<Message
					error
					onDismiss={clearErrors}
					header='There were some errors with your submission'
					list={serverErrors.map(value => value[Object.keys(value)[0]])}
				/>
			)}
			<Divider hidden />
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Grid columns={2} stackable className='form-grid padding'>
								<Grid.Column>
									<Controller
										control={control}
										name='plan.surgical_package'
										render={({ value, onChange, onBlur }) => (
											<Form.Select
												label='Surgical Package'
												className='flex-column'
												search
												clearable
												value={value}
												onChange={(event, data) => onChange(data.value)}
												onBlur={onBlur}
												options={surgicalPackageOptions}
											/>
										)}
									/>
								</Grid.Column>
								<Grid.Column>
									<Form.Group widths='equal'>
										<Controller
											control={control}
											name='ratings.adm.surgical.occrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													label='Occupational Rate'
													fluid
													search
													clearable
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.surgical.medrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													label='Medical Rate'
													fluid
													search
													clearable
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.surgical.avorate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													label='Avocation Rate'
													fluid
													search
													clearable
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
									</Form.Group>
								</Grid.Column>
							</Grid>

							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.maximum_benefit'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											label='Maximum Benefit'
											search
											clearable
											value={value}
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											options={maximumBenefitOptions[surgicalPackage] || maximumBenefitOptions['1']}
										/>
									)}
								/>
								<Controller
									control={control}
									name='plan.hospital_diagnostic'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											label='Hospital Diagnostic Deductible'
											search
											clearable
											value={value}
											onChange={(event, data) => {
												onChange(data.value)
												methods.setValue('plan.accident_emergency', data.value)
											}}
											onBlur={onBlur}
											options={deductibleOptions[maximumBenefit] || deductibleOptions['1']}
										/>
									)}
								/>
							</Form.Group>

							<Grid columns={2} stackable className='form-grid'>
								<Grid.Column>
									<Controller
										control={control}
										name='plan.accident_emergency'
										render={({ value, onChange, onBlur }) => (
											<Form.Select
												label='Accident/Emergency Deductible'
												className='flex-column'
												search
												clearable
												value={value}
												onChange={(event, data) => {
													onChange(data.value)
													methods.setValue('plan.hospital_diagnostic', data.value)
												}}
												onBlur={onBlur}
												options={deductibleOptions[maximumBenefit] || deductibleOptions['1']}
											/>
										)}
									/>
								</Grid.Column>
								<Grid.Column>
									<Form.Group widths='equal'>
										<Controller
											control={control}
											name='ratings.adm.accident.occrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Occupational Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.accident.medrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Medical Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.accident.avorate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Avocation Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
									</Form.Group>
								</Grid.Column>
							</Grid>

							<Grid columns={2} stackable className='form-grid'>
								<Grid.Column>
									<Controller
										control={control}
										name='plan.death_benefit'
										rules={{
											required: 'Enter death benefit',
											min: {
												value: 75000,
												message: 'Must be between $75,000 and $2,020,000',
											},
											max: {
												value: 2020000,
												message: 'Must be between $75,000 and $2,020,000',
											},
											pattern: {
												value: /^\d+$/,
												message: 'Decimals not allowed',
											},
										}}
										render={({ value, onChange, onBlur }) => (
											<Form.Field error={!!errors?.plan?.death_benefit}>
												<label htmlFor='plan.death_benefit'>Death Benefit</label>
												<Input
													name='plan.death_benefit'
													id='plan.death_benefit'
													className='flex-column'
													type='number'
													step='1'
													value={value}
													onChange={(event, data) => {
														onChange(event, data)

														const dread = Number(data.value) - DREAD_REDUCTION
														if (dread > 0) {
															methods.setValue('plan.dread_disease', dread)
														} else {
															methods.setValue('plan.dread_disease', '')
														}
													}}
													onBlur={onBlur}
												/>
												{!!!errors?.plan?.death_benefit && (
													<Label pointing>Must be between $75,000 and $2,020,000</Label>
												)}
												<FieldError errors={errors} name='plan.death_benefit' />
											</Form.Field>
										)}
									/>
								</Grid.Column>
								<Grid.Column>
									<Form.Group widths='equal'>
										<Controller
											control={control}
											name='ratings.adm.death.occrate'
											rules={{
												min: {
													value: 0,
													message: 'Negative value not allowed',
												},
												pattern: {
													value: /^[0-9]*(\.[0-9]{0,2})?$/,
													message: 'Only two decimal placed allowed',
												},
											}}
											render={({ value, onChange, onBlur }) => (
												<Form.Input
													fluid
													type='number'
													step='0.25'
													label='Occupational Rate'
													value={value}
													onChange={onChange}
													onBlur={onBlur}
													error={
														!!errors?.ratings?.adm?.death?.occrate &&
														errors.ratings.adm.death.occrate.message
													}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.death.medrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Medical Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType1}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.death.avorate'
											rules={{
												min: {
													value: 0,
													message: 'Negative value not allowed',
												},
												pattern: {
													value: /^[0-9]*(\.[0-9]{0,2})?$/,
													message: 'Only two decimal placed allowed',
												},
											}}
											render={({ value, onChange, onBlur }) => (
												<Form.Input
													fluid
													type='number'
													step='0.25'
													label='Avocation Rate'
													value={value}
													onChange={onChange}
													onBlur={onBlur}
													error={
														!!errors?.ratings?.adm?.death?.avorate &&
														errors.ratings.adm.death.avorate.message
													}
												/>
											)}
										/>
									</Form.Group>
								</Grid.Column>
							</Grid>
							<Grid columns={2} stackable className='form-grid'>
								<Grid.Column>
									<Controller
										control={control}
										name='plan.dread_disease'
										render={({ value, onChange, onBlur }) => (
											<Form.Input
												className='flex-column'
												label='Dread Disease'
												type='number'
												step='1'
												readOnly
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
										)}
									/>
								</Grid.Column>
								<Grid.Column>
									<Form.Group widths='equal'>
										<Controller
											control={control}
											name='ratings.adm.dread.occrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Occupational Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.dread.medrate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Medical Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
										<Controller
											control={control}
											name='ratings.adm.dread.avorate'
											render={({ value, onChange, onBlur }) => (
												<Form.Select
													fluid
													search
													clearable
													label='Avocation Rate'
													value={value}
													onChange={(event, data) => onChange(data.value)}
													onBlur={onBlur}
													options={extraRatingsType2}
												/>
											)}
										/>
									</Form.Group>
								</Grid.Column>
							</Grid>
						</Segment>
					</SegmentGroup>
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) ? (
									<>{benefits.rr && <RetrenchmentRider />}</>
								) : (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
						loading={isLoading}
						primary
						floated='right'
					>
						Illustrate
					</Button>
					<Button
						type='button'
						basic
						floated='right'
						disabled={isLoading}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default AdmedPlan
