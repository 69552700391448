import React from 'react'
import { Button, Grid, Header, Segment, Image, Divider, Container } from 'semantic-ui-react'
import logo from '../../assets/logo1.png'
import { useLoginMutation } from '../../services/novaApi'

const Login = () => {
	const [, { isLoading }] = useLoginMutation({
		fixedCacheKey: 'login',
	})

	const handleClick = () => {
		window.open(process.env.REACT_APP_CRM_LOGIN, '_self')
	}
	return (
		<Container fluid>
			<Grid
				style={{ height: '100vh', backgroundColor: '#fdfdfd' }}
				textAlign='center'
				verticalAlign='middle'
			>
				<Grid.Column style={{ maxWidth: 350 }}>
					<Segment>
						<Image src={logo} alt='Logo' style={{ display: 'inline-block' }} />
						<Header size='large'>
							<Header.Content>
								NOVA
								<Header.Subheader>Please login using the CRM System</Header.Subheader>
							</Header.Content>
						</Header>
						<Divider hidden />
						<Button
							fluid
							primary
							size='large'
							onClick={handleClick}
							disabled={isLoading}
							loading={isLoading}
						>
							CRM
						</Button>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	)
}

export default Login
