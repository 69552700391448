import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Admed from './features/admed/Admed'
import Login from './features/auth/Login'
import Protected from './features/auth/Protected'
import Clife from './features/clife/Clife'
import Help from './features/help/Help'
import Home from './features/home/Home'
import Illustrations from './features/illustrations/Illustrations'
import Mpp from './features/mpp/Mpp'
import Olnp from './features/olnp/Olnp'
import LevelTerm from './features/term/LevelTerm'
import TriflexAnnuity from './features/triflex-annuity/TriflexAnnuity'
import Triflex from './features/triflex/Triflex'
import { useCrmSession, useCrmState, useDisableNumberInputWheelEvent } from './utils'

function NotFound() {
	return <div>Not found</div>
}

const App = () => {
	useDisableNumberInputWheelEvent()

	useCrmSession()

	useCrmState()

	return (
		<Routes>
			<Route path='/login' element={<Login />} />
			<Route path='/' element={<Protected component={Layout} />}>
				<Route index element={<Home />} />
				<Route path='help' element={<Help />} />
				<Route path='product/triflex-annuity/*' element={<TriflexAnnuity />} />
				<Route path='product/mpp/*' element={<Mpp />} />
				<Route path='product/clife/*' element={<Clife />} />
				<Route path='product/olnp/*' element={<Olnp />} />
				<Route path='product/term/*' element={<LevelTerm />} />
				<Route path='product/triflex/*' element={<Triflex />} />
				<Route path='product/admed/*' element={<Admed />} />
				<Route path='illustrations' element={<Illustrations />} />
				<Route path='*' element={<NotFound />} />
			</Route>
		</Routes>
	)
}

export default App
