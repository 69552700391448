import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import { useScrollOnErrors } from '../../utils'
import PersonalForm from '../personal/PersonalForm'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import MppPlan from './MppPlan'
import MppSummary from './MppSummary'
import { mppPlanUpdated, selectMpp, useGetMppIllustrationMutation } from './mppSlice'
import ProductLayout from '../product/ProductLayout'

const Mpp = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const personalFormValues = useSelector(selectPersonal)
	const planValues = useSelector(selectMpp)
	const productName = 'mpp'
	const [errors, setErrors] = useState([])

	const [illustrate, { isLoading, isError }] = useGetMppIllustrationMutation({
		fixedCacheKey: 'mpp-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/mpp/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		//clear errors
		setErrors([])

		dispatch(mppPlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(mppPlanUpdated({ quote }))

			navigate('/product/mpp/summary')
		} catch (err) {
			if (err?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (err?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (err?.data) {
				const { messages, qid, frequency_premium, annual_premium } = err.data

				if (qid) {
					dispatch(mppPlanUpdated({ qid, frequency_premium, annual_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/mpp')
	}

	const onSummaryBack = () => {
		navigate('/product/mpp/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Mortgage Protection Plan'
						breadcrumbTitle='MPP'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<MppPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>
				<Route
					path='summary'
					element={
						<MppSummary personal={personalFormValues} values={planValues} onBack={onSummaryBack} />
					}
				/>
				<Route path='*' element={<Navigate to='/product/mpp' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default Mpp
