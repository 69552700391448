import React from 'react'
import { Dropdown, Segment } from 'semantic-ui-react'

const SummaryActions = ({
	isLoading,
	isSaved,
	disableStart,
	hasApplication,
	handleSave,
	handleSaveAndClose,
	handleDownload,
	handleStart,
	handleCopy,
	handleOpen,
	handleUnderwritingReqirements,
	handleSubmitAsApp = () => null,
	disableSubmit = false,
}) => {
	const actionItems = [
		{
			key: 'save',
			icon: 'save outline',
			text: 'Save Illustration',
			onClick: handleSave,
			disabled: isSaved,
		},
		{
			key: 'close',
			icon: 'close',
			text: !isSaved ? 'Save & Close' : 'Close Illustration',
			onClick: handleSaveAndClose,
		},
		{ key: 'copy', icon: 'copy outline', text: 'Copy Illustration', onClick: handleCopy },
		{
			key: 'download',
			icon: 'cloud download',
			text: 'Download Illustration',
			onClick: handleDownload,
		},
		{
			key: 'submit',
			icon: 'upload',
			text: 'Submit as Application',
			onClick: handleSubmitAsApp,
			disabled: disableSubmit,
		},
		{
			key: 'start',
			icon: 'file alternate outline',
			text: !hasApplication ? 'Start MDS Application' : 'Open MDS Application',
			onClick: !hasApplication ? handleStart : handleOpen,
			disabled: !!disableStart,
		},
		{
			key: 'tasks',
			icon: 'tasks',
			text: 'Underwriting Requirements',
			onClick: handleUnderwritingReqirements,
		},
	]

	return (
		<Segment basic clearing style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
			<Dropdown floating text='Actions' button loading={isLoading} options={actionItems} />
		</Segment>
	)
}

export default SummaryActions
