import { isEmpty } from 'lodash'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { calculateIssueAge, scrollToTop } from './functions'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../features/auth/authSlice'
import { crmStateSaved } from './actions'
import { useLoginMutation } from '../services/novaApi'

/**
 *
 * @param {boolean} isError
 * @param {Array} errors
 */
export const useScrollOnErrors = (isError, errors) => {
	if (typeof isError !== 'boolean' || !Array.isArray(errors)) {
		throw new Error('Invalid function arguments')
	}

	useEffect(() => {
		if (isError && !!errors.length) {
			scrollToTop()
		}
	}, [isError, errors])
}

export const useScrollOnPlanErrors = errors => {
	useEffect(() => {
		if (!isEmpty(errors)) {
			toast.error('There were some errors with your submission')
			scrollToTop()
		}
	}, [errors])
}

export const useValidateProduct = isProductValid => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!isProductValid) {
			toast.warn('Client does not qualify for product!')
			navigate('/', { replace: true })
		}
	}, [isProductValid, navigate])
}

/**
 * Disables wheel scroll on number inputs
 */
export const useDisableNumberInputWheelEvent = () => {
	useEffect(() => {
		const wheelListener = e => {
			if (document.activeElement.type === 'number') {
				document.activeElement.blur()
			}
		}
		document.addEventListener('wheel', wheelListener)

		return () => {
			document.removeEventListener('wheel', wheelListener)
		}
	}, [])
}

/**
 *
 * @param {object} initialBenefits
 * @param {Array} currentBenefits
 * @returns
 */
export const useBenefits = (initialBenefits, currentBenefits) => {
	const renderRef = useRef(0)
	const [benefits, setBenefits] = useState({ ...initialBenefits })

	useEffect(() => {
		renderRef.current++
		function checkBenefits() {
			let newBenefitsList = {}

			if (!!currentBenefits?.gdb) {
				newBenefitsList.gdb = true
			}

			if (!!currentBenefits?.ddr?.rate_1) {
				newBenefitsList.ddr = true
			}

			if (!!currentBenefits?.ad?.sum_assured) {
				newBenefitsList.ad = true
			}

			if (!!currentBenefits?.rr?.applicant_age) {
				newBenefitsList.rr = true
			}

			if (!!currentBenefits?.air?.units) {
				newBenefitsList.air = true
			}

			if (!!currentBenefits?.wp) {
				newBenefitsList.wp = true
			}

			if (!!currentBenefits?.lt?.sum_assured) {
				newBenefitsList.lt = true
			}

			if (!!currentBenefits?.cl?.sum_assured) {
				newBenefitsList.cl = true
			}

			if (!!currentBenefits?.wdda?.applicant_age) {
				newBenefitsList.wdda = true
			}

			setBenefits(newBenefitsList)
		}

		if (renderRef.current < 2) {
			checkBenefits()
		}
	}, [currentBenefits])

	return [benefits, setBenefits]
}

/**
 * Returns to the homepage if a quote is not found
 *
 * @param {string|number} qid
 */
export const useQuoteNotFound = qid => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!!!qid) {
			toast.error('No illustration found')
			navigate('/', { replace: true })
		}
	}, [qid, navigate])
}

export const useQuery = () => {
	return new URLSearchParams(useLocation().search)
}

export const useCrmState = () => {
	const query = useQuery()
	const dispatch = useDispatch()

	useEffect(() => {
		let initial = false
		const agent = query.get('agtno') || ''
		const client = query.get('cltno') || ''
		const first_name = query.get('fname') || ''
		const last_name = query.get('sname') || ''
		const sex = query.get('sex') || ''
		const date_of_birth = query.get('dob') || ''
		const applicant_first_name = query.get('app_fname') || ''
		const applicant_last_name = query.get('app_sname') || ''
		const applicant_date_of_birth = query.get('app_dob') || ''
		const applicant_sex = query.get('app_sex') || ''
		const applicant_client = query.get('app_cltno') || ''
		const application_date = dayjs().format('YYYY-MM-DD') || ''
		const appkey = query.get('app_key') || ''
		const issue_age = date_of_birth !== '' ? calculateIssueAge(application_date, date_of_birth) : ''

		if (!initial && !!appkey) {
			dispatch(
				crmStateSaved({
					agent,
					client,
					first_name,
					last_name,
					sex,
					date_of_birth,
					applicant_first_name,
					applicant_last_name,
					applicant_date_of_birth,
					applicant_sex,
					applicant_client,
					application_date,
					issue_age,
					appkey,
				}),
			)
		}

		return () => {
			initial = true
		}
	}, [query, dispatch])
}

export const useCrmSession = () => {
	const [params] = useSearchParams()
	let location = useLocation()
	let navigate = useNavigate()
	let dispatch = useDispatch()
	const ref = useRef(false)

	const appkey = params.get('app_key')
	const agent = params.get('agtno')
	const { agent: currentAgent, token: isLoggedIn } = useSelector(state => state.auth)
	const [login] = useLoginMutation({
		fixedCacheKey: 'login',
	})

	useEffect(() => {
		async function checkSession() {
			ref.current = true
			const isNewUser = agent !== currentAgent

			// if session is expired
			// logout
			if (!!!appkey && !!agent) {
				// dispatch logout
				dispatch(logout())
				toast.info('Session expired, re-log into CRM')
				return
			}

			// if user is logged in
			// and user is new
			// logout current user and login new user
			if (!!isLoggedIn && !!appkey && isNewUser) {
				dispatch(logout())
				try {
					await login({ appkey, agent }).unwrap()
					navigate(`${location.pathname}${location.search}`)
				} catch (err) {
					if (err?.data?.msg) {
						toast.error(err.data.msg)
						return
					}

					toast.error('Something wnet wrong, contact technology services')
					return
				}
			}

			// if user not logged in
			// if crm session not expired login
			if (!!!isLoggedIn && !!appkey && !!agent) {
				// dispatch login
				try {
					await login({ appkey, agent }).unwrap()
					navigate(`${location.pathname}${location.search}`)
				} catch (err) {
					if (!!err?.data?.msg) {
						toast.error(err.data.msg)
						return
					}

					toast.error('Something went wrong, contact technology services')
				}
			}
		}

		if (!ref.current) {
			checkSession()
		}
	}, [appkey, agent, currentAgent, isLoggedIn, login, navigate, location, dispatch, ref])
}
