import React, { useCallback, useMemo } from 'react'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Header,
	Icon,
	Input,
	Label,
	List,
	Loader,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	useValidateSumAssuredQuery,
	useValidateTriflexAdditionalDepositMutation,
} from '../../services/novaApi'
import {
	formatCurrency,
	isSelectedBenefits,
	unscheduledDepositOptions,
	useBenefits,
	useScrollOnPlanErrors,
	isRetrenchmenRiderInvalid,
	isWddaValid,
	useValidateProduct,
	isAirValid,
	isClifeValid,
	isTermValid,
} from '../../utils'
import { WaiverPremium } from '../benefits'
import AccidentalDeathRider from '../benefits/AccidentalDeathRider'
import AirRider from '../benefits/AirRider'
import BenefitsContainer from '../benefits/BenefitsContainer'
import ClifeRider from '../benefits/ClifeRider'
import DreadDisease from '../benefits/DreadDisease'
import GuaranteedDeathBenefit from '../benefits/GuaranteedDeathBenefit'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import TermRider from '../benefits/TermRider'
import WddaRider from '../benefits/WddaRider'
import { products } from '../product/ProductList'
import { triflexSumAssuredChanged } from './triflexSlice'

const initialBenefits = {
	gdb: false,
	ddr: false,
	ad: false,
	rr: false,
	air: false,
	wp: false,
	lt: false,
	cl: false,
	wdda: false,
}

const isWaiverPremiumInvalid = age => {
	return Number(age) < 20 || Number(age) > 50
}

const isDdrValid = age => {
	return Number(age) < 20 || Number(age) > 55
}

const isAdValid = age => {
	return Number(age) < 15 || Number(age) > 55
}

const TriflexPlan = ({
	personal,
	plan,
	onSubmit,
	onPrevious,
	isLoading,
	errors: serverErrors,
	clearErrors,
}) => {
	const dispatch = useDispatch()

	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
	})

	const planType = '0'

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors, isSubmitting },
	} = methods

	const sumAssured = methods.watch('plan.sum_assured', '0')
	const additionalDeposit = methods.watch('plan.additional_deposit')
	const payType = methods.watch('plan.pay_type')
	const yearStart = methods.watch('plan.year_start')
	const duration = 65 - Number(personal.issue_age)

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const {
		isLoading: isMinPremiumLoading,
		isFetching: isMinPremiumFetching,
		data: minPremium,
		isError: isMinPremiumError,
		error: minPremiumError,
	} = useValidateSumAssuredQuery({
		product: 'triflex',
		pcode: 2,
		type: planType,
		age: personal.issue_age,
		sex: personal.sex,
		smoker: personal.smoker,
		frequency: personal.payment_frequency,
		date: personal.application_date,
		sa: 0,
	})

	const [
		validateDeposits,
		{ isLoading: isDepositsLoading, isFetching: isDepositsFetching, data: depositsLimit },
	] = useValidateTriflexAdditionalDepositMutation({ fixedCacheKey: 'trifex-additional-deposits' })

	const onValidateDeposits = useCallback(
		async sum_assured => {
			try {
				await validateDeposits({
					sum_assured,
					age: personal.issue_age,
					frequency: personal.payment_frequency,
				}).unwrap()
			} catch (err) {
				let msg = ''

				if (!!err?.msg) {
					msg = err.msg
				} else {
					msg = 'Error validating additional deposits'
				}

				toast.error(msg)
			}
		},
		[personal.issue_age, personal.payment_frequency, validateDeposits],
	)

	const onSumAssuredChanged = () => {
		const newBenefits = Object.assign(benefits, {
			ddr: false,
			cl: false,
			ad: false,
			air: false,
		})

		setValue('benefits.ddr.rate_1', '')
		setValue('benefits.ddr.rate_2', '')
		setValue('benefits.ddr.rate_3', '')
		setValue('benefits.ddr.rate_4', '')

		setValue('ratings.ddr.occrate', '0.00')
		setValue('ratings.ddr.medrate', '0.00')
		setValue('ratings.ddr.avorate', '0.00')

		setValue('benefits.cl.sum_assured', '')

		setValue('ratings.cl.0.occrate', '0.00')
		setValue('ratings.cl.0.medrate', '0.00')
		setValue('ratings.cl.0.avorate', '0.00')

		setValue('ratings.cl.1.occrate', '0.00')
		setValue('ratings.cl.1.medrate', '0.00')
		setValue('ratings.cl.1.avorate', '0.00')

		setValue('benefits.ad.sum_assured', '')

		setValue('ratings.ad.occrate', '0.00')
		setValue('ratings.ad.medrate', '0.00')
		setValue('ratings.ad.avorate', '0.00')

		setValue('benefits.air.units', '')

		setValue('ratings.air.occrate', '1')
		setValue('ratings.air.medrate', '1')
		setValue('ratings.air.avorate', '1')

		setBenefits(newBenefits)
		dispatch(triflexSumAssuredChanged())
	}

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'triflex')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	useEffect(() => {
		if (
			sumAssured &&
			!!!depositsLimit &&
			!!additionalDeposit &&
			!isDepositsLoading &&
			!isDepositsFetching
		) {
			;(async function () {
				await onValidateDeposits(sumAssured)
			})()
		}
	}, [
		sumAssured,
		depositsLimit,
		additionalDeposit,
		isDepositsLoading,
		isDepositsFetching,
		onValidateDeposits,
	])

	useEffect(() => {
		if (isMinPremiumError) {
			if (minPremiumError.status === 400) {
				return toast.error(minPremiumError.data.msg)
			}

			return toast.error('Something went wrong, contact technology services.')
		}
	}, [isMinPremiumError, minPremiumError])

	if (isMinPremiumLoading || isMinPremiumFetching) {
		return <Loader active>Loading...</Loader>
	}

	return (
		<>
			{!!serverErrors.length && (
				<Message
					error
					onDismiss={clearErrors}
					header='There were some errors with your submission'
					list={serverErrors.map(value => value[Object.keys(value)[0]])}
				/>
			)}
			<Divider hidden />
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.sum_assured'
									rules={{
										required: 'Please enter the sum assured',
										pattern: {
											value: /^\d+$/,
											message: 'Decimals not allowed',
										},
										min: {
											value: minPremium?.min,
											message: `Minimum sum assured is ${formatCurrency(minPremium?.min, 0)}`,
										},
									}}
									render={({ value, onChange, onBlur }, { isTouched, isDirty }) => (
										<Form.Field error={!!errors?.plan?.sum_assured}>
											<label htmlFor='plan.sum_assured'>Sum Assured</label>
											<Input
												name='plan.sum_assured'
												id='plan.sum_assured'
												step='1'
												type='number'
												value={value}
												onChange={onChange}
												loading={isMinPremiumLoading || isMinPremiumFetching}
												onBlur={event => {
													onBlur(event)

													if (isDirty) {
														onSumAssuredChanged()

														onValidateDeposits(value)
													}
												}}
											/>
											{!!minPremium && !!!errors?.plan?.sum_assured && (
												<Label pointing>
													Minimum sum assured is {formatCurrency(minPremium?.min, 0)}
												</Label>
											)}
											<FieldError errors={errors} name='plan.sum_assured' />
										</Form.Field>
									)}
								/>
								{!!sumAssured && (
									<Controller
										control={control}
										name='plan.additional_deposit'
										rules={{
											max: {
												value: depositsLimit?.limit || 0,
												message: !!depositsLimit
													? `Must be less than or equal to ${formatCurrency(
															depositsLimit?.limit,
															0,
													  )}`
													: 'Invalid sum assured',
											},
										}}
										render={({ value, onChange, onBlur }) => (
											<Form.Field error={!!errors.plan?.additional_deposit}>
												<label htmlFor='plan.additional_deposit'>Additional Deposit</label>
												<Input
													fluid
													type='number'
													step='1'
													name='plan.additional_deposit'
													id='plan.additional_deposit'
													value={value}
													onChange={onChange}
													onBlur={onBlur}
													loading={isDepositsLoading || isDepositsFetching}
												/>
												{!!!errors.plan?.additional_deposit &&
													!isDepositsLoading &&
													!isDepositsFetching &&
													!!depositsLimit && (
														<Label pointing>
															Must be less than or equal to{' '}
															{formatCurrency(depositsLimit?.limit, 0)}
														</Label>
													)}
												<FieldError errors={errors} name='plan.additional_deposit' />
											</Form.Field>
										)}
									/>
								)}
							</Form.Group>
							{Number(additionalDeposit) > 0 && (
								<>
									<Controller
										control={control}
										name='plan.pay_type'
										rules={{
											required: {
												value: !!additionalDeposit,
												message: 'Select an option',
											},
										}}
										render={({ value, onChange, onBlur }) => (
											<Form.Select
												label='Un-scheduled premium deopsits will be made'
												value={value}
												search
												clearable
												options={unscheduledDepositOptions}
												onChange={(event, data) => onChange(data.value)}
												onBlur={onBlur}
												error={errors?.plan?.pay_type?.message}
											/>
										)}
									/>

									{Number(payType) === 1 && (
										<Form.Group widths='equal'>
											<Controller
												control={control}
												name='plan.year_start'
												rules={{
													required: {
														value: !!additionalDeposit && parseInt(payType) === 1,
														message: 'Please enter the start year',
													},
													min: {
														value: 1,
														message: `Between 1 and ${duration}`,
													},
													max: {
														value: duration,
														message: `Must be between 1 and ${duration}`,
													},
												}}
												render={({ value, onChange, onBlur }) => (
													<Form.Field error={!!errors?.plan?.year_start}>
														<label htmlFor='plan.year_start'>Start Year</label>
														<Input
															fluid
															name='plan.year_start'
															id='plan.year_start'
															type='number'
															step='1'
															value={value}
															onChange={onChange}
															onBlur={onBlur}
														/>
														{!!!errors?.plan?.year_start && (
															<Label pointing>{`Between 1 and ${duration}`}</Label>
														)}
														<FieldError errors={errors} name='plan.year_start' />
													</Form.Field>
												)}
											/>

											<Controller
												control={control}
												name='plan.year_end'
												rules={{
													required: {
														value: !!additionalDeposit && Number(payType) === 1,
														message: 'Please enter the year end',
													},
													min: {
														value: Number(yearStart) >= 1 ? yearStart : 1,
														message: `Between ${
															Number(yearStart) >= 1 ? yearStart : 'start year'
														} and ${duration}`,
													},
													max: {
														value: duration,
														message: `Between ${
															Number(yearStart) >= 1 ? yearStart : 'start year'
														} and ${duration}`,
													},
												}}
												render={({ value, onChange, onBlur }) => (
													<Form.Field error={!!errors?.plan?.year_end}>
														<label htmlFor='plan.year_end'>End year</label>
														<Input
															fluid
															type='number'
															step='1'
															name='plan.year_end'
															id='plan.year_end'
															value={value}
															onChange={onChange}
															onBlur={onBlur}
														/>
														{!!!errors?.plan?.year_end && (
															<Label pointing>
																{`Between ${
																	Number(yearStart) >= 1 ? yearStart : '1'
																} and ${duration}`}
															</Label>
														)}
														<FieldError errors={errors} name='plan.year_end' />
													</Form.Field>
												)}
											/>
										</Form.Group>
									)}
								</>
							)}
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.tf.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Occupational Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors?.ratings?.tf?.occrate && errors.ratings.tf.occrate.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.tf.medrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											fluid
											type='number'
											step='.25'
											label='Medical Rate'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											error={!!errors?.ratings?.tf?.medrate && errors.ratings.tf.medrate.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.tf.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Avocation Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors?.ratings?.tf?.avorate && errors.ratings.tf.avorate.message}
										/>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										label='Guarenteed Death Benefit'
										checked={benefits.gdb}
										onChange={(event, data) => setBenefits({ ...benefits, gdb: data.checked })}
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.wdda}
										disabled={isWddaValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wdda: data.checked })}
										label='WDDA'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										label='Dread Disease'
										checked={benefits.ddr}
										disabled={isDdrValid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => setBenefits({ ...benefits, ddr: data.checked })}
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.ad}
										disabled={isAdValid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => setBenefits({ ...benefits, ad: data.checked })}
										label='Accidental Death'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.air}
										disabled={isAirValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, air: data.checked })}
										label='AIR'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.wp}
										disabled={isWaiverPremiumInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wp: data.checked })}
										label='Waiver of Premium'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.lt}
										disabled={isTermValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, lt: data.checked })}
										label='Level Term'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.cl}
										disabled={isClifeValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, cl: data.checked })}
										label='C-Life'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) ? (
									<>
										{benefits.gdb && <GuaranteedDeathBenefit />}
										{benefits.ddr && (
											<DreadDisease
												qid={plan.quote?.qid || '0'}
												age={personal.issue_age}
												sex={personal.sex}
												product='triflex'
												smoker={personal.smoker}
												frequency={personal.payment_frequency}
												applicationDate={personal.application_date}
											/>
										)}
										{benefits.ad && <AccidentalDeathRider />}
										{benefits.rr && <RetrenchmentRider />}
										{benefits.air && <AirRider />}
										{benefits.wp && <WaiverPremium />}
										{benefits.lt && <TermRider issueAge={personal.issue_age} />}
										{benefits.cl && <ClifeRider />}
										{benefits.wdda && <WddaRider issueAge={personal.issue_age} />}
									</>
								) : (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading || isSubmitting}
						loading={isLoading || isSubmitting}
						primary
						floated='right'
					>
						Illustrate
					</Button>
					<Button
						type='button'
						basic
						floated='right'
						disabled={isLoading || isSubmitting}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default TriflexPlan
