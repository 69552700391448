import { createSlice } from '@reduxjs/toolkit'
import { novaApi } from '../../services/novaApi'
import { crmStateSaved } from '../../utils/actions'

const initialState = {
	agent: '',
	appkey: '',
	name: '',
	email: '',
	token: '',
	isFassUser: false,
	swdate: '',
	currentAgent: '',
	canViewAgents: [],
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			state.agent = action.payload.agent
			state.token = action.payload.token
		},
		setAgent: (state, action) => {
			state.agent = action.payload.agent
		},
		setCurrentAgent: (state, action) => {
			state.currentAgent = action.payload
		},
		logout: () => {
			return initialState
		},
		updateSWDate: (state, { payload }) => {
			state.swdate = payload.swdate
		},
	},
	extraReducers: builder => {
		builder
			.addCase(crmStateSaved, (state, action) => {
				state.agent = action.payload.agent
				state.appkey = action.payload.appkey
			})
			.addMatcher(novaApi.endpoints.login.matchFulfilled, (state, action) => {
				const { agentNo, canViewAgents, email, isFass, name, token, swdate } = action.payload
				state.agent = agentNo
				state.canViewAgents = canViewAgents
				state.email = email
				state.isFassUser = isFass
				state.name = name
				state.token = token
				state.swdate = swdate
				if (isFass === true) {
					state.currentAgent = canViewAgents[0].key || ''
				}
			})
	},
})

export const { setCredentials, setAgent, logout, setCurrentAgent } = authSlice.actions

export const selectAgent = state => state.auth.agent
export const selectIsFassUser = state => state.auth.isFassUser
export const selectCanViewAgents = state => state.auth.canViewAgents
export const selectCurrentAgent = state => state.auth.currentAgent

export default authSlice.reducer
