import React from 'react'
import { Select } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectCanViewAgents,
	selectIsFassUser,
	selectCurrentAgent,
	setCurrentAgent,
} from '../auth/authSlice'

const FassUserAgents = () => {
	const dispatch = useDispatch()

	const isFassUser = useSelector(selectIsFassUser)

	const canViewAgents = useSelector(selectCanViewAgents)

	const currentAgent = useSelector(selectCurrentAgent)

	const onChange = (event, data) => {
		dispatch(setCurrentAgent(data.value))
	}

	if (isFassUser === false) {
		return ''
	}

	return (
		<div style={{ marginLeft: '8px' }}>
			<Select options={canViewAgents} search value={currentAgent} onChange={onChange} />
		</div>
	)
}

export default FassUserAgents
