import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep, defaultsDeep, merge } from 'lodash'
import { resetProduct } from '../../app/actions'
import { novaApi } from '../../services/novaApi'
import { logout } from '../auth/authSlice'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getTriflexIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth, triflex } = api.getState()
				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent
				const qid = triflex?.quote?.qid || ''
				try {
					const response = await baseQuery(
						{
							url: 'triflex',
							method: 'POST',
							body: { ...arg, agent, qid, current_agent, is_fass_user },
						},
						api,
						extraOptions,
					)
					return response
				} catch (error) {
					return error
				}
			},
		}),
	}),
})

export const { useGetTriflexIllustrationMutation } = extendedApiSlice

const initialState = {
	is_saved: false,
	plan: {
		sum_assured: '',
		additional_deposit: '',
		pay_type: '',
		year_start: '',
		year_end: '',
	},
	benefits: {
		gdb: false,
		ddr: {
			rate_1: '',
			rate_2: '',
			rate_3: '',
			rate_4: '',
		},
		ad: {
			sum_assured: '',
		},
		rr: {
			applicant_age: '',
			additional_premium: '',
		},
		air: {
			units: '',
		},
		wp: false,
		lt: {
			sum_assured: '',
			term: '',
			type: '',
		},
		cl: {
			sum_assured: '',
		},
		wdda: {
			applicant_age: '',
			duration: '',
		},
	},
	ratings: {
		tf: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		ddr: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		ad: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		air: {
			occrate: '0',
			medrate: '0',
			avorate: '0',
		},
		wp: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		lt: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		cl: [
			{
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
			{
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
		],
		wdda: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
	},
}

const triflexSlice = createSlice({
	name: 'triflex',
	initialState,
	reducers: {
		triflexPlanUpdated: (state, action) => {
			const newState = cloneDeep(defaultsDeep(action.payload, initialState))

			newState.benefits.wp = newState.benefits?.wp || false

			// prevents resetting quote when illustration is in an error state
			if (!!!newState.quote && !!state.quote) {
				newState.quote = { ...state.quote }
			}

			return merge(state, newState)
		},
		triflexSumAssuredChanged: (state, action) => {
			state.benefits.ddr = { ...initialState.benefits.ddr }
			state.benefits.cl = { ...initialState.benefits.cl }
			state.benefits.ad = { ...initialState.benefits.ad }
			state.benefits.air = { ...initialState.benefits.air }

			state.ratings.ddr = { ...initialState.ratings.ddr }
			state.ratings.cl = { ...initialState.ratings.cl }
			state.ratings.ad = { ...initialState.ratings.ad }
			state.ratings.air = { ...initialState.ratings.air }
		},
		triflexQuoteUpdated: (state, action) => {
			return merge(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(resetProduct, () => {
				return initialState
			})
			.addCase(logout, state => initialState)
	},
})

export const { triflexPlanUpdated, triflexQuoteUpdated, triflexSumAssuredChanged } =
	triflexSlice.actions
export const selectTriflex = state => state.triflex
export default triflexSlice.reducer
