import React, { useState } from 'react'
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import PersonalForm from '../personal/PersonalForm'
import TriflexPlan from './TriflexPlan'
import { useDispatch, useSelector } from 'react-redux'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import {
	selectTriflex,
	triflexQuoteUpdated,
	useGetTriflexIllustrationMutation,
	triflexPlanUpdated,
} from './triflexSlice'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import TriflexSummary from './TriflexSummary'
import { useScrollOnErrors } from '../../utils'
import ProductLayout from '../product/ProductLayout'

const Triflex = () => {
	const navigate = useNavigate()

	const dispatch = useDispatch()

	const personalFormValues = useSelector(selectPersonal)

	const planValues = useSelector(selectTriflex)

	const [errors, setErrors] = useState([])

	const productName = 'triflex'

	const [illustrate, { isLoading, isError }] = useGetTriflexIllustrationMutation({
		fixedCacheKey: 'triflex-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/triflex/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		// clear errors
		setErrors([])

		// update store
		dispatch(triflexPlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(triflexQuoteUpdated({ quote }))

			navigate('/product/triflex/summary')
		} catch (error) {
			if (error?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (error?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (error?.data) {
				const { messages, qid, frequency_premium, annual_premium } = error.data

				if (qid) {
					dispatch(triflexQuoteUpdated({ qid, frequency_premium, annual_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/triflex')
	}

	const onSummaryBack = () => {
		navigate('/product/triflex/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Triflex'
						breadcrumbTitle='Triflex'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<TriflexPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>
				<Route
					path='summary'
					element={
						<TriflexSummary
							personal={personalFormValues}
							values={planValues}
							onBack={onSummaryBack}
						/>
					}
				/>
				<Route path='*' element={<Navigate to='/product/triflex' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default Triflex
