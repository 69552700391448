import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	Button,
	Divider,
	Grid,
	Header,
	Icon,
	List,
	Popup,
	Segment,
	SegmentGroup,
	Table,
} from 'semantic-ui-react'
import {
	formatCurrency,
	generateTriflexAnnuityPdf,
	getAnnualPremiumCovered,
	getAnnuityPlan,
	getGender,
	getPaymentFrequencyText,
	getWpExtraRating,
	onOpenUnderwritingRequirements,
	useQuoteNotFound,
} from '../../utils'
import 'jspdf-autotable'
import { triflexAnnuityQuoteUpdated } from './triflexAnnuitySlice'
import {
	useCopyIllustrationMutation,
	useLazyCheckUnderwritingRequirementsQuery,
	useOpenUnderwritingRequirementsMutation,
	useSaveIllustrationMutation,
	useUploadIllustrationMutation,
} from '../../services/novaApi'
import { useDispatch, useSelector } from 'react-redux'
import SummaryActions from '../../components/SummaryActions'
import { version } from '../../utils'

function hasBenefits(benefits) {
	return !!benefits?.wp || !!benefits?.rr?.applicant_age
}

function getAdditionalDepositType(plan) {
	if (plan.pay_type === '1') {
		return `Annually, at the beginning of policy year(s) ${plan.year_start} to ${plan.year_end}`
	}

	if (plan.pay_type === '2') {
		return 'Annually at the beginning of every year'
	}

	return null
}

const TriflexAnnuitySummary = ({ personal, values, onBack }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { quote, plan, benefits, ratings, is_saved } = values
	const [saveIllustration, { isLoading: isSaving }] = useSaveIllustrationMutation()
	const [uploadIllustration, { isLoading: isUploading }] = useUploadIllustrationMutation()
	const [copyIllustration, { isLoading: isCopying }] = useCopyIllustrationMutation()
	const [initializeUW, { isLoading: isInitializingUW }] = useOpenUnderwritingRequirementsMutation()
	const [checkRequirements, { isFetching: isCheckingReqs }] =
		useLazyCheckUnderwritingRequirementsQuery()

	const appkey = useSelector(state => state.auth.appkey)

	const isGold = plan.type === '1'
	const hasOccFprem = Number(quote.occfprem) > 0 && isGold
	const hasMedFprem = Number(quote.medfprem) > 0 && isGold
	const hasAvoFprem = Number(quote.avofprem) > 0 && isGold

	const handleCopyIllustration = async () => {
		try {
			if (!values.is_saved) {
				await handleSave()
			}

			await copyIllustration({ ids: quote?.qid, agent: personal.agent }).unwrap()

			toast.success('Illustration copied')
		} catch (err) {
			if (!!err?.data?.msg) {
				toast.error(err.data.msg)
				return
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleSave = async () => {
		if (!!values.is_saved) {
			return
		}

		try {
			await saveIllustration({ qid: quote?.qid }).unwrap()

			dispatch(triflexAnnuityQuoteUpdated({ is_saved: true }))

			toast.success('Illustration saved.')
		} catch (error) {
			if (!!error?.data?.msg) {
				toast.error(error.data.msg)
			}

			toast.error('Server error, please contact technology services')
			throw error
		}
	}

	const handleSaveAndClose = async () => {
		try {
			if (!is_saved) {
				await handleSave()
			}
			navigate('/')
		} catch (error) { }
	}

	const handleOpenApplication = () => {
		window.open(
			`${process.env.REACT_APP_EAPPS_URL}/applications/annuity/?policy=${quote?.policy}&resume=true&token=${appkey}`,
		)
	}

	const handleStart = async () => {
		try {
			if (benefits?.wp == true) {
				let res = await checkRequirements(quote.qid).unwrap()
				if (res.uwreqs == false) {
					return toast.error('Underwriting requirements must be completed first!!')
				}
			}

			// upload the illustration to the server
			const doc = generateTriflexAnnuityPdf({ ...personal, ...values })

			const file = doc.output('blob')

			await uploadIllustration({ file, qid: quote?.qid }).unwrap()

			// save the illustration if it isn't saved
			handleSave()

			// open eapps in a new tab
			window.open(
				`${process.env.REACT_APP_EAPPS_URL}/applications/annuity/?agtno=${personal.agent}&cltno=${personal.client}&appclient=${personal.applicant_client}&qid=${quote.qid}&isNew=true&token=${appkey}`,
			)

			// go back to the plan screen
			navigate('/product/triflex-annuity/plan')
		} catch (error) {
			if (!!error?.data?.msg) {
				toast.error(error.data.msg)
				return
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const handleDownload = async () => {
		const doc = generateTriflexAnnuityPdf({ ...personal, ...values })

		const blob = doc.output('blob')
		const file = new Blob([blob], { type: 'application/pdf' })

		try {
			toast.info('Uploading illustration')

			await uploadIllustration({ file, qid: quote?.qid }).unwrap()
		} catch (err) {
			if (err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Error uploading illustration')
		}

		const planType = getAnnuityPlan(plan.type)

		doc.save(`Triflex Annuity ${planType} - ${quote?.qid}${version}`)

		toast.success('Illustration downloaded.')
	}

	const handleOpenUnderwritingRequirements = async () => {
		let qid = quote?.qid
		try {
			await initializeUW({ qid }).unwrap()
			onOpenUnderwritingRequirements(qid)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	useQuoteNotFound(quote?.qid)

	return (
		<div className='summary'>
			<SummaryActions
				isLoading={isSaving || isUploading || isCopying || isInitializingUW || isCheckingReqs}
				isSaved={is_saved}
				disableSubmit
				hasApplication={!!quote?.appid}
				handleSave={handleSave}
				handleSaveAndClose={handleSaveAndClose}
				handleDownload={handleDownload}
				handleStart={handleStart}
				handleCopy={handleCopyIllustration}
				handleOpen={handleOpenApplication}
				handleUnderwritingReqirements={handleOpenUnderwritingRequirements}
			/>
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Personal Information</Header>
				</Segment>
				<Segment padded>
					<Header size='small'>
						{personal.first_name} {personal.last_name}
					</Header>
					<Grid columns={3} divided>
						<Grid.Column>
							<Header sub>Sex</Header>
							<span>{getGender(personal.sex)}</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Age</Header>
							<span>{personal.issue_age} years</span>
						</Grid.Column>
						<Grid.Column>
							<Header sub>Retirement Age</Header>
							<span>{plan.retirement_age}</span>
						</Grid.Column>
					</Grid>
				</Segment>
			</SegmentGroup>
			<Divider hidden />
			<SegmentGroup>
				<Segment secondary>
					<Header as='h4'>Plan Information</Header>
				</Segment>
				<Segment padded>
					<List divided relaxed className='summary-list'>
						{!!plan.additional_deposit && (
							<List.Item>
								<List.Content floated='right'>
									{formatCurrency(plan.additional_deposit)}{' '}
									<Popup
										position='top center'
										content={getAdditionalDepositType(plan)}
										trigger={<Icon name='question circle' />}
									/>
								</List.Content>
								<List.Content>Un-scheduled Premium Deposit</List.Content>
							</List.Item>
						)}
						<List.Item>
							<List.Content floated='right'>
								{getPaymentFrequencyText(personal.payment_frequency)}
							</List.Content>
							<List.Content>Payment Frequency</List.Content>
						</List.Item>
						<List.Item>
							<List.Content floated='right'>{formatCurrency(quote?.standard_fprem)}</List.Content>
							<List.Content>Standard Frequency Premium</List.Content>
						</List.Item>
						{hasOccFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.occfprem)}</List.Content>
								<List.Content>Occupational Extra Premium</List.Content>
							</List.Item>
						)}
						{hasMedFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.medfprem)}</List.Content>
								<List.Content>Medical Extra Premium</List.Content>
							</List.Item>
						)}
						{hasAvoFprem && (
							<List.Item>
								<List.Content floated='right'>{formatCurrency(quote?.avofprem)}</List.Content>
								<List.Content>Avocation Extra Premium</List.Content>
							</List.Item>
						)}
						<List.Item>
							<List.Content floated='right'>
								{formatCurrency(quote?.frequency_premium)}
							</List.Content>
							<List.Content>Total Frequency Premium</List.Content>
						</List.Item>
					</List>
				</Segment>
			</SegmentGroup>
			{hasBenefits(benefits) && (
				<>
					<Divider hidden />
					<SegmentGroup>
						<Segment secondary>
							<Header as='h4'>Supplemental Benefits</Header>
						</Segment>
						<Segment basic style={{ padding: 0 }}>
							<Table>
								<Table.Header>
									<Table.HeaderCell>Benefit</Table.HeaderCell>
									<Table.HeaderCell>Details</Table.HeaderCell>
									<Table.HeaderCell>Rating</Table.HeaderCell>
								</Table.Header>
								<Table.Body>
									{!!benefits?.wp && (
										<Table.Row>
											<Table.Cell>Waiver Premium</Table.Cell>
											<Table.Cell>Covered</Table.Cell>
											<Table.Cell>Standard Rating {getWpExtraRating(ratings.wp)}</Table.Cell>
										</Table.Row>
									)}

									{!!benefits?.rr?.applicant_age && (
										<Table.Row>
											<Table.Cell>Retrenchment Rider</Table.Cell>
											<Table.Cell>
												{`Annual Premium Covered: ${getAnnualPremiumCovered(
													benefits.rr.additional_premium,
													quote?.frequency_premium,
													personal.payment_frequency,
												)}`}
											</Table.Cell>
											<Table.Cell>Standard Rating</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							</Table>
						</Segment>
					</SegmentGroup>
				</>
			)}
			<Divider hidden />
			<Button negative floated='right' onClick={onBack}>
				Back
			</Button>
		</div>
	)
}

export default TriflexAnnuitySummary
