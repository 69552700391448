import React, { useEffect, useRef } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Button, Divider, Form, Header, Icon, Segment, SegmentGroup } from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	hasOnlyLetters,
	validateInput,
	frequencyOptions,
	isMaxStringLength,
	calculateIssueAge,
} from '../../utils'

const isLongName = isMaxStringLength(30)
const validateAlphaLongName = str => validateInput(str, hasOnlyLetters, isLongName)

const PersonalForm = ({ onNext, onCancel, defaultValues, product }) => {
	const submitCountRef = useRef(0)
	const showExtraApplicantInformation = ['M', 'F'].includes(defaultValues.applicant_sex)
	const showApplicantInformation =
		defaultValues.applicant_sex === 'C' && product === 'triflex-annuity'

	const methods = useForm({
		reValidateMode: 'onChange',
		defaultValues,
	})
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = methods

	const applicationDate = methods.watch('application_date')
	const dateOfBirth = methods.watch('date_of_birth')

	// scroll to top if errors after submit
	useEffect(() => {
		const { submitCount, errors } = methods.formState
		const hasErrors = Object.keys(errors) !== 0
		if (hasErrors && submitCountRef.current !== submitCount) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
			submitCountRef.current = submitCount
			toast.error('There were some errors with your submission')
		}
	}, [methods.formState])

	return (
		<>
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onNext)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Insured Information</Header>
						</Segment>
						<Segment>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='first_name'
									rules={{
										required: 'Please enter first name',
										setValueAs: value => String(value).trim(),
										pattern: {
											value: /^[a-zA-Z_\s'-]*$/,
											message: 'Only letters allowed.',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											fluid
											value={value}
											required
											label='First Name'
											onChange={(event, data) => {
												if (!validateAlphaLongName(data.value)) {
													return
												}

												onChange(data.value)
											}}
											onBlur={onBlur}
											error={!!errors.first_name && { content: errors.first_name.message }}
										/>
									)}
								/>
								<Controller
									control={control}
									name='last_name'
									rules={{
										required: 'Please enter last name',
										pattern: {
											value: /^[A-Za-z_\s'-]+$/,
											message: 'Only letters allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											fluid
											value={value}
											required
											label='Last Name'
											onChange={(event, data) => {
												if (!validateAlphaLongName(data.value)) {
													return
												}
												onChange(data.value)
											}}
											onBlur={onBlur}
											error={!!errors.last_name && errors.last_name.message}
										/>
									)}
								/>
							</Form.Group>
							{/*  */}
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='date_of_birth'
									rules={{ required: 'Enter date of birth' }}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											required
											label='Date of birth'
											type='date'
											value={value}
											onChange={(event, data) => {
												if (!!data.value && !!applicationDate) {
													const issueAge = calculateIssueAge(applicationDate, data.value)
													methods.setValue('issue_age', issueAge)
												}
												onChange(data.value)
											}}
											onBlur={onBlur}
											error={!!errors.date_of_birth && errors.date_of_birth.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='issue_age'
									rules={{ valueAsNumber: true, required: true }}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											readOnly
											required
											label='Issue Age'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
										/>
									)}
								/>
							</Form.Group>
							{/*  */}
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='payment_frequency'
									rules={{
										required: 'Select frequency',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											value={value}
											search
											required
											clearable
											label='Payment Frequency'
											options={frequencyOptions}
											onBlur={onBlur}
											onChange={(event, data) => onChange(data.value)}
											error={!!errors.payment_frequency && errors.payment_frequency.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='application_date'
									rules={{
										required: 'Select application date',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Application Date'
											type='date'
											value={value}
											onChange={(event, data) => {
												if (!!data.value && !!dateOfBirth) {
													const issueAge = calculateIssueAge(data.value, dateOfBirth)
													methods.setValue('issue_age', issueAge)
												}
												onChange(data.value)
											}}
											onBlur={onBlur}
											error={!!errors.application_date}
										/>
									)}
								/>
							</Form.Group>
							{/*  */}
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='sex'
									rules={{ required: 'Select an option' }}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors.sex} required>
											<label>Sex</label>
											<Button.Group fluid>
												<Button
													icon
													role='button'
													type='button'
													primary={value === 'M'}
													onClick={() => onChange('M')}
													onBlur={onBlur}
												>
													<Icon name='man' /> Male
												</Button>
												<Button
													icon
													role='button'
													type='button'
													primary={value === 'F'}
													onClick={() => onChange('F')}
													onBlur={onBlur}
												>
													<Icon name='woman' /> Female
												</Button>
											</Button.Group>
											<FieldError errors={errors} name='sex' />
										</Form.Field>
									)}
								/>
								<Controller
									control={control}
									name='smoker'
									rules={{ required: 'Select an option' }}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors.sex} required>
											<label>Smoker</label>
											<Button.Group fluid>
												<Button
													icon
													role='button'
													type='button'
													primary={value === '0'}
													onClick={() => onChange('0')}
													onBlur={onBlur}
												>
													No
												</Button>
												<Button
													icon
													role='button'
													type='button'
													primary={value === '1'}
													onClick={() => onChange('1')}
													onBlur={onBlur}
												>
													Yes
												</Button>
											</Button.Group>
											<FieldError errors={errors} name='smoker' />
										</Form.Field>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>

					{showApplicantInformation && (
						<>
							<Divider hidden section />
							<SegmentGroup>
								<Segment secondary>
									<Header>Applicant Information</Header>
								</Segment>
								<Segment>
									<Form.Group widths='equal'>
										<Controller
											control={control}
											name='applicant_first_name'
											rules={{
												setValueAs: value => String(value).trim(),
												required: "Please enter the applicant's first name",
											}}
											render={({ value, onChange, onBlur }) => (
												<Form.Input
													label='Applicant First Name'
													value={value}
													onChange={(event, data) => {
														if (!validateAlphaLongName(data.value)) {
															return
														}

														onChange(data.value)
													}}
													onBlur={onBlur}
												/>
											)}
										/>
										<Controller
											control={control}
											name='applicant_last_name'
											rules={{ setValueAs: value => String(value).trim() }}
											render={({ value, onChange, onBlur }) => (
												<Form.Input
													label='Applicant Last Name'
													value={value}
													onChange={(event, data) => {
														if (!validateAlphaLongName(data.value)) {
															return
														}

														onChange(data.value)
													}}
													onBlur={onBlur}
												/>
											)}
										/>
									</Form.Group>

									{showExtraApplicantInformation && (
										<Form.Group widths='equal'>
											<Controller
												control={control}
												name='applicant_date_of_birth'
												rules={{ valueAsDate: true }}
												render={({ value, onChange, onBlur }) => (
													<Form.Input
														label='Applicant Date of Birth'
														type='date'
														value={value}
														onChange={onChange}
														onBlur={onBlur}
													/>
												)}
											/>
											<Controller
												control={control}
												name='applicant_age'
												rules={{ valueAsNumber: true }}
												render={({ value, onChange, onBlur }) => (
													<Form.Input
														label='Applicant Age'
														type='number'
														value={value}
														onChange={onChange}
														onBlur={onBlur}
													/>
												)}
											/>
										</Form.Group>
									)}
								</Segment>
							</SegmentGroup>
						</>
					)}
					<Divider hidden section />
					<Button onClick={handleSubmit(onNext)} floated='right' basic primary>
						Next
					</Button>
					<Button
						type='button'
						style={{ marginRight: '1em' }}
						floated='right'
						basic
						onClick={onCancel}
					>
						Cancel
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default PersonalForm
