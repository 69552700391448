import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Header, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'
import { extraRatingsType1 } from '../../utils'

const ClifeRider = ({ validateSumAssured }) => {
	const { control, errors } = useFormContext()

	return (
		<Benefit header='C-Life'>
			<Controller
				control={control}
				name='benefits.cl.sum_assured'
				rules={{
					required: 'Enter the sum assured',
					pattern: {
						value: /^\d+$/,
						message: 'Decimals not allowed',
					},
					min: {
						value: 75000,
						message: 'Must be between $75,000 and $1,999,999',
					},
					max: {
						value: 1999999,
						message: 'Must be between $75,000 and $1,999,999',
					},
					validate: validateSumAssured,
				}}
				render={({ value, onChange, onBlur }) => (
					<Form.Field error={!!errors?.benefits?.cl?.sum_assured}>
						<label htmlFor=''>Sum Assured</label>
						<Input
							name='benefits.cl.sum_assured'
							id='benefits.cl.sum_assured'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							type='number'
							step='1'
						/>
						{!!!errors?.benefits?.cl?.sum_assured && (
							<Label pointing>Must be between $75,000 and $1,999,999</Label>
						)}
						<FieldError errors={errors} name='benefits.cl.sum_assured' />
					</Form.Field>
				)}
			/>

			<Header content='Term' />
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.cl.1.occrate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							label='Occupational Rate'
							type='number'
							step='.25'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors.ratings?.cl['1']?.occrate && errors.ratings.cl['1'].occrate.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.cl.1.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							label='Medical Rate'
							value={value}
							search
							clearable
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType1}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.cl.1.avorate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							label='Avocation Rate'
							type='number'
							step='.25'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors.ratings?.cl['1']?.avorate && errors.ratings.cl['1'].avorate.message}
						/>
					)}
				/>
			</Form.Group>

			<Header content='Critical Illness' />
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.cl.0.occrate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							label='Occupational Rate'
							type='number'
							step='.25'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors.ratings?.cl['0']?.occrate && errors.ratings.cl['0'].occrate.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.cl.0.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							label='Medical Rate'
							value={value}
							search
							clearable
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType1}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.cl.0.avorate'
					rules={{
						min: {
							value: 0,
							message: 'Negative value not allowed',
						},
						pattern: {
							value: /^[0-9]*(\.[0-9]{0,2})?$/,
							message: 'Only two decimal placed allowed',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Input
							label='Avocation Rate'
							type='number'
							step='.25'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							fluid
							error={!!errors.ratings?.cl['0']?.avorate && errors.ratings.cl['0'].avorate.message}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default ClifeRider
