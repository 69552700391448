import { nanoid } from '@reduxjs/toolkit'
import { range } from 'lodash'

export const version = '202304'

export const isRetrenchmenRiderInvalid = age => parseInt(age) < 20 || parseInt(age) > 65

/**
 * Array of payment frequency
 */
export const frequencyOptions = [
	{
		key: nanoid(),
		text: 'Annually',
		value: 'A',
	},
	{
		key: nanoid(),
		text: 'Semi-Annually',
		value: 'H',
	},
	{
		key: nanoid(),
		text: 'Quarterly',
		value: 'Q',
	},
	{
		key: nanoid(),
		text: 'Monthly',
		value: 'M',
	},
]

export const surgicalPackageOptions = [
	{
		key: nanoid(),
		text: 'Bronze',
		value: '1',
	},
	{
		key: nanoid(),
		text: 'Silver',
		value: '2',
	},
	{
		key: nanoid(),
		text: 'Gold',
		value: '3',
	},
]

export const DREAD_REDUCTION = 20000

export const maximumBenefitOptions = {
	1: range(1, 1.75, 0.25).map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	2: range(1, 2.25, 0.25).map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	3: range(1, 2.75, 0.25).map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
}

export const deductibleOptions = {
	1: [0, 500, 1000, 1500].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	1.25: [0, 500, 1000, 1500, 1875].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	1.5: [0, 500, 1000, 1500, 2000, 2250].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	1.75: [0, 500, 1000, 1500, 2000, 2500, 2625].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	2: [0, 500, 1000, 1500, 2000, 2500, 3000].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	2.25: [0, 500, 1000, 1500, 2000, 2500, 3000, 3375].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
	2.5: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 3750].map(v => ({
		key: nanoid(),
		text: v.toString(),
		value: v.toString(),
	})),
}

/**
 * Array of retirement age
 */
export const retirementAgeOptions = range(50, 71).map(value => ({
	key: value.toString(),
	text: value.toString(),
	value,
}))

export const termOptions = [
	{
		key: nanoid(),
		text: 'Term to 60',
		value: '705',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 55
		},
	},
	{
		key: nanoid(),
		text: 'Term to 65',
		value: '706',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 60
		},
	},
	{
		key: nanoid(),
		text: 'Term to 70',
		value: '709',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 65
		},
	},
	{
		key: nanoid(),
		text: 'Term to 75',
		value: '775',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 65
		},
	},
	{
		key: nanoid(),
		text: 'Term to 80',
		value: '780',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 70
		},
	},
	{
		key: nanoid(),
		text: 'Term to 85',
		value: '785',
		validate: age => {
			age = parseInt(age)
			return age >= 20 && age <= 70
		},
	},
]

export const unscheduledDepositOptions = [
	{
		key: nanoid(),
		text: 'From policy year',
		value: '1',
	},
	{
		key: nanoid(),
		text: 'Every policy year',
		value: '2',
	},
]

export const extraRatingsType1 = ['0.00', ...range(125, 525, 25)].map(v => ({
	key: v.toString(),
	text: v.toString(),
	value: v.toString(),
}))

export const extraRatingsType1_9 = ['0.00', ...range(125, 925, 25)].map(v => ({
	key: v.toString(),
	text: v.toString(),
	value: v.toString(),
}))

export const extraRatingsType2 = [0, ...range(1.25, 3.25, 0.25)].map(value => ({
	key: value.toFixed(2).toString(),
	text: value.toFixed(2).toString(),
	value: value.toFixed(2).toString(),
}))

export const extraRatingsType4 = [
	{
		key: '0',
		text: 'A',
		value: '0',
	},
	{
		key: '1',
		text: 'B',
		value: '1',
	},
	{
		key: '2',
		text: 'C',
		value: '2',
	},
]

export const ddrRate1Options = range(25, 125, 25).map(v => ({
	key: v.toString(),
	text: `${v}%`,
	value: v.toString(),
}))

export const ddrRate2Options = range(25, 100, 25).map(v => ({
	key: v.toString(),
	text: `${v}%`,
	value: v.toString(),
}))

export const ddrRate3Options = range(25, 75, 25).map(v => ({
	key: v.toString(),
	text: `${v}%`,
	value: v.toString(),
}))

export const ddrRate4Options = [25].map(v => ({
	key: v.toString(),
	text: `${v}%`,
	value: v.toString(),
}))

export const mppPlanOptions = [
	{
		key: nanoid(),
		text: 'MPP 10',
		value: '1101',
		plan: '10',
		validate: age => {
			return parseInt(age) >= 18 && parseInt(age) <= 55
		},
	},
	{
		key: nanoid(),
		text: 'MPP 15',
		value: '1102',
		plan: '15',
		validate: age => {
			return parseInt(age) >= 18 && parseInt(age) <= 55
		},
	},
	{
		key: nanoid(),
		text: 'MPP 20',
		value: '1103',
		plan: '20',
		validate: age => {
			return parseInt(age) >= 18 && parseInt(age) <= 50
		},
	},
	{
		key: nanoid(),
		text: 'MPP 25',
		value: '1104',
		plan: '25',
		validate: age => {
			return parseInt(age) >= 18 && parseInt(age) <= 45
		},
	},
	{
		key: nanoid(),
		text: 'MPP 30',
		value: '1105',
		plan: '50',
		validate: age => {
			return parseInt(age) >= 18 && parseInt(age) <= 40
		},
	},
]
