import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Nav from '../features/nav/Nav'

const Layout = () => {
	return (
		<div className='App'>
			<Nav />
			<Outlet />
			<ToastContainer
				position='bottom-left'
				autoClose={5000}
				hideProgressBar={true}
				closeOnClick={true}
				newestOnTop={true}
			/>
		</div>
	)
}

export default Layout
