import React from 'react'
import { Header, Segment, SegmentGroup } from 'semantic-ui-react'
export * from './WaiverPremium'
export * from './DreadDisease'

const Benefit = ({ header, subHeader, children }) => {
	return (
		<SegmentGroup>
			<Segment secondary>
				<Header>
					{header}
					{subHeader && <Header.Subheader>{subHeader}</Header.Subheader>}
				</Header>
			</Segment>
			<Segment>{children}</Segment>
		</SegmentGroup>
	)
}

export default Benefit
