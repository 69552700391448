import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import { useScrollOnErrors } from '../../utils'
import PersonalForm from '../personal/PersonalForm'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import ProductLayout from '../product/ProductLayout'
import ClifePlan from './ClifePlan'
import { clifePlanUpdated, clifeQuoteUpdated, selectClife } from './clifeSlice'
import { useGetClifeIllustrationMutation } from './clifeSlice'
import ClifeSummary from './ClifeSummary'

const Clife = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const personalFormValues = useSelector(selectPersonal)
	const planValues = useSelector(selectClife)
	const [errors, setErrors] = useState([])

	const productName = 'clife'

	const [illustrate, { isLoading, isError }] = useGetClifeIllustrationMutation({
		fixedCacheKey: 'clife-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/clife/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/clife')
	}

	const onPlanSubmit = async values => {
		setErrors([])

		const qid = planValues.quote?.qid || ''

		dispatch(clifePlanUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(clifeQuoteUpdated({ quote }))

			navigate('/product/clife/summary')
		} catch (err) {
			if (err?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (err?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (err?.data) {
				const { messages, qid, frequency_premium, annual_premium, min_premium } = err.data

				if (qid) {
					dispatch(clifeQuoteUpdated({ qid, frequency_premium, annual_premium, min_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onSummaryBack = () => {
		navigate('/product/clife/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='C-Life'
						breadcrumbTitle='C-Life'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<ClifePlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>

				<Route
					path='summary'
					element={
						<ClifeSummary
							personal={personalFormValues}
							values={planValues}
							onBack={onSummaryBack}
						/>
					}
				/>
				<Route path='*' element={<Navigate to='/product/clife' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default Clife
