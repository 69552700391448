import React, { useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Header,
	Icon,
	Input,
	Label,
	List,
	Loader,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import { useValidateSumAssuredQuery } from '../../services/novaApi'
import {
	extraRatingsType1_9,
	formatCurrency,
	isSelectedBenefits,
	useBenefits,
	useScrollOnPlanErrors,
	isRetrenchmenRiderInvalid,
	isWddaValid,
	isAirValid,
	useValidateProduct,
	isClifeValid,
	isTermValid,
} from '../../utils'
import { WaiverPremium } from '../benefits'
import AccidentalDeathRider from '../benefits/AccidentalDeathRider'
import AirRider from '../benefits/AirRider'
import BenefitsContainer from '../benefits/BenefitsContainer'
import ClifeRider from '../benefits/ClifeRider'
import DreadDisease from '../benefits/DreadDisease'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import TermRider from '../benefits/TermRider'
import WddaRider from '../benefits/WddaRider'
import { products } from '../product/ProductList'
import { olnpSumAssuredChanged } from './olnpSlice'

const initialBenefits = {
	ddr: false,
	ad: false,
	rr: false,
	air: false,
	wp: false,
	lt: false,
	cl: false,
	wdda: false,
}

const isWaiverPremiumInvalid = age => {
	return Number(age) < 15 || Number(age) > 50
}

const isDdrInvalid = age => {
	return Number(age) < 20 || Number(age) > 60
}

const isAdValid = age => {
	return Number(age) < 15 || Number(age) > 55
}

const OlnpPlan = ({
	plan,
	personal,
	isLoading,
	onSubmit,
	onPrevious,
	clearErrors,
	errors: serverErrors,
}) => {
	const dispatch = useDispatch()

	const methods = useForm({
		defaultValues: { ...plan },
		revalidateMode: 'onChange',
	})

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors, isSubmitting },
	} = methods

	const planTypeRef = useRef(watch('plan.type'))
	const planType = watch('plan.type')
	const sumAssured = watch('plan.sum_assured', '0')

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const {
		isLoading: isMinPremiumLoading,
		isFetching: isMinPremiumFetching,
		data: minPremium,
	} = useValidateSumAssuredQuery({
		product: 'olnp',
		pcode: 5,
		type: planTypeRef.current,
		age: personal.issue_age,
		sex: personal.sex,
		smoker: personal.smoker,
		frequency: personal.payment_frequency,
		date: personal.application_date,
		sa: 0,
	})

	const onSumAssuredChanged = () => {
		setValue('benefits.ddr.rate_1', '')
		setValue('benefits.ddr.rate_2', '')
		setValue('benefits.ddr.rate_3', '')
		setValue('benefits.ddr.rate_4', '')

		setValue('ratings.ddr.occrate', '0.00')
		setValue('ratings.ddr.medrate', '0.00')
		setValue('ratings.ddr.avorate', '0.00')

		setValue('benefits.cl.sum_assured', '')

		setValue('ratings.cl.0.occrate', '0.00')
		setValue('ratings.cl.0.medrate', '0.00')
		setValue('ratings.cl.0.avorate', '0.00')

		setValue('ratings.cl.1.occrate', '0.00')
		setValue('ratings.cl.1.medrate', '0.00')
		setValue('ratings.cl.1.avorate', '0.00')

		setValue('benefits.ad.sum_assured', '')

		setValue('ratings.ad.occrate', '0.00')
		setValue('ratings.ad.medrate', '0.00')
		setValue('ratings.ad.avorate', '0.00')

		setValue('benefits.air.units', '0', { shouldDirty: true })

		setValue('ratings.air.occrate', '1')
		setValue('ratings.air.medrate', '1')
		setValue('ratings.air.avorate', '1')

		setBenefits({ ...benefits, ddr: false, cl: false, ad: false, air: false })

		dispatch(olnpSumAssuredChanged())
	}

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'olnp')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	useEffect(() => {
		if (isMinPremiumFetching || isMinPremiumLoading) {
			return
		}
		if (planType !== planTypeRef.current) {
			setValue('plan.type', planTypeRef.current)
		}
	}, [planType, setValue, isMinPremiumFetching, isMinPremiumLoading])

	if (isMinPremiumLoading || isMinPremiumFetching) {
		return <Loader active>Loading...</Loader>
	}

	return (
		<>
			{!!serverErrors.length && (
				<Message
					error
					onDismiss={clearErrors}
					header='There were some errors with your submission'
					list={serverErrors.map(value => value[Object.keys(value)[0]])}
				/>
			)}
			<Divider hidden />
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.sum_assured'
									rules={{
										required: 'Enter sum assured',
										pattern: {
											value: /^\d+$/,
											message: 'Decimals not allowed',
										},
										min: {
											value: minPremium?.min,
											message: `Minimum sum assured is ${formatCurrency(minPremium?.min, 0)}`,
										},
									}}
									render={({ value, onChange, onBlur }, { isDirty }) => (
										<Form.Field>
											<label htmlFor='plan.sum_assured'>Sum Assured</label>
											<Input
												type='number'
												step='1'
												fluid
												value={value}
												onChange={onChange}
												loading={isMinPremiumLoading || isMinPremiumFetching}
												error={!!errors?.plan?.sum_assured && errors.plan.sum_assured.message}
												onBlur={event => {
													onBlur(event)

													if (isDirty) {
														onSumAssuredChanged()
													}
												}}
											/>
											{!!minPremium && !!!errors?.plan?.sum_assured && (
												<Label pointing>
													Minimum sum assured is {formatCurrency(minPremium?.min, 0)}
												</Label>
											)}
											<FieldError errors={errors} name='plan.sum_assured' />
										</Form.Field>
									)}
								/>
								<Controller
									control={control}
									name='plan.type'
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors?.plan?.type}>
											<label>Type</label>
											<Button.Group fluid>
												<Button
													role='button'
													type='button'
													primary={value === '1'}
													onClick={async () => {
														onChange('1')
														planTypeRef.current = '1'
													}}
													onBlur={onBlur}
												>
													Basic
												</Button>
												<Button
													role='button'
													type='button'
													primary={value === '2'}
													onClick={async () => {
														onChange('2')
														planTypeRef.current = '2'
													}}
													onBlur={onBlur}
												>
													Enhanced
												</Button>
											</Button.Group>
											<FieldError errors={errors} name='plan.type' />
										</Form.Field>
									)}
								/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.olnp.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Occupational Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors.ratings?.olnp?.occrate && errors.ratings.olnp.occrate.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.olnp.medrate'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											label='Medical Rate'
											value={value}
											search
											clearable
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											options={extraRatingsType1_9}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.olnp.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Avocation Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={
												!!errors?.ratings?.olnp?.avorate && errors.ratings.olnp.avorate.message
											}
										/>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>
					<Divider hidden />
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										checked={benefits.ddr}
										disabled={isDdrInvalid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => {
											setBenefits({ ...benefits, ddr: data.checked })
										}}
										label='Dread Disease'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.wdda}
										disabled={isWddaValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wdda: data.checked })}
										label='WDDA'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.ad}
										disabled={isAdValid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => setBenefits({ ...benefits, ad: data.checked })}
										label='Accidental Death'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.air}
										disabled={isAirValid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => setBenefits({ ...benefits, air: data.checked })}
										label='AIR'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.wp}
										disabled={isWaiverPremiumInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wp: data.checked })}
										label='Waiver of Premium'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.lt}
										disabled={isTermValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, lt: data.checked })}
										label='Level Term'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.cl}
										disabled={isClifeValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, cl: data.checked })}
										label='C-Life'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) && (
									<>
										{benefits.ddr && (
											<DreadDisease
												qid={plan.quote?.qid || '0'}
												age={personal.issue_age}
												sex={personal.sex}
												smoker={personal.smoker}
												product='olnp'
												frequency={personal.payment_frequency}
												applicationDate={personal.application_date}
											/>
										)}
										{benefits.ad && <AccidentalDeathRider />}
										{benefits.rr && <RetrenchmentRider />}
										{benefits.air && <AirRider />}
										{benefits.wp && <WaiverPremium />}
										{benefits.lt && <TermRider issueAge={personal.issue_age} />}
										{benefits.cl && <ClifeRider />}
										{benefits.wdda && <WddaRider issueAge={personal.issue_age} />}
									</>
								)}
								{!isSelectedBenefits(benefits) && (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>

					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading || isSubmitting}
						loading={isLoading || isSubmitting}
						primary
						floated='right'
					>
						Illustrate
					</Button>
					<Button
						type='button'
						basic
						floated='right'
						disabled={isLoading || isSubmitting}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default OlnpPlan
