import React from 'react'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Form, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'
import { useValidateAirLimitQuery } from '../../services/novaApi'
import { extraRatingsType4 } from '../../utils'

const AirRider = () => {
	const { control, watch, errors } = useFormContext()

	const sum_assured = watch('plan.sum_assured', '0')
	const ad_sum_assured = watch('benefits.ad.sum_assured', '0')
	const units = watch('benefits.air.units', '0')

	const { isLoading, isFetching, data, error, isError } = useValidateAirLimitQuery(
		{
			sum_assured: Number(sum_assured).toString(),
			ad_sum_assured: Number(ad_sum_assured).toString(),
		},
		{ skip: !!units },
	)

	useEffect(() => {
		if ((!isLoading || !isFetching) && isError) {
			let msg = error?.msg || 'Could not validate air units'
			toast.error(msg)
		}
	}, [isLoading, isFetching, error, isError])

	return (
		<Benefit header='AIR'>
			<Controller
				control={control}
				name='benefits.air.units'
				rules={{
					required: 'Enter units',
					min: {
						value: 1,
						message: `Must be between 1 and ${data?.limit}`,
					},
					max: {
						value: data?.limit,
						message: `Must be between 1 and ${data?.limit}`,
					},
				}}
				render={({ value, onChange, onBlur }) => (
					<Form.Field error={!!errors?.benefits?.air}>
						<label htmlFor='benefits.air.units'>Number of Units</label>
						<Input
							id='benefits.air.units'
							name='benefits.air.units'
							type='number'
							step='1'
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							loading={isLoading || isFetching}
						/>
						{(isLoading || isFetching) && <Label pointing>Loading...</Label>}
						{!!!errors?.benefits?.air && !isLoading && !isFetching && (
							<Label pointing>Must be between 1 and {data?.limit}</Label>
						)}
						<FieldError errors={errors} name='benefits.air.units' />
					</Form.Field>
				)}
			/>
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.air.occrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Occupational Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType4}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.air.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Medical Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType4}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.air.avorate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							fluid
							search
							clearable
							label='Avocation Rate'
							value={value}
							onChange={(event, data) => onChange(data.value)}
							onBlur={onBlur}
							options={extraRatingsType4}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default AirRider
