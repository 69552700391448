import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Header,
	Icon,
	Input,
	Label,
	List,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	formatCurrency,
	retirementAgeOptions,
	getMinimumPremium,
	validateAdditionalDeposit,
	unscheduledDepositOptions,
	useScrollOnPlanErrors,
	isSelectedBenefits,
	useBenefits,
	isRetrenchmenRiderInvalid,
	useValidateProduct,
} from '../../utils'
import { WaiverPremium } from '../benefits'
import BenefitsContainer from '../benefits/BenefitsContainer'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import { products } from '../product/ProductList'

const initialBenefits = {
	wp: false,
	rr: false,
}

const isWaiverPremiumInvalid = age => {
	return Number(age) < 18 || Number(age) > 50
}

const TriflexAnnuityPlan = ({
	personal,
	plan,
	onSubmit,
	onPrevious,
	isLoading,
	errors: serverErrors,
	clearErrors,
}) => {
	const minimumPremium = getMinimumPremium(personal.payment_frequency)
	const disablePlatinum = ['M', 'F'].includes(personal.applicant_sex)
	const disableGold = personal.applicant_sex === 'C'
	const validRetirementAgesOptions = retirementAgeOptions.map(r => {
		const planDuration = r.value - personal.issue_age
		if (planDuration < 10) {
			return { ...r, disabled: true }
		}
		return r
	})

	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
	})

	const additionalDeposit = methods.watch('plan.additional_deposit')
	const payType = methods.watch('plan.pay_type')
	const retirementAge = methods.watch('plan.retirement_age')
	const yearStart = methods.watch('plan.year_start')

	const { handleSubmit, control, errors } = methods

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'triflex-annuity')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	return (
		<>
			{!!serverErrors.length && (
				<Message
					error
					onDismiss={clearErrors}
					header='There were some errors with your submission'
					list={serverErrors.map(value => value[Object.keys(value)[0]])}
				/>
			)}
			<Divider hidden />
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.premium'
									rules={{
										required: 'Please enter the premium',
										min: {
											value: minimumPremium,
											message: `Must be at least ${formatCurrency(minimumPremium)}`,
										},
										pattern: {
											value: /^\d+$/,
											message: 'Decimals not allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors?.plan?.premium}>
											<label htmlFor='plan.premium'>Premium</label>
											<Input
												name='plan.premium'
												id='plan.premium'
												step='1'
												type='number'
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
											{!!!errors?.plan?.premium && (
												<Label pointing>Must be at least {formatCurrency(minimumPremium)}</Label>
											)}
											<FieldError errors={errors} name='plan.premium' />
										</Form.Field>
									)}
								/>
								<Controller
									control={control}
									name='plan.retirement_age'
									rules={{
										required: 'Please select a retirement age',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											label='Retirement Age'
											value={value}
											clearable
											search
											options={validRetirementAgesOptions}
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											error={errors?.plan?.retirement_age?.message}
										/>
									)}
								/>
							</Form.Group>
							{/*  */}
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.additional_deposit'
									rules={{
										validate: validateAdditionalDeposit,
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors.plan?.additional_deposit}>
											<label htmlFor='plan.additional_deposit'>Un-scheduled premium deposit</label>
											<Input
												fluid
												type='number'
												step='1'
												name='plan.additional_deposit'
												id='plan.additional_deposit'
												value={value}
												onChange={onChange}
												onBlur={onBlur}
											/>
											{!!!errors.plan?.additional_deposit && (
												<Label pointing>Must be between $500.00 and $999,999</Label>
											)}
											<FieldError errors={errors} name='plan.additional_deposit' />
										</Form.Field>
									)}
								/>
								<Controller
									control={methods.control}
									name='plan.type'
									rules={{
										required: 'Please select plan type',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors?.plan?.type}>
											<label>Type</label>
											<Button.Group fluid>
												<Button
													role='button'
													type='button'
													primary={parseInt(value) === 2}
													disabled={disablePlatinum}
													onClick={() => {
														onChange(2)
													}}
													onBlur={onBlur}
												>
													Platinum
												</Button>
												<Button
													role='button'
													type='button'
													primary={parseInt(value) === 1}
													onClick={() => {
														onChange('1')
													}}
													onBlur={onBlur}
													disabled={disableGold}
												>
													Gold
												</Button>
											</Button.Group>
											<FieldError errors={errors} name='plan.type' />
										</Form.Field>
									)}
								/>
							</Form.Group>
							{!!additionalDeposit && (
								<>
									<Controller
										control={control}
										name='plan.pay_type'
										rules={{
											required: {
												value: !!additionalDeposit,
												message: 'Select an option',
											},
										}}
										render={({ value, onChange, onBlur }) => (
											<Form.Select
												label='Un-scheduled premium deopsits will be made'
												value={value}
												search
												clearable
												options={unscheduledDepositOptions}
												onChange={(event, data) => onChange(data.value)}
												onBlur={onBlur}
												error={errors?.plan?.pay_type?.message}
											/>
										)}
									/>

									{parseInt(payType) === 1 && (
										<Form.Group widths='equal'>
											<Controller
												control={control}
												name='plan.year_start'
												rules={{
													required: {
														value: !!additionalDeposit && parseInt(payType) === 1,
														message: 'Please enter the start year',
													},
													min: {
														value: 1,
														message: `Between 1 and ${
															!!retirementAge
																? Number(retirementAge) - Number(personal.issue_age)
																: 'plan duration (retirement age minus issue age)'
														}`,
													},
													max: {
														value: Number(retirementAge) - Number(personal.issue_age),
														message: `Must be between 1 and ${
															!!retirementAge
																? Number(retirementAge) - Number(personal.issue_age)
																: 'plan duration (retirement age minus issue age)'
														}`,
													},
												}}
												render={({ value, onChange, onBlur }) => (
													<Form.Field error={!!errors?.plan?.year_start}>
														<label htmlFor='plan.year_start'>Start Year</label>
														<Input
															fluid
															name='plan.year_start'
															id='plan.year_start'
															type='number'
															step='1'
															value={value}
															onChange={onChange}
															onBlur={onBlur}
														/>
														{!!!errors?.plan?.year_start && (
															<Label pointing>
																{`Between 1 and 
																${
																	!!retirementAge
																		? Number(retirementAge) - Number(personal.issue_age)
																		: 'plan duration (retirement age - issue age)'
																}`}
															</Label>
														)}
														<FieldError errors={errors} name='plan.year_start' />
													</Form.Field>
												)}
											/>

											<Controller
												control={control}
												name='plan.year_end'
												rules={{
													required: {
														value: !!additionalDeposit && Number(payType) === 1,
														message: 'Please enter the year end',
													},
													min: {
														value: Number(yearStart) >= 1 ? yearStart : 1,
														message: `Between ${
															parseInt(yearStart) >= 1 ? yearStart : 'start year'
														} and ${
															!!retirementAge
																? Number(retirementAge) - Number(personal.issue_age)
																: 'plan duration (retirement age - issue age)'
														}`,
													},
													max: {
														value: Number(retirementAge) - Number(personal.issue_age),
														message: `Between ${
															Number(yearStart) >= 1 ? yearStart : 'start year'
														} and ${
															!!retirementAge
																? Number(retirementAge) - Number(personal.issue_age)
																: 'plan duration (retirement age - issue age)'
														}`,
													},
												}}
												render={({ value, onChange, onBlur }) => (
													<Form.Field error={!!errors?.plan?.year_end}>
														<label htmlFor='plan.year_end'>End year</label>
														<Input
															fluid
															type='number'
															step='1'
															name='plan.year_end'
															id='plan.year_end'
															value={value}
															onChange={onChange}
															onBlur={onBlur}
														/>
														{!!!errors?.plan?.year_end && (
															<Label pointing>
																{`Between ${parseInt(yearStart) >= 1 ? yearStart : '1'} and
																${
																	!!retirementAge
																		? Number(retirementAge) - Number(personal.issue_age)
																		: 'plan duration (retirement age - issue age)'
																}`}
															</Label>
														)}
														<FieldError errors={errors} name='plan.year_end' />
													</Form.Field>
												)}
											/>
										</Form.Group>
									)}
								</>
							)}
						</Segment>
					</SegmentGroup>
					<Divider hidden />
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										checked={benefits.wp}
										disabled={disableGold || isWaiverPremiumInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wp: data.checked })}
										label='Waiver Premium'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) && (
									<>
										{benefits.wp && <WaiverPremium />}
										{benefits.rr && <RetrenchmentRider />}
									</>
								)}
								{!isSelectedBenefits(benefits) && (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
						loading={isLoading}
						primary
						floated='right'
					>
						Illustrate
					</Button>
					<Button
						basic
						type='button'
						floated='right'
						disabled={isLoading}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default TriflexAnnuityPlan
