import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form, Input, Label } from 'semantic-ui-react'
import Benefit from '.'
import FieldError from '../../components/FieldError'

export const RetrenchmentRider = () => {
	const { control, errors } = useFormContext()
	return (
		<Benefit
			header='Retrenchment Rider'
			subHeader='This rider provides clients with the promise that upon retrenchment they will have up to one (1) year without having to make premium payments.'
		>
			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='benefits.rr.applicant_age'
					rules={{
						required: 'Enter applicant age',
						pattern: {
							value: /^[0-9]+$/,
							message: 'Invalid age',
						},
						min: {
							value: 20,
							message: 'Between 20 and 56 inclusive',
						},
						max: {
							value: 56,
							message: 'Between 20 and 56 inclusive',
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Field error={!!errors?.benefits?.rr?.applicant_age}>
							<label htmlFor='benefits.rr.applicant_age'>Applicant Age</label>
							<Input
								type='number'
								fluid
								name='benefits.rr.applicant_age'
								id='benefits.rr.applicant_age'
								step='1'
								value={value}
								onChange={onChange}
								onBlur={onBlur}
							/>
							{!!!errors?.benefits?.rr?.applicant_age && (
								<Label pointing>Between 20 and 56 inclusive</Label>
							)}
							<FieldError errors={errors} name='benefits.rr.applicant_age' />
						</Form.Field>
					)}
				/>
				<Controller
					control={control}
					name='benefits.rr.additional_premium'
					rules={{
						validate: {
							positive: value => {
								if (!!!value) {
									return true
								}

								return (
									(!isNaN(parseFloat(value)) && parseFloat(value) >= 0) || 'Must be $0.00 or more'
								)
							},
						},
					}}
					render={({ value, onChange, onBlur }) => (
						<Form.Field error={!!errors?.benefits?.rr?.additional_premium}>
							<label htmlFor='benefits.rr.additional_premium'>Additional Annual Premium</label>
							<Input
								id='benefits.rr.additional_premium'
								name='benefits.rr.additional_premium'
								type='number'
								step='0.25'
								value={value}
								onChange={onChange}
								onBlur={onBlur}
							/>
							{!!!errors?.benefits?.rr?.additional_premium && (
								<Label pointing>
									Total Additional Annual Payment to be covered for any other Product within the
									Maritime Financial Group
								</Label>
							)}
							<FieldError name='benefits.rr.additional_premium' errors={errors} />
						</Form.Field>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default RetrenchmentRider
