import React, { useRef, useState } from 'react'
import {
	Button,
	Checkbox,
	Container,
	Divider,
	Dropdown,
	Header,
	Icon,
	Input,
	Loader,
	Table,
} from 'semantic-ui-react'
import { useGetIllustrationsQuery } from './illustrationSlice'
import { delay } from 'lodash'
import {
	useCopyIllustrationMutation,
	useDeleteIllustrationMutation,
	useLazyCheckUnderwritingRequirementsQuery,
	useOpenUnderwritingRequirementsMutation,
	useSubmitAsApplicationMutation,
} from '../../services/novaApi'
import { formatCurrency, onOpenUnderwritingRequirements } from '../../utils'
import { personalFormUpdated } from '../personal/personalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { triflexAnnuityFormUpdated } from '../triflex-annuity/triflexAnnuitySlice'
import { clifePlanUpdated } from '../clife/clifeSlice'
import { levelTermPlanUpdated } from '../term/levelTermSlice'
import { mppPlanUpdated } from '../mpp/mppSlice'
import { olnpPlanUpdated } from '../olnp/olnpSlice'
import { triflexPlanUpdated } from '../triflex/triflexSlice'
import { admedPlanUpdated } from '../admed/admedSlice'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import FassUserAgents from './FassUserAgents'

const Illustrations = () => {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const [page, setPage] = useState(1)

	const [per_page, setPerPage] = useState('10')

	const [search, setSearch] = useState('')

	const [selected, setSelected] = useState([])

	const timerRef = useRef(null)

	const { appkey, currentAgent, agent, isFassUser, swdate } = useSelector(state => state.auth)

	const { data, isLoading, isFetching } = useGetIllustrationsQuery(
		{ page, per_page, search, agent: currentAgent },
		{
			refetchOnMountOrArgChange: true,
		},
	)

	const selectedIllustration =
		selected.length === 1 ? data?.data?.find(v => v.qid === selected[0]) : null

	const hasApplication = !!selectedIllustration?.appid

	const [deleteIllustrations, { isLoading: isDeleting }] = useDeleteIllustrationMutation()

	const [copyIllustrations, { isLoading: isCopying }] = useCopyIllustrationMutation()

	const [submitAsApplication, { isLoading: isSubmittingAsApplication }] =
		useSubmitAsApplicationMutation()

	const [checkRequirements, { isLoading: isLoadingReqs, isFetching: isFetchingReqs }] =
		useLazyCheckUnderwritingRequirementsQuery()

	const [initializeUW, { isLoading: isInitializingUW }] = useOpenUnderwritingRequirementsMutation()

	const isNewIllustration = !!swdate && new Date() >= new Date(swdate)

	const isChildIllustration = !!selectedIllustration && Number(selectedIllustration.issue_age) < 18

	const isCompanyIllustration = !!selectedIllustration && selectedIllustration.applicant_sex === 'C'

	const isAnnuityIllustration =
		!!selectedIllustration && selectedIllustration.plan_name.includes('Triflex Annuity')

	const disableStartApplication =
		selected.length !== 1 ||
		(!isAnnuityIllustration && (isChildIllustration || isCompanyIllustration))

	const disableSubmitApplication =
		selected.length !== 1 || isAnnuityIllustration || isNewIllustration

	const nextPage = () => {
		setPage(p => p + 1)
		setSelected([])
	}

	const previousPage = () => {
		setPage(p => Math.max(1, p - 1))
		setSelected([])
	}

	const onSearchChange = (event, data) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current)
		}

		setPage(1)
		setSelected([])

		timerRef.current = delay(setSearch, 500, event.target.value)
	}

	const onDeleteIllustrations = async () => {
		try {
			await deleteIllustrations({ ids: selected, page, per_page, search }).unwrap()
			setSelected([])
		} catch { }
	}

	const onCopyIllustrations = async () => {
		let _agent = agent

		if (isFassUser === true && currentAgent !== '') {
			_agent = currentAgent
		}

		try {
			await copyIllustrations({ ids: selected.join(','), agent: _agent }).unwrap()
		} catch (err) { }
	}

	const onStartApplication = async () => {
		try {
			const { qid, agent, client, applicant_client, benefits } = selectedIllustration

			let applicationURL = `${process.env.REACT_APP_EAPPS_URL}/applications/annuity/?agtno=${agent}&cltno=${client}&appclient=${applicant_client}&qid=${qid}&isNew=true&token=${appkey}`

			if (!isAnnuityIllustration) {
				applicationURL = `${process.env.REACT_APP_EAPPS_V2_URL}/?agtno=${agent}&cltno=${client}&appclient=${applicant_client}&qid=${qid}&isNew=true&token=${appkey}`
			}

			if (!isAnnuityIllustration || (isAnnuityIllustration && benefits?.wp == true)) {
				let res = await checkRequirements(qid).unwrap()
				if (res.uwreqs === false) {
					return toast.error('Underwriting requirements must be completed first!!')
				}
			}

			window.open(applicationURL)

		} catch (error) { }
	}

	const onOpenApplication = () => {
		let { quote, plan_name } = selectedIllustration

		if (!plan_name.includes('Triflex Annuity')) {
			return window.open(
				`${process.env.REACT_APP_EAPPS_V2_URL}/?policy=${quote?.policy}&resume=true&token=${appkey}`,
			)
		}

		window.open(
			`${process.env.REACT_APP_EAPPS_URL}/applications/annuity/?policy=${quote?.policy}&resume=true&token=${appkey}`,
		)
	}

	const onSubmitAsApplication = async () => {
		try {
			const { msg } = await submitAsApplication({ qid: selectedIllustration?.qid }).unwrap()
			toast.info(msg)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Sever error, please contact technology services')
		}
	}

	const onSelectAll = (event, { checked }) => {
		if (checked && data?.data) {
			setSelected(data.data.map(v => v.qid))
			return
		}

		setSelected([])
	}

	const handleOpenUnderwritingRequirements = async () => {
		let [qid] = selected
		try {
			await initializeUW({ qid }).unwrap()
			onOpenUnderwritingRequirements(qid)
		} catch (err) {
			if (!!err?.data?.msg) {
				return toast.error(err.data.msg)
			}

			toast.error('Something went wrong, contact technology services')
		}
	}

	const onOpenIllustration = illustration => {
		const {
			first_name,
			last_name,
			date_of_birth,
			issue_age,
			sex,
			smoker,
			payment_frequency,
			agent,
			client,
			application_date,
			applicant_first_name,
			applicant_last_name,
			applicant_date_of_birth,
			applicant_client,
			applicant_sex,
			applicant_age,
			quote,
			plan,
			ratings,
			benefits,
			plan_name,
			is_saved,
			is_app,
		} = illustration

		// update personal information
		dispatch(
			personalFormUpdated({
				first_name,
				last_name,
				date_of_birth,
				issue_age,
				sex,
				smoker,
				payment_frequency,
				agent,
				client,
				application_date,
				applicant_first_name,
				applicant_last_name,
				applicant_date_of_birth,
				applicant_client,
				applicant_sex,
				applicant_age,
			}),
		)

		dispatch(resetProduct())

		// update plan information
		switch (plan_name) {
			case 'Triflex Annuity - Gold':
			case 'Triflex Annuity - Platinum':
				dispatch(
					triflexAnnuityFormUpdated({
						quote: { ...quote, is_app },
						plan,
						benefits,
						ratings,
						is_saved,
					}),
				)
				navigate('/product/triflex-annuity/plan')
				break
			case 'C-Life':
				dispatch(
					clifePlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }),
				)
				navigate('/product/clife/plan')
				break
			case 'Level Term':
				dispatch(
					levelTermPlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }),
				)
				navigate('/product/term/plan')
				break
			case 'MPP':
				dispatch(mppPlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }))
				navigate('/product/mpp/plan')
				break
			case 'OLNP':
				dispatch(
					olnpPlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }),
				)
				navigate('/product/olnp/plan')
				break
			case 'Triflex':
				dispatch(
					triflexPlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }),
				)
				navigate('/product/triflex/plan')
				break
			case 'Admed':
				dispatch(
					admedPlanUpdated({ quote: { ...quote, is_app }, plan, benefits, ratings, is_saved }),
				)
				navigate('/product/admed/plan')
				break
			default:
		}
	}

	return (
		<Container className='page'>
			<Header size='huge'>Illustrations</Header>
			<Divider hidden section />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex' }}>
					<Dropdown
						selection
						value={per_page}
						options={[
							{ key: '10', text: '10', value: '10' },
							{ key: '15', text: '15', value: '15' },
							{ key: '25', text: '25', value: '25' },
							{ key: '50', text: '50', value: '50' },
						]}
						onChange={(event, data) => {
							setPerPage(data.value)
						}}
					/>
					<FassUserAgents />
				</div>

				<Dropdown
					floating
					text='Actions'
					button
					className='primary'
					loading={
						isDeleting ||
						isCopying ||
						isSubmittingAsApplication ||
						isInitializingUW ||
						isLoadingReqs ||
						isFetchingReqs
					}
					disabled={isLoading || isFetching || !!!selected.length}
					options={[
						{
							key: 'copy',
							icon: 'copy',
							text: 'Copy Illustrations',
							onClick: onCopyIllustrations,
							disabled: !!!selected.length,
						},
						{
							key: 'delete',
							icon: 'delete',
							text: 'Delete Illustrations',
							onClick: onDeleteIllustrations,
							disabled: !!!selected.length,
						},
						{
							key: 'start',
							icon: 'file alternate outline',
							text: hasApplication ? 'Open MDS Application' : 'Start MDS Application',
							disabled: disableStartApplication,
							onClick: hasApplication ? onOpenApplication : onStartApplication,
						},
						{
							key: 'submit',
							icon: 'clipboard check',
							text: 'Submit as Application',
							disabled: disableSubmitApplication,
							onClick: onSubmitAsApplication,
						},
						{
							key: 'tasks',
							icon: 'tasks',
							text: 'Underwriting Requirements',
							onClick: handleOpenUnderwritingRequirements,
							disabled: !!!selected.length || selected.length > 1,
						},
					]}
				/>
			</div>

			<div style={{ display: 'flex', marginTop: '0.5em' }}>
				<Input
					style={{ flex: 1, marginRight: '1em' }}
					fluid
					icon='search'
					iconPosition='left'
					type='text'
					placeholder='Search...'
					onChange={onSearchChange}
				/>

				<Button.Group>
					<Button
						icon
						disabled={page === 1 || isDeleting || isLoading || isFetching}
						onClick={previousPage}
					>
						<Icon name='chevron left' />
						Prev
					</Button>
					<Button icon onClick={nextPage} disabled={isDeleting || isLoading || isFetching}>
						Next
						<Icon name='chevron right' />
					</Button>
				</Button.Group>
			</div>
			<Table selectable singleLine color='blue'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell collapsing>
							<Checkbox
								disabled={data?.data?.length === 0}
								checked={selected.length > 0 && selected.length === data?.data?.length}
								onChange={onSelectAll}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell>Quote ID</Table.HeaderCell>
						<Table.HeaderCell>Firstname</Table.HeaderCell>
						<Table.HeaderCell>Lastname</Table.HeaderCell>
						<Table.HeaderCell>Plan Name</Table.HeaderCell>
						<Table.HeaderCell>Sum Assured / Premium</Table.HeaderCell>
						<Table.HeaderCell>Application</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{(isLoading || isFetching) && (
						<Table.Row>
							<Table.Cell colSpan={7}>
								<Loader active inline='centered' content='Loading' />
							</Table.Cell>
						</Table.Row>
					)}
					{data?.data?.length === 0 && !isLoading && !isFetching && (
						<Table.Row>
							<Table.Cell colSpan={7} textAlign='center'>
								No illustrations found!
							</Table.Cell>
						</Table.Row>
					)}
					{!!data &&
						!isLoading &&
						!isFetching &&
						data.data.map(i => (
							<Table.Row key={i.qid}>
								<Table.Cell>
									<Checkbox
										checked={selected.includes(i.qid)}
										onChange={(event, data) => {
											if (data.checked) {
												setSelected([...selected, i.qid])
												return
											}

											setSelected(selected.filter(value => value !== i.qid))
										}}
									/>
								</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>{i.qid}</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>{i.first_name}</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>{i.last_name}</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>{i.plan_name}</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>
									{formatCurrency(i.sum_assured, 0)}
								</Table.Cell>
								<Table.Cell onClick={() => onOpenIllustration(i)}>
									{i?.is_app === '1' ? 'Yes' : 'No'}
								</Table.Cell>
							</Table.Row>
						))}
				</Table.Body>
			</Table>
			<Button.Group floated='right'>
				<Button
					icon
					disabled={page === 1 || isDeleting || isLoading || isFetching}
					onClick={previousPage}
				>
					<Icon name='chevron left' />
					Prev
				</Button>
				<Button icon onClick={nextPage} disabled={isDeleting || isLoading || isFetching}>
					Next
					<Icon name='chevron right' />
				</Button>
			</Button.Group>
		</Container>
	)
}

export default Illustrations
