import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep, defaultsDeep, merge } from 'lodash'
import { resetProduct } from '../../app/actions'
import { novaApi } from '../../services/novaApi'
import { logout } from '../auth/authSlice'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getOlnpIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth, olnp } = api.getState()
				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent
				const qid = olnp?.quote?.qid || ''
				try {
					const response = await baseQuery(
						{
							url: 'olnp',
							method: 'POST',
							body: { ...arg, qid, agent, current_agent, is_fass_user },
						},
						api,
						extraOptions,
					)
					return response
				} catch (error) {
					return error
				}
			},
		}),
	}),
})

export const { useGetOlnpIllustrationMutation } = extendedApiSlice

const initialState = {
	plan: {
		sum_assured: '',
		type: '1',
	},
	benefits: {
		ddr: {
			rate_1: '',
			rate_2: '',
			rate_3: '',
			rate_4: '',
		},
		ad: {
			sum_assured: '',
		},
		rr: {
			applicant_age: '',
			additional_premium: '',
		},
		air: {
			units: '',
		},
		wp: false,
		lt: {
			sum_assured: '',
			term: '',
			type: '',
		},
		cl: {
			sum_assured: '',
		},
		wdda: {
			applicant_age: '',
			duration: '',
		},
	},
	ratings: {
		olnp: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		ddr: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		ad: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		air: {
			occrate: '0',
			medrate: '0',
			avorate: '0',
		},
		wp: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		lt: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
		cl: [
			{
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
			{
				occrate: '0.00',
				medrate: '0.00',
				avorate: '0.00',
			},
		],
		wdda: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
	},
	quote: null,
	is_saved: false,
}

const olnpSlice = createSlice({
	name: 'olnp',
	initialState,
	reducers: {
		olnpPlanUpdated: (state, action) => {
			let newState = cloneDeep(defaultsDeep(action.payload, initialState))

			newState.benefits.wp = newState.benefits?.wp || false

			// prevents resetting quote when illustration is in an error state
			if (!!!newState.quote && !!state.quote) {
				newState.quote = { ...state.quote }
			}

			return merge(state, newState)
		},
		olnpQuoteUpdated: (state, action) => {
			return merge(state, action.payload)
		},
		olnpSumAssuredChanged: state => {
			state.benefits.ddr = { ...initialState.benefits.ddr }
			state.benefits.cl = { ...initialState.benefits.cl }
			state.benefits.ad = { ...initialState.benefits.ad }
			state.benefits.air = { ...initialState.benefits.air }

			state.ratings.ddr = { ...initialState.ratings.ddr }
			state.ratings.cl = { ...initialState.ratings.cl }
			state.ratings.ad = { ...initialState.ratings.ad }
			state.ratings.air = { ...initialState.ratings.air }
		},
	},
	extraReducers: builder => {
		builder
			.addCase(resetProduct, () => {
				return initialState
			})
			.addCase(logout, state => initialState)
	},
})

export const { olnpPlanUpdated, olnpQuoteUpdated, olnpSumAssuredChanged } = olnpSlice.actions
export const selectOlnp = state => state.olnp
export default olnpSlice.reducer
