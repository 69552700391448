import { combineReducers } from 'redux'
import admedSlice from '../features/admed/admedSlice'
import authSlice from '../features/auth/authSlice'
import clifeSlice from '../features/clife/clifeSlice'
import mppSlice from '../features/mpp/mppSlice'
import olnpSlice from '../features/olnp/olnpSlice'
import personalSlice from '../features/personal/personalSlice'
import levelTermSlice from '../features/term/levelTermSlice'
import triflexAnnuitySlice from '../features/triflex-annuity/triflexAnnuitySlice'
import triflexSlice from '../features/triflex/triflexSlice'
import { novaApi } from '../services/novaApi'

const reducer = combineReducers({
	auth: authSlice,
	personal: personalSlice,
	triflexAnnuity: triflexAnnuitySlice,
	mpp: mppSlice,
	clife: clifeSlice,
	olnp: olnpSlice,
	term: levelTermSlice,
	triflex: triflexSlice,
	admed: admedSlice,
	[novaApi.reducerPath]: novaApi.reducer,
})

export default reducer
