import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import Benefit from '.'
import { extraRatingsType2 } from '../../utils'
import DDRRate1 from './DDRRate1'
import DDRRate2 from './DDRRate2'
import DDRRate3 from './DDRRate3'
import DDRRate4 from './DDRRate4'

const DreadDisease = ({ qid, age, sex, smoker, frequency, product, applicationDate: app_date }) => {
	const { watch, control } = useFormContext()

	const rate1 = watch('benefits.ddr.rate_1', '0')
	const rate2 = watch('benefits.ddr.rate_2', '0')
	const rate3 = watch('benefits.ddr.rate_3', '0')

	const showRate2 = Number(rate1) < 100
	const showRate3 = Number(rate1) + Number(rate2) < 100
	const showRate4 = Number(rate1) + Number(rate2) + Number(rate3) < 100

	return (
		<Benefit header='Dread Disease'>
			<Form.Group widths='equal'>
				<DDRRate1
					qid={qid}
					age={age}
					sex={sex}
					smoker={smoker}
					product={product}
					frequency={frequency}
					applicationDate={app_date}
				/>
				{!!rate1 && showRate2 && (
					<DDRRate2
						qid={qid}
						age={age}
						sex={sex}
						smoker={smoker}
						product={product}
						frequency={frequency}
						applicationDate={app_date}
					/>
				)}
				{!!rate2 && showRate3 && (
					<DDRRate3
						qid={qid}
						age={age}
						sex={sex}
						smoker={smoker}
						product={product}
						frequency={frequency}
						applicationDate={app_date}
					/>
				)}
				{!!rate3 && showRate4 && (
					<DDRRate4
						qid={qid}
						age={age}
						sex={sex}
						smoker={smoker}
						product={product}
						frequency={frequency}
						applicationDate={app_date}
					/>
				)}
			</Form.Group>

			<Form.Group widths='equal'>
				<Controller
					control={control}
					name='ratings.ddr.occrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							label='Occupational Rate'
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							onChange={(event, data) => onChange(data.value)}
							options={extraRatingsType2}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.ddr.medrate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							label='Medical Rate'
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							onChange={(event, data) => onChange(data.value)}
							options={extraRatingsType2}
						/>
					)}
				/>
				<Controller
					control={control}
					name='ratings.ddr.avorate'
					render={({ value, onChange, onBlur }) => (
						<Form.Select
							label='Avocation Rate'
							fluid
							search
							clearable
							value={value}
							onBlur={onBlur}
							onChange={(event, data) => onChange(data.value)}
							options={extraRatingsType2}
						/>
					)}
				/>
			</Form.Group>
		</Benefit>
	)
}

export default DreadDisease
