import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import { toast } from 'react-toastify'
import reportLogo from '../assets/illustration-logo.png'
import {
	extraRatingsType4,
	frequencyOptions,
	mppPlanOptions,
	surgicalPackageOptions,
	termOptions,
} from './options'

/**
 * Checks if a string only contains letters.
 *
 * @param {string} str
 * @returns boolean
 */
export const hasOnlyLetters = str => {
	let re = /^[A-Za-z_\s'-.0-9]*$/g
	return re.test(str)
}

export const getAgentContext = state => {
	if (state.isFassUser === true && state.currentAgent !== '') {
		return state.currentAgent
	}

	return state.agent
}

export const getFrequencyMultiplier = freq => {
	switch (freq) {
		case 'H':
			return 2
		case 'Q':
			return 4
		case 'M':
			return 12
		case 'A':
		default:
			return 1
	}
}

export const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}

export const getAirRatingText = rating => {
	return extraRatingsType4.find(v => v.value === rating)?.text || 'N/A'
}

/**
 * Returns a function that checks a string's length against
 * the defined max length.
 *
 * @param {number} max
 * @returns boolean
 */
export const isMaxStringLength = max => str => {
	if (typeof str !== 'string') {
		throw new Error('Expected string but got ' + typeof str)
	}

	return str.length < max + 1
}

/**
 * Tests string input against an array of validators.
 *
 * @param {string} str - The string input
 * @param  {...function} args - Array of validators to test against
 * @returns
 */
export function validateInput(str, ...args) {
	let validators
	if (Array.isArray(args[0])) {
		validators = args[0].slice()
	} else {
		validators = args.slice()
	}

	if (validators.length === 0) {
		throw new Error(`${this.prototype.name} expects at least one validator`)
	}

	return validators.reduce((prev, current) => {
		return prev && current(str)
	}, true)
}

export const getSurgicalPackageText = value => {
	return surgicalPackageOptions.find(p => p.value === value)?.text || ''
}

export const isSelectedBenefits = benefitsList => {
	for (let k in benefitsList) {
		if (benefitsList[k] === true) {
			return true
		}
	}
	return false
}

export const getAdmedDeathBenefitExtraRating = quote => {
	const { occval, medval, avoval } = quote?.ratings?.adm?.death
	const rate = Number(occval) + Number(medval) + Number(avoval)
	if (rate > 1) {
		return `${formatCurrency(rate, 2)} / per thousand`
	}

	return ''
}

export const getWpExtraRating = ({ occrate, medrate, avorate }) => {
	let rating = 1

	occrate = Number(occrate) || 0
	medrate = Number(medrate) || 0
	avorate = Number(avorate) || 0

	if (occrate > 0) {
		rating += occrate - 1
	}

	if (medrate > 0) {
		rating += medrate - 1
	}

	if (avorate > 0) {
		rating += avorate - 1
	}

	return rating > 1 ? `x ${rating}` : ''
}

export const isWddaValid = age => {
	return Number(age) < 0 || Number(age) > 15
}

export const isAirValid = age => {
	return Number(age) < 16 || Number(age) > 55
}

export const isClifeValid = age => {
	return Number(age) < 20 || Number(age) > 65
}

export const isTermValid = age => {
	return Number(age) < 20 || Number(age) > 70
}

export const getExtraRating = ({ occval, medval, avoval }, isPdf = false) => {
	const rating = Number(occval) + Number(medval) + Number(avoval)

	if (!isPdf) {
		return rating > 0 ? `${formatCurrency(rating)} per thousand` : 'Standard Rate'
	}

	return `${formatCurrency(rating, 2)} per thousand`
}

/**
 * Calculates client issue age.
 *
 * @param {string} issueDate Date of the application
 * @param {string} birthDate Client's date of birth
 * @returns number
 */
export const calculateIssueAge = (issueDate, birthDate) => {
	let age = 0
	let tempDate = null
	let dateOfIssue = dayjs(issueDate)
	let dateOfBirth = dayjs(birthDate)

	age = dateOfIssue.year() - dateOfBirth.year()

	if (dateOfIssue.month() < dateOfBirth.month()) {
		age -= 1
	}

	tempDate = dateOfBirth.add(6, 'months').add(age, 'years')

	if (dateOfIssue.isAfter(tempDate)) {
		return (age += 1)
	}

	return age
}

/**
 * Calculates the minimum premium based on the premium frequency.
 *
 * @param {string} frequency A - Annually, H - Semi-Annually, Q -
 * Quarterly, M - Monthly
 *
 * @returns number
 */
export const getMinimumPremium = frequency => {
	switch (frequency) {
		case 'A':
			return 3600
		case 'H':
			return 1800
		case 'Q':
			return 900
		case 'M':
			return 300
		default:
			throw new Error('Invalid premium frequency')
	}
}

export const onOpenUnderwritingRequirements = id => {
	const handle = window.open(`${process.env.REACT_APP_UNDERWRITING_URL}?qid=${id}`, 'uw-req')

	if (!handle) {
		toast.info('Please enable window popup')
	}
}

export const getPaymentFrequencyText = frequency => {
	return frequencyOptions.find(f => f.value === frequency).text
}

/**
 * Return T-Life or E-Life for term type
 * @param {string} term
 * @returns string
 */
export const getTermType = term => {
	switch (term) {
		case '2':
			return 'T-Life'
		case '1':
			return 'E-Life'
		default:
			return 'N/A'
	}
}

export const getTermPlan = plan => {
	return termOptions.find(t => t.value === plan)?.text || 'N/A'
}

export const getAnnualPremiumCovered = (additional_prem, fprem, freq) => {
	const multiplier = getFrequencyMultiplier(freq)
	const result = Number(fprem) * multiplier + Number(additional_prem)
	return formatCurrency(result)
}

/**
 * Formats a number to currency.
 *
 * @param {number|string} amount
 * @param {number} digits Maximum fraction digits
 * @returns
 */
export const formatCurrency = (amount, digits = 2) =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: digits,
	}).format(parseFloat(amount) || 0)

/**
 * Check if the amount is either 0 or between 500 and 1000000.
 *
 * @param {number} amount
 * @returns boolean
 */
export const validateAdditionalDeposit = amount => {
	return (
		amount === '' ||
		Number(amount) === 0 ||
		(Number(amount) >= 500 && Number(amount) < 1000000) ||
		'Must be between $500 and $999,999'
	)
}

/**
 * Return the gender name
 *
 * @param {string} gender
 * @returns string
 */
export const getGender = gender => {
	if (!!!gender) {
		return 'N/A'
	}

	return gender === 'M' ? 'Male' : 'Female'
}

export const getAnnuityPlan = type => {
	return parseInt(type) === 1 ? 'GOLD' : 'PLATNIUM'
}

export const getStandardExtraRating = (occ_value, med_value, avo_value) => {
	occ_value = parseFloat(occ_value) || 0
	med_value = parseFloat(med_value) || 0
	avo_value = parseFloat(avo_value) || 0
	return occ_value + med_value + avo_value
}

/**
 * Returns waiver premium standard rate.
 *
 * @param {string | number} occrate
 * @param {string | number} medrate
 * @param {string | number} avorate
 * @returns string
 */
export const getWaiverPremiumStandardRate = (occrate, medrate, avorate) => {
	let rating = 1

	if (parseFloat(occrate) > 0) {
		rating += parseFloat(occrate) - 1
	}

	if (parseFloat(medrate) > 0) {
		rating += parseFloat(medrate) - 1
	}

	if (parseFloat(avorate) > 0) {
		rating += parseFloat(avorate) - 1
	}

	return rating > 1 ? `x ${rating}` : ''
}

export const getBasePlanExtraRating = quote => {
	const result =
		Number(quote?.occfprem) || 0 + Number(quote?.medfprem) || 0 + Number(quote?.avofprem) || 0
	return formatCurrency(result)
}

export const getSmokerText = smoker => {
	if (smoker === '0') {
		return 'Non-Smoker'
	}

	return 'Smoker'
}

/**
 * Returns an instance of jsPdf of the Triflex Annuity Illustration
 *
 * @param {object} values
 * @returns jsPdf
 */
export const generateTriflexAnnuityPdf = values => {
	const planType = getAnnuityPlan(values.plan.type)
	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	const generateHeader = doc => {
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)
		doc.setTextColor('#000000')

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`TRIFLEX ANNUITY - ${planType}`, 11, 2.5, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})

		return doc
	}

	const generateFooter = doc => {
		doc.setFontSize(8)
		doc.setTextColor('#969696')
		doc.setFont('Arial', 'normal')
		doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
		doc.text('Page 1 of 4', 20, 26, {
			align: 'right',
		})
	}

	/** REPORT HEADER */

	doc = generateHeader(doc)

	/** END REPORT HEADER */

	/** REPORT BODY */

	doc.setFontSize(8)
	doc.setTextColor('#000000')
	doc.text('Prepared For', 9.5, 3.5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(
		`${values.plan.type === '2' ? values.applicant_first_name : values.first_name} ${
			values.plan.type === '2' ? values.applicant_last_name : values.last_name
		}`,
		10.1,
		3.5,
	)

	doc.setFont('Arial', 'bold')
	doc.text('Agent Name', 9.5, 4.3, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(values.quote?.agent, 10.1, 4.3)

	doc.setFont('Arial', 'bold')
	doc.text('Annuitant', 9.5, 5.1, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5.1)

	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.89, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.sex === 'F' ? 'Female' : 'Male'}`, 10.1, 5.89)

	doc.setFont('Arial', 'bold')
	doc.text('Issue Age of Annuitant', 9.5, 6.7, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.7)

	doc.setFont('Arial', 'bold')
	doc.text('Chosen Retirement Age:', 12, 6.7)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.plan.retirement_age}`, 15.4, 6.7)

	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 7.56, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.standard_fprem)}`, 10.1, 7.56)

	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 12, 7.56)

	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 8.45, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 8.45)

	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency Premium', 9.5, 9.35, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(parseFloat(values.quote?.frequency_premium || 0))}`, 10.1, 9.35)

	doc.setFont('Arial', 'bold')
	doc.text('Additional Deposits', 9.5, 10.26, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.additional_deposit || 0)}`, 10.1, 10.26)

	// check pay type and add to form
	doc.setFont('Arial', 'bold')
	doc.text(
		`${
			values.plan?.pay_type === '2'
				? 'Annually, at the beginning of every policy year'
				: values.plan?.pay_type === '1'
				? `Annually, at the beginning of policy year(s) ${values.plan?.year_start} to ${values.plan?.year_end}`
				: ''
		}`,
		12,
		10.26,
	)

	doc.setFont('Arial', 'bold')
	doc.text('Maximum Tax Free Lump Sum', 9.5, 11.16, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text('25.00%', 10.1, 11.16)

	const coverageBody = []
	coverageBody.push([
		'Triflex Annuity Planned Premium',
		'',
		{
			content: formatCurrency(values.quote?.standard_annual_premium),
			styles: { halign: 'right' },
		},
	])

	if (!!values.quote?.ratings?.wp) {
		coverageBody.push([
			'Waiver of Premium',
			`Standard Rate ${getWpExtraRating(values.ratings.wp)}`,
			{
				content: formatCurrency(values.quote?.ratings?.wp?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.ratings?.rr) {
		coverageBody.push([
			`Retrenchment Rider\t Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,

			'Standard Rate',
			{
				content: `${formatCurrency(values.quote?.ratings?.rr?.aprem)}`,
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	doc.autoTable({
		startY: 12,
		head: [['Coverage', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
					},
				},
				'',
				{
					content: formatCurrency(parseFloat(values.quote.annual_premium)),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'Under this Plan Maritime Life shall invest a large part of your premiums in the proportions decided by you in one or more of three investments funds; the Government Bonds Funds, the Corporate Bonds and Mortgages Fund, and the Property and Equity Fund.',
		1.4,
		17.5,
		{
			maxWidth: 18.5,
		},
	)

	doc.setFont('Arial', 'bold')
	doc.text(
		'The figures contained herein are illustrative and are not guaranteed by the Company. They are only designed to give a GENERAL INDICATION of the kind of results that could be achieved. The figures illustrated are based on an assumed average net rate earned on the three funds. Actual results will depend on the performance of each investment fund and the premium allocations among the three funds.',
		1.4,
		18.4,
		{ maxWidth: 18.5 },
	)

	doc.setFont('Arial', 'normal')
	doc.text(
		'In light of the volatility of interst rate, and in order to demonstrate the impact of interest returns on the result, benefits are illustrated at three rates. The average of the 4.00%, 6.00% and 8.00% rates are on par with the current long term risk free rate.',
		1.4,
		19.6,
		{ maxWidth: 18.5 },
	)

	doc.text(
		'I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		20.5,
		{
			maxWidth: 18.5,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(15, 22, 19.8, 22)

	doc.text(`${values.first_name} ${values.last_name}`, 17.5, 22.3, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text('(INSURED)', 17.5, 22.6, {
		align: 'center',
	})

	doc.setFont('Arial', 'normal')
	doc.setFontSize(7.5)
	doc.text(
		'I Certify that this is an official illustration of Maritime Life (Caribbean) Limited and I have explained its contents to my client.',
		1.4,
		23.8,
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(15, 24.3, 19.8, 24.3)

	doc.text(values.quote?.agent, 17.5, 24.6, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text('(AGENT)', 17.5, 24.85, {
		align: 'center',
	})

	/** END REPORT BODY */

	/** REPORT FOOTER */
	generateFooter(doc)
	/** END PAGE FOOTER */

	doc.addPage('letter', 'p')

	/** PAGE HEADER */
	doc = generateHeader(doc)
	/** END PAGE HEADER */

	/** PAGE BODY */

	const rate_1_values = values.quote?.illustration_values?.rate_1?.map(
		({
			POLYEAR,
			RETAGE,
			PREMPAID,
			PREMFUND,
			ADDEPFUND,
			TOTALFUND,
			FULLANNUITY,
			MAXLUMPSUM,
			REDANNUITY,
		}) => {
			return [
				POLYEAR,
				RETAGE,
				formatCurrency(PREMPAID, 0),
				formatCurrency(PREMFUND, 0),
				formatCurrency(ADDEPFUND, 0),
				formatCurrency(TOTALFUND, 0),
				formatCurrency(FULLANNUITY, 0),
				formatCurrency(MAXLUMPSUM, 0),
				formatCurrency(REDANNUITY, 0),
			]
		},
	)

	const rate_1 = Number(values?.quote?.illustration_values?.rate_1[0]?.RATE) * 100.0

	doc.autoTable({
		startY: 3.5,
		headStyles: {
			fillColor: '#C0C0C0',
			textColor: '#000000',
			lineColor: '#000000',
			lineWidth: 0.01,
			cellPadding: 0.07,
		},
		bodyStyles: {
			lineWidth: 0.01,
			lineColor: '#000000',
			fillColor: '#ffffff',
			cellPadding: 0.07,
			textColor: '#000000',
			fontSize: 8,
		},
		head: [
			[
				{
					content: 'FUND ACCUMULATION PROJECTED RETIREMENT BENEFITS',
					colSpan: 9,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'End of Policy Year',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Attained Age',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Total Premiums Paid',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 2,
						fontSize: 7,
					},
				},
				{
					content: `Assuming an interest rate of ${rate_1.toFixed(2)}%`,
					colSpan: 6,
					styles: {
						halign: 'center',
						cellWidth: 2.29,
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'Fund Accumulation of Planned Premiums',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Fund Accumulation of Additional Deposits',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Total Fund Accumulation / Cash Surrender Value',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Projected Full Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Tax Free Lumpsum',
					styles: {
						fontSize: 7,
						halign: 'center',
						cellWidth: 2.29,
					},
				},
				{
					content: 'Reduced Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
			],
		],
		columnStyles: {
			0: { halign: 'center' },
			1: { halign: 'center' },
			2: { halign: 'center' },
			3: { halign: 'right' },
			4: { halign: 'right' },
			5: { halign: 'right' },
			6: { halign: 'right' },
			7: { halign: 'right' },
			8: { halign: 'right' },
		},
		body: [...rate_1_values],
	})

	doc.setFont('Arial', 'italic')
	doc.setTextColor('#000000')
	doc.text(
		`Triflex Annuity ${getAnnuityPlan(values.plan.type)
			.split(' ')
			.map(
				w => w[0].toUpperCase() + w.substring(1).toLowerCase(),
			)} is an Individual Deferred Annuity Plan Approved by the Board of Inland Revenue under Section 28 of the Income Tax Ordinance of Trinidad and Tobago. Currently premiums on approved Plan are a deductible allowance. An individual can claim a deduction of up to $50,000 per annum in respect of contributions to Approved Annuities, Registered Pension Plans and the National Insurance Pension Seheme. The Maximum Lump Sum shown is based on existing legislation which permits a tax free withdrawal at retirement of up to 25% of the Fund Accumulation. Approved annuities cannot be surrendered. If however the policy is de-approved and surrenedered, a surrender tax of 25% of the Fund Accumulation is deducted from the proceeds and remitted to the Board of Inland Revenue. The Cash Surrender Values illustrated above are before the deduction of the tax.`,
		1.4,
		17.8,
		{
			maxWidth: 18.5,
		},
	)

	doc.setFont('Arial', 'normal')
	doc.setFontSize(7.5)
	doc.text(
		'Under this Plan Maritime Life shall invest a large part of your premiums in the proportions decided by you in one or more of three investments funds; the Government Bonds Funds, the Corporate Bonds and Mortgages Fund, and the Property and Equity Fund.',
		1.4,
		20.5,
		{
			maxWidth: 18.7,
		},
	)

	doc.setFont('Arial', 'bold')

	doc.text(
		'The figures contained herein are illustrative and are not guaranteed by the Company. They are only designed to give a GENERAL INDICATION of the kind of results that could be achieved. The figures illustrated are based on an assumed average net rate earned on the three funds. Actual results will depend on the performance of each investment fund and the premium allocations among the three funds.',
		1.4,
		21.3,
		{ maxWidth: 18.7 },
	)

	doc.setFont('Arial', 'normal')
	doc.text(
		'In light of the volatility of interst rate, and in order to demonstrate the impact of interest returns on the result, benefits are illustrated at three rates. The average of the 4.00%, 6.00% and 8.00% rates are on par with the current long term risk free rate.',
		1.4,
		22.6,
		{ maxWidth: 18.5 },
	)

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(
		'I Certify that this is an official illustration of Maritime Life (Caribbean) Limited and I have explained its contents to my client.',
		1.4,
		24.2,
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(15, 24.7, 19.8, 24.7)

	doc.text(values.quote?.agent, 17.5, 25, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text('(AGENT)', 17.5, 25.3, {
		align: 'center',
	})

	/** END PAGE BODY */

	/** PAGE FOOTER */
	generateFooter(doc)
	/** END PAGE FOOTER */

	doc.addPage()

	/** PAGE HEADER */
	generateHeader(doc)
	/** END PAGE HEADER */

	/** PAGE BODY */
	const rate_2_values = values.quote?.illustration_values?.rate_2?.map(
		({
			POLYEAR,
			RETAGE,
			PREMPAID,
			PREMFUND,
			ADDEPFUND,
			TOTALFUND,
			FULLANNUITY,
			MAXLUMPSUM,
			REDANNUITY,
		}) => {
			return [
				POLYEAR,
				RETAGE,
				formatCurrency(PREMPAID, 0),
				formatCurrency(PREMFUND, 0),
				formatCurrency(ADDEPFUND, 0),
				formatCurrency(TOTALFUND, 0),
				formatCurrency(FULLANNUITY, 0),
				formatCurrency(MAXLUMPSUM, 0),
				formatCurrency(REDANNUITY, 0),
			]
		},
	)

	const rate_2 = Number(values?.quote?.illustration_values?.rate_2[0]?.RATE) * 100.0

	doc.autoTable({
		startY: 3.5,
		headStyles: {
			fillColor: '#C0C0C0',
			textColor: '#000000',
			lineColor: '#000000',
			lineWidth: 0.01,
			cellPadding: 0.07,
		},
		bodyStyles: {
			lineWidth: 0.01,
			lineColor: '#000000',
			fillColor: '#ffffff',
			cellPadding: 0.07,
			textColor: '#000000',
			fontSize: 8,
		},
		head: [
			[
				{
					content: 'FUND ACCUMULATION PROJECTED RETIREMENT BENEFITS',
					colSpan: 9,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'End of Policy Year',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Attained Age',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Total Premiums Paid',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 2,
						fontSize: 7,
					},
				},
				{
					content: `Assuming an interest rate of ${rate_2.toFixed(2)}%`,
					colSpan: 6,
					styles: {
						halign: 'center',
						cellWidth: 2.29,
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'Fund Accumulation of Planned Premiums',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Fund Accumulation of Additional Deposits',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Total Fund Accumulation / Cash Surrender Value',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Projected Full Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Tax Free Lumpsum',
					styles: {
						fontSize: 7,
						halign: 'center',
						cellWidth: 2.29,
					},
				},
				{
					content: 'Reduced Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
			],
		],
		columnStyles: {
			0: { halign: 'center' },
			1: { halign: 'center' },
			2: { halign: 'center' },
			3: { halign: 'right' },
			4: { halign: 'right' },
			5: { halign: 'right' },
			6: { halign: 'right' },
			7: { halign: 'right' },
			8: { halign: 'right' },
		},
		body: [...rate_2_values],
	})

	doc.setFont('Arial', 'italic')
	doc.setTextColor('#000000')
	doc.text(
		`Triflex Annuity ${getAnnuityPlan(values.plan.type)
			.split(' ')
			.map(
				w => w[0].toUpperCase() + w.substring(1).toLowerCase(),
			)} is an Individual Deferred Annuity Plan Approved by the Board of Inland Revenue under Section 28 of the Income Tax Ordinance of Trinidad and Tobago. Currently premiums on approved Plan are a deductible allowance. An individual can claim a deduction of up to $50,000 per annum in respect of contributions to Approved Annuities, Registered Pension Plans and the National Insurance Pension Seheme. The Maximum Lump Sum shown is based on existing legislation which permits a tax free withdrawal at retirement of up to 25% of the Fund Accumulation. Approved annuities cannot be surrendered. If however the policy is de-approved and surrenedered, a surrender tax of 25% of the Fund Accumulation is deducted from the proceeds and remitted to the Board of Inland Revenue. The Cash Surrender Values illustrated above are before the deduction of the tax.`,
		1.4,
		17.8,
		{
			maxWidth: 18.7,
		},
	)

	doc.setFont('Arial', 'normal')
	doc.setFontSize(7.5)
	doc.text(
		'Under this Plan Maritime Life shall invest a large part of your premiums in the proportions decided by you in one or more of three investments funds; the Government Bonds Funds, the Corporate Bonds and Mortgages Fund, and the Property and Equity Fund.',
		1.4,
		20.5,
		{
			maxWidth: 18.7,
		},
	)

	doc.setFont('Arial', 'bold')

	doc.text(
		'The figures contained herein are illustrative and are not guaranteed by the Company. They are only designed to give a GENERAL INDICATION of the kind of results that could be achieved. The figures illustrated are based on an assumed average net rate earned on the three funds. Actual results will depend on the performance of each investment fund and the premium allocations among the three funds.',
		1.4,
		21.3,
		{ maxWidth: 18.7 },
	)

	doc.setFont('Arial', 'normal')
	doc.text(
		'In light of the volatility of interst rate, and in order to demonstrate the impact of interest returns on the result, benefits are illustrated at three rates. The average of the 4.00%, 6.00% and 8.00% rates are on par with the current long term risk free rate.',
		1.4,
		22.6,
		{ maxWidth: 18.5 },
	)

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(
		'I Certify that this is an official illustration of Maritime Life (Caribbean) Limited and I have explained its contents to my client.',
		1.4,
		24.2,
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(15, 24.7, 19.8, 24.7)

	doc.text(values.quote?.agent, 17.5, 25, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text('(AGENT)', 17.5, 25.3, {
		align: 'center',
	})
	/** END PAGE BODY */

	/** PAGE FOOTER */
	generateFooter(doc)
	/** END PAGE FOOTER */

	doc.addPage()

	/** PAGE HEADER */
	doc = generateHeader(doc)
	/** END PAGE HEADER */

	/** PAGE BODY */
	const rate_3_values = values.quote?.illustration_values?.rate_3?.map(
		({
			POLYEAR,
			RETAGE,
			PREMPAID,
			PREMFUND,
			ADDEPFUND,
			TOTALFUND,
			FULLANNUITY,
			MAXLUMPSUM,
			REDANNUITY,
		}) => {
			return [
				POLYEAR,
				RETAGE,
				formatCurrency(PREMPAID, 0),
				formatCurrency(PREMFUND, 0),
				formatCurrency(ADDEPFUND, 0),
				formatCurrency(TOTALFUND, 0),
				formatCurrency(FULLANNUITY, 0),
				formatCurrency(MAXLUMPSUM, 0),
				formatCurrency(REDANNUITY, 0),
			]
		},
	)

	const rate_3 = Number(values?.quote?.illustration_values?.rate_3[0]?.RATE) * 100.0

	doc.autoTable({
		startY: 3.5,
		headStyles: {
			fillColor: '#C0C0C0',
			textColor: '#000000',
			lineColor: '#000000',
			lineWidth: 0.01,
			cellPadding: 0.07,
		},
		bodyStyles: {
			lineWidth: 0.01,
			lineColor: '#000000',
			fillColor: '#ffffff',
			cellPadding: 0.07,
			textColor: '#000000',
			fontSize: 8,
		},
		head: [
			[
				{
					content: 'FUND ACCUMULATION PROJECTED RETIREMENT BENEFITS',
					colSpan: 9,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'End of Policy Year',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Attained Age',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					content: 'Total Premiums Paid',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 2,
						fontSize: 7,
					},
				},
				{
					content: `Assuming an interest rate of ${rate_3.toFixed(2)}%`,
					colSpan: 6,
					styles: {
						halign: 'center',
						cellWidth: 2.29,
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					content: 'Fund Accumulation of Planned Premiums',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Fund Accumulation of Additional Deposits',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Total Fund Accumulation / Cash Surrender Value',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Projected Full Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Tax Free Lumpsum',
					styles: {
						fontSize: 7,
						halign: 'center',
						cellWidth: 2.29,
					},
				},
				{
					content: 'Reduced Monthly Annuity (with 10 year guarantee period)',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
			],
		],
		columnStyles: {
			0: { halign: 'center' },
			1: { halign: 'center' },
			2: { halign: 'center' },
			3: { halign: 'right' },
			4: { halign: 'right' },
			5: { halign: 'right' },
			6: { halign: 'right' },
			7: { halign: 'right' },
			8: { halign: 'right' },
		},
		body: [...rate_3_values],
	})

	doc.setFont('Arial', 'italic')
	doc.setTextColor('#000000')
	doc.text(
		'Triflex Annuity Gold is an Individual Deferred Annuity Plan Approved by the Board of Inland Revenue under Section 28 of the Income Tax Ordinance of Trinidad and Tobago. Currently premiums on approved Plan are a deductible allowance. An individual can claim a deduction of up to $50,000 per annum in respect of contributions to Approved Annuities, Registered Pension Plans and the National Insurance Pension Seheme. The Maximum Lump Sum shown is based on existing legislation which permits a tax free withdrawal at retirement of up to 25% of the Fund Accumulation. Approved annuities cannot be surrendered. If however the policy is de-approved and surrenedered, a surrender tax of 25% of the Fund Accumulation is deducted from the proceeds and remitted to the Board of Inland Revenue. The Cash Surrender Values illustrated above are before the deduction of the tax.',
		1.4,
		17.8,
		{
			maxWidth: 18.7,
		},
	)

	doc.setFont('Arial', 'normal')
	doc.setFontSize(7.5)
	doc.text(
		'Under this Plan Maritime Life shall invest a large part of your premiums in the proportions decided by you in one or more of three investments funds; the Government Bonds Funds, the Corporate Bonds and Mortgages Fund, and the Property and Equity Fund.',
		1.4,
		20.5,
		{
			maxWidth: 18.7,
		},
	)

	doc.setFont('Arial', 'bold')
	doc.text(
		'The figures contained herein are illustrative and are not guaranteed by the Company. They are only designed to give a GENERAL INDICATION of the kind of results that could be achieved. The figures illustrated are based on an assumed average net rate earned on the three funds. Actual results will depend on the performance of each investment fund and the premium allocations among the three funds.',
		1.4,
		21.3,
		{ maxWidth: 18.7 },
	)

	doc.setFont('Arial', 'normal')
	doc.text(
		'In light of the volatility of interst rate, and in order to demonstrate the impact of interest returns on the result, benefits are illustrated at three rates. The average of the 4.00%, 6.00% and 8.00% rates are on par with the current long term risk free rate.',
		1.4,
		22.6,
		{ maxWidth: 18.5 },
	)

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(
		'I Certify that this is an official illustration of Maritime Life (Caribbean) Limited and I have explained its contents to my client.',
		1.4,
		24.2,
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(15, 24.7, 19.8, 24.7)

	doc.text(values.quote?.agent, 17.5, 25, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text('(AGENT)', 17.5, 25.3, {
		align: 'center',
	})
	/** END PAGE BODY */

	/** PAGE FOOTER */
	generateFooter(doc)
	/** END PAGE FOOTER */

	return doc
}

export const generateClifePdf = values => {
	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const generateHeader = doc => {
		doc.setTextColor('#000000')
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`C-Life`, 11, 2.5, {
			align: 'center',
		})

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote?.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})
		return doc
	}

	doc.setLineWidth(0.01)

	doc = generateHeader(doc)

	doc.rect(1.41, 4, 18.77, 10.2)

	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.8)

	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.6)

	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 7.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 7.4)

	doc.line(1.38, 8.2, 20.2, 8.25)

	doc.setFont('Arial', 'bold')
	doc.text('Sum Assured', 9.5, 9, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.sum_assured, 0)}`, 10.1, 9)

	doc.setFont('Arial', 'bold')
	doc.text('Base Plan Extra Rating', 9.5, 9.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getExtraRating(values.quote?.ratings?.cl, true)} Sum Assured`, 10.1, 9.8)

	doc.line(1.38, 10.6, 20.2, 10.6)

	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 11.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 11.4)

	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 12.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote.standard_fprem)}`, 10.1, 12.2)

	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 13, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 13)

	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency Premium', 9.5, 13.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote.frequency_premium)}`, 10.1, 13.8)

	const coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			'C-Life',
			`Sum Assured: ${formatCurrency(values.plan.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.cl),
			{
				content: formatCurrency(values.quote?.ratings?.cl?.aprem),
				styles: { halign: 'right' },
			},
		],
	]

	if (!!values.benefits.lt.sum_assured) {
		coverageBody.push([
			`${getTermType(values.benefits.lt.type)} - ${getTermPlan(values.benefits.lt.term)}`,
			`Sum Assured:  ${formatCurrency(values.benefits.lt.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.lt),
			{
				content: formatCurrency(values.quote?.ratings?.lt?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.rr.applicant_age) {
		coverageBody.push([
			'Retrenchment Rider',
			`Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,
			'Standard Rate',
			{ content: formatCurrency(values.quote?.ratings?.rr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	doc.autoTable({
		startY: 14.2,
		head: [['Coverage', '', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setFontSize(8)
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)
	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 24.4, 12.8, 24.4)

	doc.text(`${values.first_name} ${values.last_name}`, 9.7, 24.7, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9.7, 25, {
		align: 'center',
	})

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 24.4, 19.8, 24.4)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 24.7, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 25, {
		align: 'center',
	})

	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 1 of 1', 1.4, 26.3)

	return doc
}

export const generateMppPdf = values => {
	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const planType = mppPlanOptions.find(o => o.value === values.plan.type).plan

	const generateHeader = doc => {
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)
		doc.setTextColor('#000000')

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`Mortgage Protection Plan ${planType} (Convertible)`, 11, 2.5, {
			align: 'center',
		})

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})

		return doc
	}

	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	doc.setLineWidth(0.01)

	doc = generateHeader(doc)

	doc.rect(1.38, 4, 18.8, 10.4)

	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.8)

	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.6)

	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 7.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 7.4)

	doc.line(1.38, 8.2, 20.2, 8.25)

	doc.setFont('Arial', 'bold')
	doc.text('Sum Assured', 9.5, 9, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.sum_assured, 0)}`, 10.1, 9)

	doc.setFont('Arial', 'bold')
	doc.text('Base Plan Extra Rating', 9.5, 9.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getExtraRating(values.quote?.ratings?.mpp, true)} of Basic Sum Assured`, 10.1, 9.8)

	doc.line(1.38, 10.6, 20.2, 10.6)

	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 11.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 11.4)

	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 12.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote.standard_fprem)}`, 10.1, 12.2)

	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 13, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 13)

	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency  Premium', 9.5, 13.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote.frequency_premium)}`, 10.1, 13.8)

	const coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			`${mppPlanOptions.find(m => m.value === values.plan.type).text}`,
			`Sum Assured: ${formatCurrency(values.plan.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.mpp),
			{ content: formatCurrency(values.quote?.ratings?.mpp?.aprem), styles: { halign: 'right' } },
		],
	]

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	doc.autoTable({
		startY: 14.4,
		head: [['Coverage', '', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)
	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 25.7, 12.8, 25.7)

	doc.text(`${values.first_name} ${values.last_name}`, 9, 26)

	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9, 26.3)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 1 of 1', 1.4, 26.3)

	doc.addPage()

	doc = generateHeader(doc)

	const illustrationValuesBody =
		values.quote?.illustration_values?.map(({ DURATION, ATTAINED_AGE, SUMASSURED }) => {
			return [
				{ content: DURATION, styles: { halign: 'center' } },
				{ content: ATTAINED_AGE, styles: { halign: 'center' } },
				{ content: formatCurrency(SUMASSURED, 0), styles: { halign: 'right' } },
			]
		}) || []

	doc.autoTable({
		startY: 4,
		head: [
			[
				{
					content: 'Duration',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},

				{
					content: 'Attained Age',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},

				{
					content: 'Sum Assured',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
			],
		],
		body: illustrationValuesBody,
		margin: { left: 7.5, right: 5 },

		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
			cellWidth: 2,
		},
	})

	doc.setTextColor('#000000')
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)

	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 25.7, 12.8, 25.7)

	doc.text(`${values.first_name} ${values.last_name}`, 9.8, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9.8, 26.3, {
		align: 'center',
	})

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 2 of 2', 1.4, 26.3)

	return doc
}

export const generateOlnpPdf = values => {
	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	const planType = values?.plan?.type === '1' ? 'Basic' : 'Enhanced'

	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const generateHeader = doc => {
		doc.setTextColor('#000000')
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`Ordinary Life Non Par - ${planType}`, 11, 2.5, {
			align: 'center',
		})

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote?.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})

		return doc
	}

	doc.setLineWidth(0.01)

	doc = generateHeader(doc)

	doc.rect(1.41, 4, 18.77, 10.2)

	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.8)

	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.6)

	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 7.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 7.4)

	doc.line(1.38, 8.2, 20.2, 8.25)

	doc.setFont('Arial', 'bold')
	doc.text('Sum Assured', 9.5, 9, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.sum_assured, 0)}`, 10.1, 9)

	doc.setFont('Arial', 'bold')
	doc.text('Base Plan Extra Rating', 9.5, 9.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getExtraRating(values.quote?.ratings?.ol, true)} of Basic Sum Assured`, 10.1, 9.8)

	doc.line(1.38, 10.6, 20.2, 10.6)

	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 11.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 11.4)

	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 12.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.standard_fprem)}`, 10.1, 12.2)

	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 13, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 13)

	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency  Premium', 9.5, 13.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote.frequency_premium)}`, 10.1, 13.8)

	const coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			'Ordinary Life Non Par',
			`Sum Assured: ${formatCurrency(values.plan.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.ol),
			{
				content: formatCurrency(values.quote?.ratings?.ol?.aprem),
				styles: { halign: 'right' },
			},
		],
	]

	if (!!values.benefits.ddr.rate_1) {
		const acceleration = `${values.benefits.ddr.rate_1}%${
			!!values.benefits.ddr.rate_2 ? ', ' + values.benefits.ddr.rate_2 + '%' : ''
		}${!!values.benefits.ddr.rate_3 ? ', ' + values.benefits.ddr.rate_3 + '%' : ''}${
			!!values.benefits.ddr.rate_4 ? ', ' + values.benefits.ddr.rate_4 + '%' : ''
		}`

		coverageBody.push([
			'OLNP Dread Disease',
			`Acceleration: ${acceleration}`,
			`Standard Rate ${getWpExtraRating(values.ratings.ddr)}`,
			{ content: formatCurrency(values.quote?.ratings?.ddr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.wdda.applicant_age) {
		coverageBody.push([
			`Waiver of Premium on Death or Disability - ${values.benefits.wdda.duration} years`,
			`Applicant Age: ${values.benefits.wdda.applicant_age}`,
			`Standard Rate ${getWpExtraRating(values.ratings.wdda)}`,
			{
				content: `${formatCurrency(values.quote?.ratings?.wdda?.aprem)}`,
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.ad.sum_assured) {
		coverageBody.push([
			'Accidental Death',
			`Sum Assured: ${formatCurrency(values.benefits.ad.sum_assured, 0)}`,
			`Standard Rate ${getWpExtraRating(values.ratings.ad)}`,
			{ content: formatCurrency(values.quote?.ratings?.ad?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.rr.applicant_age) {
		coverageBody.push([
			'Retrenchment Rider',
			`Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,
			'Standard Rate',
			{
				content: formatCurrency(values.quote?.ratings?.rr?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.air.units) {
		coverageBody.push([
			'Accidental Indemnity Rider',
			`Number of Units: ${values.benefits.air.units}`,
			`Class ${[
				getAirRatingText(values.ratings.air.occrate),
				getAirRatingText(values.ratings.air.medrate),
				getAirRatingText(values.ratings.air.avorate),
			].join(', ')}`,
			{
				content: formatCurrency(values.quote?.ratings?.air?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.wp) {
		coverageBody.push([
			'Waiver of Premium',
			'',
			`Standard Rate ${getWpExtraRating(values.ratings.wp)}`,
			{
				content: formatCurrency(values.quote?.ratings?.wp?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.lt.sum_assured) {
		coverageBody.push([
			`${getTermType(values.benefits.lt.type)} - ${getTermPlan(values.benefits.lt.term)}`,
			`Sum Assured:  ${formatCurrency(values.benefits.lt.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.lt),
			{
				content: formatCurrency(values.quote?.ratings?.lt?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.cl.sum_assured) {
		coverageBody.push([
			'C-Life',
			`Sum Assured: ${formatCurrency(values.benefits.cl.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.cl),
			{
				content: formatCurrency(values.quote?.ratings?.cl?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	doc.autoTable({
		startY: 14.2,
		head: [['Coverage', '', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setFontSize(8)
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)

	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 25.7, 12.8, 25.7)

	doc.text(`${values.first_name} ${values.last_name}`, 9.6, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9.6, 26.3, {
		align: 'center',
	})

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 1 of 2', 1.4, 26.3)

	//End of First Page

	doc.addPage()

	doc = generateHeader(doc)

	// Illustration Values Table

	let illustrationValues =
		values.quote?.illustration_values?.map(({ DURATION, ATTAINED_AGE, PREMS, CSV, PAIDUP }) => {
			return [
				{ content: DURATION, styles: { halign: 'center' } },
				{ content: ATTAINED_AGE, styles: { halign: 'center' } },
				{ content: formatCurrency(PREMS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(CSV, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(PAIDUP, 0), styles: { halign: 'right' } },
			]
		}) || []
	doc.autoTable({
		startY: 4,
		head: [
			[
				{ content: 'Duration', styles: { halign: 'center' } },
				{ content: 'Attained Age', styles: { halign: 'center' } },
				{ content: 'Premiums Paid', styles: { halign: 'right' } },
				{ content: 'Cash Surrender Value', styles: { halign: 'right' } },
				{ content: 'Paid Up Value', styles: { halign: 'right' } },
			],
		],
		body: illustrationValues,
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setTextColor('#000000')
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 2 of 2', 1.4, 26.3)

	return doc
}

function sortTriflexRating(a, b) {
	if (parseInt(a.ATTAGE) < parseInt(b.ATTAGE)) {
		return -1
	}

	if (parseInt(a.ATTAGE) > parseInt(b.ATTAGE)) {
		return 1
	}

	return 0
}

export const generateTriflexPdf = values => {
	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const generateHeader = doc => {
		doc.setTextColor('#000000')
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`Triflex`, 11, 2.5, {
			align: 'center',
		})

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote?.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})

		return doc
	}

	const generateFooter = (doc, isLongFormat) => {
		doc.setTextColor('#000000')
		doc.setFontSize(8)
		doc.setFont('Arial', 'normal')
		doc.text(
			'Under this plan, Maritime shall invest a large part of your premiums in the proprtions decided by you in one or more of three investment funds; the Maritime Life Mortgage Fund, the Maritime Life Bond Fund and the Maritime Life Property and Equity Fund.',
			1.4,
			22.8,
			{
				maxWidth: 18.8,
			},
		)

		doc.setFont('Arial', 'bold')
		doc.text(
			'The figures contained herein are illustrative and are only designed to give a GENERAL INDICATION of the kind of results that could be achieved. The figures illustrated are based on an average net rate earned on the three funds. The exact results will depend on the performance of each investment fund and the premium allocations among the three funds.',
			1.4,
			23.7,
			{
				maxWidth: 18.8,
			},
		)

		doc.setFont('Arial', 'normal', 'normal')
		doc.text(
			'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
			1.4,
			24.9,
			{
				maxWidth: 18.8,
			},
		)

		doc.setDrawColor('#000000')
		doc.setLineWidth(0.01)
		doc.line(13.8, 26.1, 19.8, 26.1)

		doc.text(`${values.quote?.agent}`, 16.8, 26.4, {
			align: 'center',
		})

		doc.setFont('Arial', 'bold')
		doc.text(`(SALES PERSON)`, 16.8, 26.7, {
			align: 'center',
		})

		doc.setFont('Arial', 'normal')
		doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26.3)
		doc.text(`Page ${doc.getNumberOfPages()} of ${!!isLongFormat ? '3' : '2'}`, 1.4, 26.6)

		return doc
	}

	doc.setLineWidth(0.01)

	doc = generateHeader(doc)

	doc.rect(1.41, 4, 18.77, 10.2)

	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.6)

	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.2)

	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 6.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 6.8)

	doc.line(1.38, 7.4, 20.2, 7.4)

	doc.setFont('Arial', 'bold')
	doc.text('Sum Assured', 9.5, 8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.sum_assured, 0)}`, 10.1, 8)

	doc.setFont('Arial', 'bold')
	doc.text('Base Plan Extra Rating', 9.5, 8.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getExtraRating(values.quote?.ratings?.tf, true)} of Basic Sum Assured`, 10.1, 8.6)

	doc.line(1.38, 9.4, 20.2, 9.4)

	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 10, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 10)

	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 10.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.standard_fprem)}`, 10.1, 10.6)

	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 11.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 11.2)

	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency Premium', 9.5, 11.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.frequency_premium)}`, 10.1, 11.8)

	doc.line(1.38, 12.4, 20.2, 12.4)

	if (!!values.plan.additional_deposit && parseInt(values.plan.additional_deposit) > 0) {
		doc.setFont('Arial', 'bold')
		doc.text('Additional Deposit', 6.5, 13, {
			align: 'right',
		})

		doc.setFont('Arial', 'normal')
		doc.text(
			`${formatCurrency(
				values.plan.additional_deposit,
			)} annually at the beginning of every policy year after year 4`,
			6.8,
			13,
		)
	}

	const coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			'Triflex',
			`Sum Assured: ${formatCurrency(values.plan.sum_assured, 0)}`,
			`${getExtraRating(values.quote?.ratings?.tf)}`,
			{
				content: formatCurrency(values.quote?.ratings?.tf?.aprem),
				styles: { halign: 'right' },
			},
		],
	]

	if (!!values.benefits.gdb) {
		coverageBody.push([
			'Guaranteed Death Benefit',
			'',
			'Standard Rate',
			{ content: formatCurrency(values.quote?.ratings?.gdb?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.ddr.rate_1) {
		const acceleration = `${values.benefits.ddr.rate_1}%${
			!!values.benefits.ddr.rate_2 ? ', ' + values.benefits.ddr.rate_2 + '%' : ''
		}${!!values.benefits.ddr.rate_3 ? ', ' + values.benefits.ddr.rate_3 + '%' : ''}${
			!!values.benefits.ddr.rate_4 ? ', ' + values.benefits.ddr.rate_4 + '%' : ''
		}`

		coverageBody.push([
			'Triflex Dread Disease',
			`Acceleration: ${acceleration}`,
			`Standard Rate ${getWpExtraRating(values.ratings.ddr)}`,
			{ content: formatCurrency(values.quote?.ratings?.ddr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.wdda.applicant_age) {
		coverageBody.push([
			`Waiver of Premium on Death or Disability - ${values.benefits.wdda.duration} years`,
			`Applicant Age: ${values.benefits.wdda.applicant_age}`,
			`Standard Rate ${getWpExtraRating(values.ratings.wdda)}`,
			{
				content: `${formatCurrency(values.quote?.ratings?.wdda?.aprem)}`,
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.air.units) {
		coverageBody.push([
			'Accidental Indemnity Rider',
			`Number of Units: ${values.benefits.air.units}`,
			`Class ${[
				getAirRatingText(values.ratings.air.occrate),
				getAirRatingText(values.ratings.air.medrate),
				getAirRatingText(values.ratings.air.avorate),
			].join(', ')}`,
			{
				content: formatCurrency(values.quote?.ratings?.air?.aprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.benefits.ad.sum_assured) {
		coverageBody.push([
			'Accidental Death ',
			`Sum Assured: ${formatCurrency(values.benefits.ad.sum_assured, 0)}`,
			`Standard Rate ${getWpExtraRating(values.ratings.ad)}`,
			{ content: formatCurrency(values.quote?.ratings?.ad?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.rr.applicant_age) {
		coverageBody.push([
			'Retrenchment',
			`Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,
			`Standard Rate`,
			{ content: formatCurrency(values.quote?.ratings?.rr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.wp) {
		coverageBody.push([
			'Waiver of Premium',
			``,
			`Standard Rate ${getWpExtraRating(values.ratings.wp)}`,
			{ content: formatCurrency(values.quote?.ratings?.wp?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.lt.sum_assured) {
		coverageBody.push([
			`${getTermType(values.benefits.lt.type)} - ${getTermPlan(values.benefits.lt.term)}`,
			`Sum Assured: ${formatCurrency(values.benefits.lt.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.lt),
			{ content: formatCurrency(values.quote?.ratings?.lt?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.cl.sum_assured) {
		coverageBody.push([
			'C-Life',
			`Sum Assured: ${formatCurrency(values.benefits.cl.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.cl),
			{ content: formatCurrency(values.quote?.ratings?.cl?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	doc.autoTable({
		startY: 13.4,
		head: [['Coverage', '', 'Extra Rating', { content: 'Premium', styles: { halign: 'right' } }]],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote?.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	doc.setFontSize(8)
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)
	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		22.9,
		{
			maxWidth: 18.8,
		},
	)

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 25.7, 12.8, 25.7)

	doc.text(`${values.first_name} ${values.last_name}`, 9.5, 26, { align: 'center' })

	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9.5, 26.3, { align: 'center' })

	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 17, 26, {
		align: 'center',
	})

	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 17, 26.3, {
		align: 'center',
	})

	doc.setFontSize(7)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text(`Page 1 of ${values.issue_age < 28 ? '3' : '2'}`, 1.4, 26.3)

	doc.addPage()

	doc = generateHeader(doc)

	const sortedRate1 = [...values.quote?.illustration_values?.rate_1]?.sort(sortTriflexRating)
	const rate_1_values = sortedRate1?.map(
		({ RATE, ATTAGE, POLYEAR, PREMS, DEATHBEN, DEATHBENRIDERS, POLVAL, CASHVAL }) => {
			return [
				POLYEAR,
				ATTAGE,
				{ content: formatCurrency(PREMS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBEN, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBENRIDERS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(POLVAL, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(CASHVAL, 0), styles: { halign: 'right' } },
			]
		},
	)
	const rate_1 = Number(values.quote?.illustration_values?.rate_1[0]?.RATE) * 100

	doc.autoTable({
		startY: values.issue_age < 28 ? 4.2 : 4,
		head: [
			[
				{
					content: '',
					colSpan: 2,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						cellWidth: 1,
					},
				},
				{
					content: `Based on a projected interest rate of ${rate_1}%`,
					colSpan: 5,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
					},
				},
			],
			[
				{
					content: 'Year',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Attained Age',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Basic Premiums Paid',
					styles: {
						halign: 'right',
						cellWidth: 2.5,
					},
				},
				{
					content: 'Triflex Death Benefit',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Death Benefit on Rider(s)',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Policy Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Cash Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
			],
		],
		body: rate_1_values,
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
			cellWidth: 1,
			halign: 'center',
		},
		headStyles: {
			fillColor: [255, 255, 255],
			textColor: 0,
			halign: 'center',
		},
	})

	const sortedRate2 = [...values.quote?.illustration_values?.rate_2]?.sort(sortTriflexRating)
	const rate_2_values = sortedRate2?.map(
		({ RATE, ATTAGE, POLYEAR, PREMS, DEATHBEN, DEATHBENRIDERS, POLVAL, CASHVAL }) => {
			return [
				POLYEAR,
				ATTAGE,
				{ content: formatCurrency(PREMS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBEN, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBENRIDERS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(POLVAL, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(CASHVAL, 0), styles: { halign: 'right' } },
			]
		},
	)
	const rate_2 = Number(values.quote?.illustration_values?.rate_2[0]?.RATE) * 100

	doc.autoTable({
		startY: values.issue_age < 28 ? doc.lastAutoTable.finalY + 1.2 : doc.lastAutoTable.finalY + 0.5,
		head: [
			[
				{
					content: '',
					colSpan: 2,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						cellWidth: 1,
					},
				},
				{
					content: `Based on a projected interest rate of ${rate_2}%`,
					colSpan: 5,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
					},
				},
			],
			[
				{
					content: 'Year',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Attained Age',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Basic Premiums Paid',
					styles: {
						halign: 'right',
						cellWidth: 2.5,
					},
				},
				{
					content: 'Triflex Death Benefit',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Death Benefit on Rider(s)',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Policy Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Cash Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
			],
		],
		body: rate_2_values,
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
			cellWidth: 1,
			halign: 'center',
		},
		headStyles: {
			fillColor: [255, 255, 255],
			textColor: 0,
			halign: 'center',
		},
	})

	//print footer for the second page
	if (values.issue_age < 28) {
		doc = generateFooter(doc, values.issue_age < 28)
	}

	const sortedRate3 = [...values.quote?.illustration_values?.rate_3]?.sort(sortTriflexRating)
	const rate_3_values = sortedRate3?.map(
		({ RATE, ATTAGE, POLYEAR, PREMS, DEATHBEN, DEATHBENRIDERS, POLVAL, CASHVAL }) => {
			return [
				POLYEAR,
				ATTAGE,
				{ content: formatCurrency(PREMS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBEN, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(DEATHBENRIDERS, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(POLVAL, 0), styles: { halign: 'right' } },
				{ content: formatCurrency(CASHVAL, 0), styles: { halign: 'right' } },
			]
		},
	)
	const rate_3 = Number(values.quote?.illustration_values?.rate_3[0]?.RATE) * 100

	// print last table on another page
	if (values.issue_age < 28) {
		doc.addPage()

		doc = generateHeader(doc)

		doc.autoTable({
			startY: 4.2,
			head: [
				[
					{
						content: '',
						colSpan: 2,
						styles: {
							halign: 'center',
							fontStyle: 'bold',
							cellWidth: 1,
						},
					},
					{
						content: `Based on a projected interest rate of ${rate_3}%`,
						colSpan: 5,
						styles: {
							halign: 'center',
							fontStyle: 'bold',
							fillColor: [199, 199, 199],
						},
					},
				],
				[
					{
						content: 'Year',
						styles: {
							halign: 'center',
							cellWidth: 2,
						},
					},
					{
						content: 'Attained Age',
						styles: {
							halign: 'center',
							cellWidth: 2,
						},
					},
					{
						content: 'Basic Premiums Paid',
						styles: {
							halign: 'right',
							cellWidth: 2.5,
						},
					},
					{
						content: 'Triflex Death Benefit',
						styles: {
							halign: 'right',
							cellWidth: 3,
						},
					},
					{
						content: 'Death Benefit on Rider(s)',
						styles: {
							halign: 'right',
							cellWidth: 3,
						},
					},
					{
						content: 'Policy Value',
						styles: {
							halign: 'right',
							cellWidth: 3,
						},
					},
					{
						content: 'Cash Value',
						styles: {
							halign: 'right',
							cellWidth: 3,
						},
					},
				],
			],
			body: rate_3_values,
			styles: {
				fontSize: 8,
				cellPadding: 0.1,
				textColor: 0,
				lineWidth: 0.01,
				lineColor: [0, 0, 0],
				cellWidth: 1,
				halign: 'center',
			},
			headStyles: {
				fillColor: [255, 255, 255],
				textColor: 0,
				halign: 'center',
			},
		})

		doc = generateFooter(doc, values.issue_age < 28)

		return doc
	}

	// print last table 0.5 units after the second table
	doc.autoTable({
		startY: doc.lastAutoTable.finalY + 0.5,
		head: [
			[
				{
					content: '',
					colSpan: 2,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						cellWidth: 1,
					},
				},
				{
					content: `Based on a projected interest rate of ${rate_3}%`,
					colSpan: 5,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
					},
				},
			],
			[
				{
					content: 'Year',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Attained Age',
					styles: {
						halign: 'center',
						cellWidth: 2,
					},
				},
				{
					content: 'Basic Premiums Paid',
					styles: {
						halign: 'right',
						cellWidth: 2.5,
					},
				},
				{
					content: 'Triflex Death Benefit',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Death Benefit on Rider(s)',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Policy Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
				{
					content: 'Cash Value',
					styles: {
						halign: 'right',
						cellWidth: 3,
					},
				},
			],
		],
		body: rate_3_values,
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
			cellWidth: 1,
			halign: 'center',
		},
		headStyles: {
			fillColor: [255, 255, 255],
			textColor: 0,
			halign: 'center',
		},
	})

	doc = generateFooter(doc, values.issue_age < 28)

	return doc
}

export const generateAdmedPdf = values => {
	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	const standard_extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const generateHeader = doc => {
		doc.setTextColor('#000000')
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(`ADmed`, 11, 2.5, {
			align: 'center',
		})

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote?.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})
		return doc
	}

	doc.setLineWidth(0.01)

	doc = generateHeader(doc)

	// Create Rect to hold information
	doc.rect(1.38, 4, 18.8, 13.35)

	// Insured
	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	//Sex
	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.8)

	//Issue Age
	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.6)

	//Smoker
	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 7.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 7.4)

	//line
	doc.line(1.38, 8.2, 20.2, 8.25) //horizontal line

	//Death Benefit
	doc.setFont('Arial', 'bold')
	doc.text('Death Benefit', 9.5, 9, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.death_benefit, 0)}`, 10.1, 9)

	//Dread Disease Coverage
	doc.setFont('Arial', 'bold')
	doc.text('Dread Disease Coverage', 9.5, 9.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.dread_disease, 0)}`, 10.1, 9.8)

	//line
	doc.line(1.38, 10.6, 20.2, 10.6) //horizontal line

	//Frequency
	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 11.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 11.4)

	//Standard Frequency Premium
	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 12.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.standard_fprem)}`, 10.1, 12.2)

	//Extra Rating Premium
	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 13, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(standard_extra_rating_premium)}`, 10.1, 13)

	//Total Frequency Premium
	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency Premium (Initial)', 9.5, 13.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.frequency_premium)}`, 10.1, 13.8)

	let coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			'ADmed Death Benefit',
			`Death Benefit: ${formatCurrency(values.plan.death_benefit, 0)}`,
			`${getAdmedDeathBenefitExtraRating(values.quote)}`,
			{
				content: formatCurrency(values.quote?.ratings?.adm?.death?.aprem),
				styles: { halign: 'right' },
			},
		],
		[
			'ADmed Dread Disease',
			`Coverage: ${formatCurrency(values.plan.dread_disease, 0)}`,
			`Standard Rate ${getWpExtraRating(values?.ratings?.adm?.dread)}`,
			{
				content: formatCurrency(values.quote?.ratings?.adm?.dread?.aprem),
				styles: { halign: 'right' },
			},
		],
		[
			'ADmed Surgical Benefit',
			`Package: ${getSurgicalPackageText(values.plan.surgical_package)}`,
			`Standard Rate ${getWpExtraRating(values?.ratings?.adm?.surgical)}`,
			{
				content: formatCurrency(values.quote?.ratings?.adm?.surgical?.aprem),
				styles: { halign: 'right' },
			},
		],
		[
			'ADmed Hospital Diagnostics Benefit',
			`Deductible: ${formatCurrency(values.plan.accident_emergency)} | Limit - ${formatCurrency(
				values.quote?.illustration_values?.benefits?.LIMITAE,
				0,
			)}`,
			'Standard Rate',
			{
				content: formatCurrency(values.quote?.ratings?.adm?.accident?.aprem),
				styles: { halign: 'right' },
			},
		],
		[
			'ADmed Accident and Emergency Services Benefit',
			`Deductible: ${formatCurrency(values.plan.accident_emergency)} | Limit - ${formatCurrency(
				values.quote?.illustration_values?.benefits?.LIMITAE,
				0,
			)}`,
			`Standard Rate ${getWpExtraRating(values?.ratings?.adm?.accident)}`,
			{
				content: formatCurrency(values.quote?.ratings?.adm?.max_benefit?.aprem),
				styles: { halign: 'right' },
			},
		],
	]

	if (!!values.benefits.rr.applicant_age) {
		coverageBody.push([
			'Retrenchment Rider',
			`Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,
			'Standard Rate',
			{ content: formatCurrency(values.quote?.ratings?.rr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	// Coverage table
	doc.autoTable({
		startY: 14.2,
		head: [['Coverage', '', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote?.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	//Limitations paragraph
	doc.setFontSize(8)
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)
	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	//Signing area

	//insured signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 25.7, 12.8, 25.7)

	//insured name
	doc.text(`${values.first_name} ${values.last_name}`, 9, 26)

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9, 26.3)

	//sales person signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	//salesperson name
	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	// Report Footer
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 1 of 3', 1.4, 26.3)

	//End of First Page

	/**
	 * This is the start of the second page
	 * This page contains the AdMed Illustration Table
	 */
	//Add new page
	doc.addPage()

	/**
	 * Report header
	 */
	doc = generateHeader(doc)

	/**
	 * Report Body
	 */

	const premiumsBody =
		values.quote?.illustration_values?.coverage?.map(
			({ ATTAINED_AGE, PREM1, PREM2, FROMYR, TOYR, DESCRIPTION }) => {
				return [
					{ content: ATTAINED_AGE, styles: { halign: 'center' } },
					{ content: formatCurrency(PREM1), styles: { halign: 'center' } },
					{ content: formatCurrency(PREM2), styles: { halign: 'center' } },
					{ content: FROMYR, styles: { halign: 'center' } },
					{ content: TOYR, styles: { halign: 'center' } },
					{ content: DESCRIPTION, styles: { halign: 'center' } },
				]
			},
		) || []

	// Coverage table
	doc.autoTable({
		startY: 4.0,
		head: [
			[
				{
					// first heading cell
					content: 'AdMed coverage ceases at age 70',
					colSpan: 5,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
					},
				},
			],
			[
				{
					// second heading cell
					content:
						'The annual premium payable will change as shown below  due to the Change in Surgical, Hospital Diagnostics and Accident and Emergency Services Premium',
					colSpan: 5,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
					},
				},
			],
			[
				{
					// first header cell
					content: 'Attained Age of Insured',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					/**
					 * these two (second and third cells)
					 * are for the upper of the "twin" header cells
					 */
					content: 'No Claim',
					styles: {
						halign: 'center',
						cellWidth: 5,
						fontStyle: 'bold',
					},
				},
				{
					content: 'Maximum Claim',
					styles: {
						halign: 'center',
						cellWidth: 5,
						fontStyle: 'bold',
					},
				},
				{
					// fourth header cell
					content: 'Payable from the beginning of ... ',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
				{
					// fifth header cell
					content: '... to the end of ...',
					rowSpan: 2,
					styles: {
						halign: 'center',
						cellWidth: 1.4,
						fontSize: 7,
					},
				},
			],
			[
				{
					/**
					 * These two are the lower of the "twin" cells
					 */
					content: 'Adjusted Annual Premium',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
				{
					content: 'Adjusted Annual Premium',
					styles: {
						cellWidth: 2.29,
						fontSize: 7,
						halign: 'center',
					},
				},
			],
		],
		body: premiumsBody,
		bodyStyles: {
			0: { halign: 'center' },
			1: { halign: 'center' },
			2: { halign: 'center' },
			3: { halign: 'center' },
			4: { halign: 'center' },
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
			cellWidth: 3,
		},
		headStyles: {
			fillColor: [255, 255, 255],
			textColor: 0,
			halign: 'center',
		},
	}) // end of table

	// Limitations paragraph
	doc.setTextColor('#000000')
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)

	//Signing area

	//sales person signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	//salesperson name
	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	// Report Footer
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 2 of 3', 1.4, 26.3) // end of page

	//End of Second Page

	/**
	 * This is the start of the third page
	 * This page has contains the AdMed Breakdown
	 */

	// add new page
	doc.addPage()

	/**
	 * Report header
	 */
	doc = generateHeader(doc)

	/**
	 * Report Body
	 */

	const admedBenefits = values.quote?.illustration_values?.benefits

	doc.autoTable({
		startY: 4,
		head: [
			[
				{
					// first heading cell
					content:
						'AdMed comprises five specific benefits designed to address major medical needs and is not intended to replace or act as a substitute for basic health care plans.',
					colSpan: 2,
					styles: {
						halign: 'center',
						fontStyle: 'bold',
					},
				},
			],
		],
		body: [
			[
				/**
				 * First row of body.
				 */
				{
					content: `
    Surgical Benefits: 

    Category A - ${formatCurrency(admedBenefits?.LIMITCATA)}
    Category B - ${formatCurrency(admedBenefits?.LIMITCATB)}
    Category C - ${formatCurrency(admedBenefits?.LIMITCATC)}
    Category D - ${formatCurrency(admedBenefits?.LIMITCATD)}
    Category E - ${formatCurrency(admedBenefits?.LIMITCATE)}

    Maximum over every 
    successive 5 year

    ${formatCurrency(admedBenefits?.LIMIT5YEAR)}
            `,
					styles: { halign: 'center', cellWidth: 5, fontStyle: 'bold' },
				},
				{
					content: `Payable whenever the Insured undergoes a surgical operation that is performed at a hospital facility, which is medically necessary and appropriate, invasive and requires a surgical incision. The specific amount payable depend on the type of surgery and its assigned category. This is paid regardless of whether the client pays for the procedure or not.`,
					styles: { valign: 'middle', halign: 'left', cellWidth: 13.6 },
				},
			],
			[
				{
					content: `\nHospital Diagnostics\nBenefit\nMaximum per Policy\nYear: ${formatCurrency(
						admedBenefits?.LIMITHD,
					)}\nDeductible: ${formatCurrency(admedBenefits?.DEDUCTIBLE)} per claim
                 `,
					styles: { halign: 'center', cellWidth: 5, fontStyle: 'bold' },
				},
				{
					content: `Payable if the Life Insured incurs expenses for room and board, diagnostic lab tests,\ndiagnostics X-rays, machine testing or hospital ancillary services while at a hospital for at least one overnight stay, in respect of diagnostic services as a result of accident, emergency or for sickness on a doctor's recommendation. This is a reimbursement type benefit and requires original bills. Prior Approval is required for this benefit.`,
					styles: { valign: 'middle', halign: 'left', cellWidth: 13.6 },
				},
			],
			[
				{
					content: `\nAccident and\nEmergency Services\n\nMaximum per Policy\nYear: ${formatCurrency(
						admedBenefits?.LIMITAE,
					)}\n\nDeductible: ${formatCurrency(admedBenefits?.DEDUCTIBLE)} per claim
                 `,
					styles: { halign: 'center', cellWidth: 5, fontStyle: 'bold' },
				},
				{
					content: `Payable if the Life Insured incurs expenses at a hospital in respect of medical or ambulance services required as a result of accident or emergency. This is a reimbursement type benefit\nand requires original bills.`,
					styles: { valign: 'middle', halign: 'left', cellWidth: 13.6 },
				},
			],
			[
				{
					content: `\nDread Disease\nAcceleration Benefit\n\nMaximum $2,000,000\nPer Life Insured
                 `,
					styles: { halign: 'center', cellWidth: 5, fontStyle: 'bold' },
				},
				{
					content: `Is an advance payment of the Death Benefit and is payable on diagnosis of either a Heart Attack, Stroke, Cancer, Coronary Artery Disease Requiring Surgery, Renal Failure, Paralysis, Major Organ Transplant, Heart Valve Replacement, Multiple Sclerosis, Terminal Illness, Major Burns, Coma and Total and Permanent Disability. The Dread Disease benefit is payable only once. Dread disease coverage ceases once a dread disease claim is paid. The portion of the premium in respect of the Dread Disease benefit also ceases after a dread disease claim.`,
					styles: { valign: 'middle', halign: 'left', cellWidth: 13.6 },
				},
			],
			[
				{
					content: `\nDeath Benefit\n`,
					styles: { halign: 'center', cellWidth: 5, fontStyle: 'bold' },
				},
				{
					content: `Payable to the named Beneficiary on the death of the Life Insured less any payments on the Dread Disease Acceleration Benefit.`,
					styles: { valign: 'middle', halign: 'left', cellWidth: 13.6 },
				},
			],
		],

		styles: {
			fontSize: 9,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
			cellWidth: 3,
		},
		headStyles: {
			fillColor: [255, 255, 255],
			textColor: 0,
			halign: 'center',
		},
	}) // end of table

	doc.setTextColor('#000000')
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)

	//Signing area

	//sales person signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 25.7, 19.8, 25.7)

	//salesperson name
	doc.text(`${values.quote?.agent}`, 16.8, 26, {
		align: 'center',
	})

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 26.3, {
		align: 'center',
	})

	// Report Footer
	doc.setFontSize(8)
	doc.setFont('Arial', 'normal')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 3 of 3', 1.4, 26.3)

	return doc
}

export const generateTermPdf = values => {
	let doc = new jsPDF({
		orientation: 'p',
		unit: 'cm',
		format: 'letter',
		putOnlyUsedFonts: true,
		compress: true,
	})

	doc.setFont('arial')

	const extra_rating_premium = getStandardExtraRating(
		values.quote?.occfprem,
		values.quote?.medfprem,
		values.quote?.avofprem,
	)

	const generateHeader = doc => {
		doc.setTextColor('#000000')
		doc.addImage(reportLogo, 1.3, 1.6, 4.3, 1.423)
		doc.setFont('Arial', 'bold')
		doc.setFontSize(10)

		doc.text('MARITIME LIFE (CARIBBEAN) LIMITED', 11, 2, {
			align: 'center',
		})

		doc.text(
			`${getTermType(values.plan.type)} - ${getTermPlan(values.plan.term)} (Convertible)`,
			11,
			2.5,
			{
				align: 'center',
			},
		)

		doc.text(`Illustration`, 11, 3, {
			align: 'center',
		})

		doc.setFontSize(8)
		doc.text(values.quote?.qid, 20, 2, {
			align: 'right',
		})

		doc.setTextColor('#969696')
		doc.text('Only Valid if Certified by Agent', 20, 2.5, {
			align: 'right',
		})

		return doc
	}

	doc.setLineWidth(0.01)

	/**
	 * Report header
	 */
	doc = generateHeader(doc)

	/**
	 * Report Body
	 */

	// Create Rect to hold information
	doc.rect(1.41, 4, 18.77, 10.2)

	// Insured
	doc.setTextColor('#000000')
	doc.text('Insured', 9.5, 5, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.first_name} ${values.last_name}`, 10.1, 5)

	//Sex
	doc.setFont('Arial', 'bold')
	doc.text('Sex', 9.5, 5.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getGender(values.sex)}`, 10.1, 5.8)

	//Issue Age
	doc.setFont('Arial', 'bold')
	doc.text('Issue Age', 9.5, 6.6, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${values.issue_age}`, 10.1, 6.6)

	//Smoker
	doc.setFont('Arial', 'bold')
	doc.text('Smoker', 9.5, 7.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getSmokerText(values.smoker)}`, 10.1, 7.4)

	//line
	doc.line(1.38, 8.2, 20.2, 8.25) //horizontal line

	//Sum Assured
	doc.setFont('Arial', 'bold')
	doc.text('Sum Assured', 9.5, 9, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.plan.sum_assured, 0)}`, 10.1, 9)

	//Base Plan Extra Rating
	doc.setFont('Arial', 'bold')
	doc.text('Base Plan Extra Rating', 9.5, 9.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getExtraRating(values?.quote?.ratings?.lt, true)} of Sum Assured`, 10.1, 9.8)

	//line
	doc.line(1.38, 10.6, 20.2, 10.6) //horizontal line

	//Frequency
	doc.setFont('Arial', 'bold')
	doc.text('Frequency', 9.5, 11.4, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${getPaymentFrequencyText(values.payment_frequency)}`, 10.1, 11.4)

	//Standard Frequency Premium
	doc.setFont('Arial', 'bold')
	doc.text('Standard Frequency Premium', 9.5, 12.2, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.standard_fprem)}`, 10.1, 12.2)

	//Extra Rating Premium
	doc.setFont('Arial', 'bold')
	doc.text('Extra Rating Premium', 9.5, 13, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(extra_rating_premium)}`, 10.1, 13)

	//Total Frequency  Premium
	doc.setFont('Arial', 'bold')
	doc.text('Total Frequency  Premium', 9.5, 13.8, {
		align: 'right',
	})

	doc.setFont('Arial', 'normal')
	doc.text(`${formatCurrency(values.quote?.frequency_premium || 0)}`, 10.1, 13.8)

	let coverageBody = [
		[
			'Policy Factor',
			'',
			'',
			{ content: formatCurrency(values.quote?.polfee), styles: { halign: 'right' } },
		],
		[
			`${getTermPlan(values.plan.term)} - ${getTermType(values.plan.type)}`,
			`Sum Assured: ${formatCurrency(values.plan.sum_assured, 0)}`,
			getExtraRating(values?.quote?.ratings?.lt),
			{
				content: formatCurrency(values.quote?.ratings?.lt?.aprem),
				styles: { halign: 'right' },
			},
		],
	]

	if (!!values.benefits.ad.sum_assured) {
		coverageBody.push([
			'Accidental Death',
			`Sum Assured: ${formatCurrency(values.benefits.ad.sum_assured, 0)}`,
			`Standard Rate ${getWpExtraRating(values.ratings.ad)}`,
			{ content: formatCurrency(values.quote?.ratings?.ad?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.rr.applicant_age) {
		coverageBody.push([
			'Retrenchment Rider',
			`Total Annual Premium Covered: ${getAnnualPremiumCovered(
				values.benefits.rr.additional_premium,
				values.quote?.frequency_premium,
				values.payment_frequency,
			)}`,
			'Standard Rate',
			{ content: formatCurrency(values.quote?.ratings?.rr?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.wp) {
		coverageBody.push([
			'Waiver of Premium',
			'',
			`Standard Rate ${getWpExtraRating(values.ratings.wp)}`,
			{ content: formatCurrency(values.quote?.ratings?.wp?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.benefits.cl.sum_assured) {
		coverageBody.push([
			'C-Life',
			`Sum Assured: ${formatCurrency(values.benefits.cl.sum_assured, 0)}`,
			getExtraRating(values.quote?.ratings?.cl),
			{ content: formatCurrency(values.quote?.ratings?.cl?.aprem), styles: { halign: 'right' } },
		])
	}

	if (!!values.quote?.occaprem && Number(values.quote?.occaprem) > 0) {
		coverageBody.push([
			'Occupational Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.occaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.medaprem && Number(values.quote?.medaprem) > 0) {
		coverageBody.push([
			'Medical Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.medaprem),
				styles: { halign: 'right' },
			},
		])
	}

	if (!!values.quote?.avoaprem && Number(values.quote?.avoaprem) > 0) {
		coverageBody.push([
			'Avocation Extra Premium',
			'',
			'',
			{
				content: formatCurrency(values.quote?.avoaprem),
				styles: { halign: 'right' },
			},
		])
	}

	// Coverage table
	doc.autoTable({
		startY: 14.2,
		head: [['Coverage', '', 'Extra Rating', 'Premium']],
		body: coverageBody,
		foot: [
			[
				{
					content: 'Total Annual Premium',
					styles: { fontStyle: 'bold', fillColor: [199, 199, 199] },
				},
				'',
				'',
				{
					content: formatCurrency(values.quote?.annual_premium),
					styles: {
						fontStyle: 'bold',
						fillColor: [199, 199, 199],
						halign: 'right',
					},
				},
			],
		],
		footStyles: {
			fillColor: [199, 199, 199],
			lineWidth: 0,
		},
		styles: {
			fontSize: 8,
			cellPadding: 0.1,
			textColor: 0,
			lineWidth: 0.01,
			lineColor: [0, 0, 0],
		},
		headStyles: {
			fillColor: [199, 199, 199],
			textColor: 0,
		},
	})

	//Limitations paragraph
	doc.setFontSize(8)
	doc.text(
		'This document is an illustration designed to give a clear indication of the benefits of this plan. These benefits are subject to the exclusions and limitations as cited in the policy contract. This is neither an actual policy contract nor evidence of one.',
		1.4,
		22,
		{
			maxWidth: 18.8,
		},
	)
	doc.text(
		'Notwithstanding any wording to the contrary, I agree that the above information shall form part of my Application for insurance to Maritime Life (Caribbean) Limited as evidenced by my signature affixed to this page.',
		1.4,
		23,
		{
			maxWidth: 18.8,
		},
	)

	//Signing area

	//insured signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(6.8, 24.4, 12.8, 24.4)

	//insured name
	doc.text(`${values.first_name} ${values.last_name}`, 9.7, 24.7, {
		align: 'center',
	})

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(INSURED)`, 9.7, 25, {
		align: 'center',
	})

	//sales person signing area
	doc.setDrawColor('#000000')
	doc.setLineWidth(0.01)
	doc.line(13.8, 24.4, 19.8, 24.4)

	//salesperson name
	doc.setFont('Arial', 'normal')
	doc.text(`${values.quote?.agent}`, 16.8, 24.7, {
		align: 'center',
	})

	//title
	doc.setFont('Arial', 'bold')
	doc.text(`(SALES PERSON)`, 16.8, 25, {
		align: 'center',
	})

	// Report Footer
	doc.setTextColor('#000000')
	doc.text(`Date Prepared ${dayjs().format('MMM-DD-YYYY')}`, 1.4, 26)
	doc.text('Page 1 of 1', 1.4, 26.3)

	return doc
}
