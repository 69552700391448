import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { omit } from 'lodash'
import { merge } from 'lodash'
import { crmStateSaved } from '../../utils/actions'
import { logout } from '../auth/authSlice'

const initialState = {
	issue_age: 0,
	agent: '',
	client: '',
	date: '',
	first_name: '',
	middle_initial: '',
	last_name: '',
	date_of_birth: '',
	sex: '',
	smoker: '',
	applicant_first_name: '',
	applicant_last_name: '',
	applicant_date_of_birth: '',
	applicant_client: '',
	applicant_sex: '',
	applicant_age: '',
	payment_frequency: '',
	application_date: dayjs().format('YYYY-MM-DD'),
}

const personalSlice = createSlice({
	name: 'personal',
	initialState,
	reducers: {
		personalFormUpdated: (state, action) => {
			return merge(state, action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(crmStateSaved, (state, action) => {
				let payload = omit(action.payload, ['appkey'])
				return merge(state, payload)
			})
			.addCase(logout, state => initialState)
	},
})

export const { personalFormUpdated } = personalSlice.actions

export const selectPersonal = state => state.personal

export default personalSlice.reducer
