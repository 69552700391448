import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { Label } from 'semantic-ui-react'

const FieldError = ({ errors, name }) => {
	return (
		<ErrorMessage
			errors={errors}
			name={name}
			render={({ message }) => (
				<Label prompt pointing>
					{message}
				</Label>
			)}
		/>
	)
}

export default FieldError
