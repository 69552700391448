import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'

const Protected = ({ component: Component }) => {
	let location = useLocation()

	let { token } = useSelector(state => state.auth)

	if (!!!token) {
		return <Navigate to={`/login/${location.search}`} state={{ from: location }} replace />
	}

	return <Component />
}

export default Protected
