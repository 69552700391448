import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import Benefit from './'
import WaiverPremiumExtraRatings from './WaiverPremiumExtraRatings'
import { useEffect } from 'react'

export const WaiverPremium = () => {
	const { control, errors, setValue } = useFormContext()

	useEffect(() => {
		let initial = false

		if (!initial) {
			setValue('benefits.wp', true, { shouldDirty: true })
		}

		return () => (initial = true)
	}, [setValue])

	return (
		<Benefit header='Waiver Premium'>
			<Controller
				control={control}
				name='benefits.wp'
				render={({ value, onChange, onBlur }) => (
					<Form.Checkbox
						checked={true}
						onBlur={onBlur}
						error={!!errors?.benefits?.wp}
						label='This rider allows policy premiums to be waived in the event of total and permanent disability of the life insured'
					/>
				)}
			/>
			<WaiverPremiumExtraRatings />
		</Benefit>
	)
}

export default WaiverPremium
