import React from 'react'
import { nanoid } from '@reduxjs/toolkit'
import ProductCard from './ProductCard'
import { Grid } from 'semantic-ui-react'
import OlnpIcon from '../../assets/olnp-icon-48px.svg'
import AdmedIcon from '../../assets/admed-icon-48px.svg'
import AnnuityIcon from '../../assets/annuity-icon-48px.svg'
import ClifeIcon from '../../assets/clife-icon-48px.svg'
import MppIcon from '../../assets/mpp-icon-48px.svg'
import TriflexIcon from '../../assets/triflex-icon-48px.svg'
import TermIcon from '../../assets/life-icon-48px.svg'

export const products = [
	{
		id: nanoid(),
		title: 'Triflex Annuity',
		path: '/product/triflex-annuity',
		ages: '18 - 60 years',
		product: 'triflex-annuity',
		validate: age => {
			return Number(age) >= 18 && Number(age) <= 60
		},
		description:
			'Flexible and customizable payments throughout the term of the policy based on what stage of life you are in and your investment preference.',
		icon: (
			<>
				<img src={AnnuityIcon} alt='annuity icon' width='48' height='48' />
			</>
		),
	},
	{
		id: nanoid(),
		title: 'Level Term',
		path: '/product/term',
		ages: '20 - 70 years',
		product: 'term',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 20 && Number(age) <= 70
		},
		description:
			'Temporary life insurance designed to provide coverage for death up to specified ages (e.g. to age 60, 70 or 80).',
		icon: (
			<>
				<img src={TermIcon} alt='term icon' width='48' height='48' />{' '}
			</>
		),
	},
	// {
	// 	id: nanoid(),
	// 	title: 'Accidental Death',
	// 	path: '/product/accidental',
	// 	ages: '18 - 55 years',
	// 	validate: age => {
	// 		if (!age || String(age).trim() === '') return false
	// 		return Number(age) >= 18 && Number(age) <= 55
	// 	},
	// 	description: 'This plan provides coverage in the event of premature death resulting from an accident.',
	// 	icon: <div style={{ height: '48px' }}></div>,
	// },
	{
		id: nanoid(),
		title: 'C-Life',
		path: '/product/clife',
		ages: '20 - 65 years',
		product: 'clife',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 20 && Number(age) <= 65
		},
		description:
			'Critical Illness Insurance is a term life insurance plan with the added benefit of critical illness coverage.',
		icon: (
			<>
				{' '}
				<img src={ClifeIcon} alt='clife icon' width='48' height='48' />
			</>
		),
	},
	{
		id: nanoid(),
		title: 'Triflex',
		path: '/product/triflex',
		ages: '1 - 55 years',
		product: 'triflex',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 0 && Number(age) <= 55
		},
		description:
			'Life Insurance with Savings is an investment-linked plan that combines accumulated investment returns with term life insurance coverage up to age sixty-five (65).',
		icon: (
			<>
				<img src={TriflexIcon} alt='triflex icon' width='48' height='48' />
			</>
		),
	},
	{
		id: nanoid(),
		title: 'Admed',
		path: '/product/admed',
		ages: '20 - 60 years',
		product: 'admed',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 20 && Number(age) <= 60
		},
		description:
			'Finances unexpected major medical bills at an affordable price by providing a comprehensive package of benefits.',
		icon: (
			<>
				{' '}
				<img src={AdmedIcon} alt='admed icon' width='48' height='48' />
			</>
		),
	},
	{
		id: nanoid(),
		title: 'Olnp',
		path: '/product/olnp',
		ages: '0 - 70 years',
		product: 'olnp',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 0 && Number(age) <= 70
		},
		description:
			'Life insurance that accumulates a cash value that can be accessed during your lifetime and transferred upon the insured’s passing.',
		icon: (
			<>
				<img src={OlnpIcon} alt='olnp icon' width='48' height='48' />
			</>
		),
	},
	{
		id: nanoid(),
		title: 'MPP',
		value: 'mpp',
		path: '/product/mpp',
		ages: '18 - 55 years',
		product: 'mpp',
		validate: age => {
			if (String(age).trim() === '') return false
			return Number(age) >= 18 && Number(age) <= 55
		},
		description:
			'This plan provides coverage for death during the specified term of the policy; however, the amount payable on death is based on a decreasing scale.',
		icon: (
			<>
				<img src={MppIcon} alt='mpp icon' width='48' height='48' />
			</>
		),
	},
]

const ProductList = () => {
	return (
		<Grid className='product-list'>
			{products.map(product => (
				<Grid.Column
					key={product.id}
					mobile={16}
					tablet={5}
					computer={4}
					largeScreen={4}
					widescreen={4}
				>
					<ProductCard product={product} fluid />
				</Grid.Column>
			))}
		</Grid>
	)
}

export default ProductList
