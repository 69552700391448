import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import { extraRatingsType2 } from '../../utils'

export const WaiverPremiumExtraRatings = () => {
	const { control } = useFormContext()

	return (
		<Form.Group widths='equal'>
			<Controller
				control={control}
				name='ratings.wp.occrate'
				render={({ value, onChange, onBlur }) => (
					<Form.Select
						label='Occupational Rate'
						fluid
						search
						clearable
						value={value}
						onBlur={onBlur}
						onChange={(event, data) => onChange(data.value)}
						options={extraRatingsType2}
					/>
				)}
			/>
			<Controller
				control={control}
				name='ratings.wp.medrate'
				render={({ value, onChange, onBlur }) => (
					<Form.Select
						fluid
						label='Medical Rate'
						value={value}
						search
						clearable
						onChange={(event, data) => onChange(data.value)}
						onBlur={onBlur}
						options={extraRatingsType2}
					/>
				)}
			/>
			<Controller
				control={control}
				name='ratings.wp.avorate'
				render={({ value, onChange, onBlur }) => (
					<Form.Select
						fluid
						label='Avocation Rate'
						value={value}
						search
						clearable
						onChange={(event, data) => onChange(data.value)}
						onBlur={onBlur}
						options={extraRatingsType2}
					/>
				)}
			/>
		</Form.Group>
	)
}

export default WaiverPremiumExtraRatings
