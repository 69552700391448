import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Button,
	Checkbox,
	Form,
	Header,
	Icon,
	Input,
	Label,
	List,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	extraRatingsType1,
	isRetrenchmenRiderInvalid,
	isSelectedBenefits,
	isTermValid,
	useBenefits,
	useScrollOnPlanErrors,
	useValidateProduct,
} from '../../utils'
import BenefitsContainer from '../benefits/BenefitsContainer'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import TermRider from '../benefits/TermRider'
import { products } from '../product/ProductList'

const initialBenefits = {
	lt: false,
	rr: false,
}

const ClifePlan = ({
	plan,
	personal,
	isLoading,
	onSubmit,
	onPrevious,
	onSkipIllustrate,
	errors: serverErrors,
	clearErrors,
}) => {
	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
		shouldFocusError: true,
	})

	const { handleSubmit, control, errors } = methods

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'clife')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	return (
		<>
			{!!serverErrors.length && (
				<Message
					error
					onDismiss={clearErrors}
					header='There were some errors with your submission'
					list={serverErrors.map(value => value[Object.keys(value)[0]])}
				/>
			)}
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Controller
								control={control}
								name='plan.sum_assured'
								rules={{
									required: 'Enter the sum assured',
									min: {
										value: 75000,
										message: 'Must be between $75,000 and $1,999,999',
									},
									max: {
										value: 1999999,
										message: 'Must be between $75,000 and $1,999,999',
									},
									pattern: {
										value: /^\d+$/,
										message: 'Decimals not allowed',
									},
								}}
								render={({ value, onChange, onBlur }) => (
									<Form.Field error={!!errors?.plan?.sum_assured}>
										<label htmlFor='plan.sum_assured'>Sum Assured</label>
										<Input
											name='plan.sum_assured'
											id='plan.sum_assured'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											type='number'
											step='1'
										/>
										{!!!errors?.plan?.sum_assured && (
											<Label pointing>Must be between $75,000 and $1,999,999</Label>
										)}
										<FieldError errors={errors} name='plan.sum_assured' />
									</Form.Field>
								)}
							/>
							<Header content='Term' />
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.cl.1.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Occupational Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={
												!!errors.ratings?.cl['1']?.occrate && errors.ratings.cl['1'].occrate.message
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.cl.1.medrate'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											label='Medical Rate'
											value={value}
											search
											clearable
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											options={extraRatingsType1}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.cl.1.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Avocation Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={
												!!errors.ratings?.cl['1']?.avorate && errors.ratings.cl['1'].avorate.message
											}
										/>
									)}
								/>
							</Form.Group>

							<Header content='Critical Illness' />
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.cl.0.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Occupational Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={
												!!errors.ratings?.cl['0']?.occrate && errors.ratings.cl['0'].occrate.message
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.cl.0.medrate'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											label='Medical Rate'
											value={value}
											search
											clearable
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											options={extraRatingsType1}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.cl.0.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											label='Avocation Rate'
											type='number'
											step='.25'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={
												!!errors.ratings?.cl['0']?.avorate && errors.ratings.cl['0'].avorate.message
											}
										/>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										checked={benefits.lt}
										disabled={isTermValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, lt: data.checked })}
										label='Level Term'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) && (
									<>
										{benefits.lt && <TermRider issueAge={personal.issue_age} />}
										{benefits.rr && <RetrenchmentRider />}
									</>
								)}
								{!isSelectedBenefits(benefits) && (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>
					<Button
						floated='right'
						primary
						disabled={isLoading}
						loading={isLoading}
						onClick={handleSubmit(onSubmit)}
					>
						Illustrate
					</Button>
					<Button
						floated='right'
						basic
						type='button'
						disabled={isLoading}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default ClifePlan
