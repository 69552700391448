// @flow
import React from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'

const BenefitsContainer = ({ benefitsList, benefitsContent }) => {
	return (
		<>
			<Header>Supplementary Benefits</Header>
			<Grid stackable columns={2}>
				<Grid.Column width={4}>{benefitsList}</Grid.Column>
				<Grid.Column style={benefitsContentStyle} width={12}>
					{benefitsContent}
				</Grid.Column>
			</Grid>
			<Divider hidden section />
		</>
	)
}

const benefitsContentStyle = {
	paddingTop: 0,
}

BenefitsContainer.propType = {}

export default BenefitsContainer
