import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
	Button,
	Divider,
	Form,
	Header,
	Input,
	Label,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import { extraRatingsType1_9, mppPlanOptions, useValidateProduct } from '../../utils'
import { products } from '../product/ProductList'

const MppPlan = ({
	plan,
	personal,
	isLoading,
	onSubmit,
	onPrevious,
	clearErrors,
	errors: serverErrors,
}) => {
	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
	})

	const validatedMppPlanOptions = mppPlanOptions.map(value => {
		if (value.validate(personal.issue_age)) {
			return { ...value, disabled: false }
		}

		return { ...value, disabled: true }
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods

	const getMinSumAssured = age => {
		if (Number(age) >= 50 && Number(age) <= 55) {
			return { value: 25000, message: 'Must be between $25,000 and $3,000,000' }
		}

		return { value: 750000, message: 'Must be between $750,000 and $3,000,000' }
	}

	const getMaxSumAssured = age => {
		if (Number(age) >= 50 && Number(age) <= 55) {
			return { value: 3000000, message: 'Must be between $25,000 and $3,000,000' }
		}

		return { value: 3000000, message: 'Must be between $750,000 and $3,000,000' }
	}

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'mpp')?.validate(personal.issue_age)
	}, [personal.issue_age])

	useValidateProduct(isProductValid)

	return (
		<>
			{!!serverErrors.length && (
				<>
					<Message
						error
						onDismiss={clearErrors}
						header='There were some errors with your submission'
						list={serverErrors.map(value => value[Object.keys(value)[0]])}
					/>
					<Divider hidden />
				</>
			)}
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.sum_assured'
									rules={{
										required: 'Enter sum assured',
										pattern: {
											value: /^\d+$/,
											message: 'Decimals not allowed',
										},
										min: getMinSumAssured(personal.issue_age),
										max: getMaxSumAssured(personal.issue_age),
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors?.plan?.sum_assured}>
											<label htmlFor='plan.sum_assured'>Sum Assured</label>
											<Input
												name='plan.sum_assured'
												id='plan.sum_assured'
												value={value}
												onChange={onChange}
												onBlur={onBlur}
												type='number'
												step='1'
												error={
													!!errors?.plan?.sum_assured && {
														content: errors.plan.sum_assured.message,
													}
												}
											/>
											{!!!errors?.plan?.sum_assured && (
												<Label pointing>{getMinSumAssured(personal.issue_age)?.message}</Label>
											)}
											<FieldError errors={errors} name='plan.sum_assured' />
										</Form.Field>
									)}
								/>
								<Controller
									control={control}
									name='plan.type'
									rules={{
										required: 'Select a plan',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											label='Plan'
											value={value}
											placeholder='Select a plan'
											options={validatedMppPlanOptions}
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											error={errors?.plan?.type?.message}
										/>
									)}
								/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.mpp.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											type='number'
											step='.25'
											label='Occupational Rate'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors.ratings?.mpp?.occrate && errors.ratings.mpp.occrate.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.mpp.medrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											search
											clearable
											value={value}
											onBlur={onBlur}
											label='Medical Rate'
											options={extraRatingsType1_9}
											onChange={(event, data) => onChange(data.value)}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.mpp.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											type='number'
											step='.25'
											label='Avocation Rate'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors.ratings?.mpp?.avorate && errors.ratings.mpp.avorate.message}
										/>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>
					<Divider hidden section />
					<Button
						floated='right'
						primary
						disabled={isLoading}
						loading={isLoading}
						onClick={handleSubmit(onSubmit)}
					>
						Illustrate
					</Button>
					<Button
						floated='right'
						basic
						type='button'
						disabled={isLoading}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default MppPlan
