import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
	Button,
	Checkbox,
	Divider,
	Form,
	Header,
	Icon,
	Input,
	Label,
	List,
	Message,
	Segment,
	SegmentGroup,
} from 'semantic-ui-react'
import FieldError from '../../components/FieldError'
import {
	extraRatingsType1_9,
	isClifeValid,
	isRetrenchmenRiderInvalid,
	isSelectedBenefits,
	termOptions,
	useBenefits,
	useScrollOnPlanErrors,
	useValidateProduct,
} from '../../utils'
import { WaiverPremium } from '../benefits'
import AccidentalDeathRider from '../benefits/AccidentalDeathRider'
import BenefitsContainer from '../benefits/BenefitsContainer'
import ClifeRider from '../benefits/ClifeRider'
import RetrenchmentRider from '../benefits/RetrenchmentRider'
import { products } from '../product/ProductList'
import { termSumAssuredChanged } from './levelTermSlice'

const initialBenefits = {
	ad: false,
	wp: false,
	cl: false,
	rr: false,
}

const isWaiverPremiumInvalid = age => {
	return Number(age) < 20 || Number(age) > 50
}

const isAdValid = age => {
	return Number(age) < 20 || Number(age) > 55
}

const LevelTermPlan = ({
	plan,
	personal,
	isLoading,
	onSubmit,
	onPrevious,
	clearErrors,
	errors: serverErrors,
}) => {
	const dispatch = useDispatch()

	const methods = useForm({
		defaultValues: { ...plan },
		reValidateMode: 'onChange',
	})

	const { handleSubmit, control, setValue, errors } = methods

	const sumAssured = methods.watch('plan.sum_assured', '0')

	const validTermOptions = termOptions.map(v => {
		if (!v.validate(personal.issue_age)) {
			return { key: v.key, text: v.text, value: v.value, disabled: true }
		}

		return { key: v.key, text: v.text, value: v.value, disabled: false }
	})

	const [benefits, setBenefits] = useBenefits(initialBenefits, plan.benefits)

	const isProductValid = useMemo(() => {
		return products.find(v => v?.product === 'term')?.validate(personal.issue_age)
	}, [personal.issue_age])

	const onSumAssuredChanged = () => {
		setValue('benefits.ad.sum_assured', '')
		setValue('ratings.ad.occrate', '0.00')
		setValue('ratings.ad.medrate', '0.00')
		setValue('ratings.ad.avorate', '0.00')
		dispatch(termSumAssuredChanged())
		setBenefits({ ...benefits, ad: false })
	}

	useValidateProduct(isProductValid)

	useScrollOnPlanErrors(errors)

	return (
		<>
			{!!serverErrors.length && (
				<>
					{!!serverErrors.length && (
						<Message
							error
							onDismiss={clearErrors}
							header='There were some errors with your submission'
							list={serverErrors.map(value => value[Object.keys(value)[0]])}
						/>
					)}
					<Divider hidden />
				</>
			)}
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<SegmentGroup>
						<Segment secondary>
							<Header>Plan Info</Header>
						</Segment>
						<Segment>
							<Controller
								control={control}
								name='plan.sum_assured'
								rules={{
									required: 'Enter sum assured',
									min: {
										value: 75000,
										message: 'Must be between $75,000 and $4,999,999',
									},
									max: {
										value: 4999999,
										message: 'Must be between $75,000 and $4,999,999',
									},
									pattern: {
										value: /^\d+$/,
										message: 'Decimals not allowed',
									},
								}}
								render={({ value, onChange, onBlur }, { isTouched }) => (
									<Form.Field error={!!errors?.plan?.sum_assured}>
										<label htmlFor='plan.sum_assured'>Sum Assured</label>
										<Input
											name='plan.sum_assured'
											id='plan.sum_assured'
											type='number'
											step='1'
											value={value}
											onChange={onChange}
											onBlur={event => {
												onBlur(event)

												if (isTouched) {
													onSumAssuredChanged()
												}
											}}
										/>
										{!!!errors?.plan?.sum_assured && (
											<Label pointing>Must be between $75,000 and $4,999,999</Label>
										)}
										<FieldError errors={errors} name='plan.sum_assured' />
									</Form.Field>
								)}
							/>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='plan.term'
									rules={{
										required: 'Select a plan',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											label='Plan'
											value={value}
											onChange={(event, data) => onChange(data.value)}
											onBlur={onBlur}
											options={validTermOptions}
											fluid
											search
											clearable
											error={!!errors?.plan?.term && { content: errors.plan.term.message }}
										/>
									)}
								/>
								<Controller
									control={control}
									name='plan.type'
									rules={{
										required: 'Select a type',
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Field error={!!errors.plan?.type}>
											<label>Type</label>
											<Button.Group fluid>
												<Button
													role='button'
													type='button'
													primary={value === '2'}
													onClick={() => {
														if (value === '2') {
															return onChange('')
														}
														onChange('2')
													}}
													onBlur={onBlur}
												>
													T-Life
												</Button>
												<Button
													role='button'
													type='button'
													primary={value === '1'}
													onClick={() => {
														if (value === '1') {
															return onChange('')
														}
														onChange('1')
													}}
													onBlur={onBlur}
												>
													E-Life
												</Button>
											</Button.Group>
											<FieldError errors={errors} name='plan.type' />
										</Form.Field>
									)}
								/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Controller
									control={control}
									name='ratings.lt.occrate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											type='number'
											step='.25'
											label='Occupational Rate'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors?.ratings?.lt?.occrate && errors.ratings.lt.occrate.message}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.lt.medrate'
									render={({ value, onChange, onBlur }) => (
										<Form.Select
											fluid
											search
											clearable
											value={value}
											onBlur={onBlur}
											label='Medical Rate'
											options={extraRatingsType1_9}
											onChange={(event, data) => onChange(data.value)}
										/>
									)}
								/>
								<Controller
									control={control}
									name='ratings.lt.avorate'
									rules={{
										min: {
											value: 0,
											message: 'Negative value not allowed',
										},
										pattern: {
											value: /^[0-9]*(\.[0-9]{0,2})?$/,
											message: 'Only two decimal placed allowed',
										},
									}}
									render={({ value, onChange, onBlur }) => (
										<Form.Input
											type='number'
											step='.25'
											label='Avocation Rate'
											value={value}
											onChange={onChange}
											onBlur={onBlur}
											fluid
											error={!!errors?.ratings?.lt?.avorate && errors.ratings.lt.avorate.message}
										/>
									)}
								/>
							</Form.Group>
						</Segment>
					</SegmentGroup>
					<Divider hidden />
					<BenefitsContainer
						benefitsList={
							<List relaxed>
								<List.Item>
									<Checkbox
										checked={benefits.ad}
										disabled={isAdValid(personal.issue_age) || !!!sumAssured}
										onChange={(event, data) => setBenefits({ ...benefits, ad: data.checked })}
										label='Accidental Death'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.wp}
										disabled={isWaiverPremiumInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, wp: data.checked })}
										label='Waiver Premium'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.cl}
										disabled={isClifeValid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, cl: data.checked })}
										label='C-Life'
									/>
								</List.Item>
								<List.Item>
									<Checkbox
										checked={benefits.rr}
										disabled={isRetrenchmenRiderInvalid(personal.issue_age)}
										onChange={(event, data) => setBenefits({ ...benefits, rr: data.checked })}
										label='Retrenchment Rider'
									/>
								</List.Item>
							</List>
						}
						benefitsContent={
							<>
								{isSelectedBenefits(benefits) ? (
									<>
										{benefits.ad && <AccidentalDeathRider />}
										{benefits.wp && <WaiverPremium />}
										{benefits.cl && <ClifeRider />}
										{benefits.rr && <RetrenchmentRider />}
									</>
								) : (
									<Segment placeholder>
										<Header icon>
											<Icon name='dont' />
											No benefits selected
										</Header>
									</Segment>
								)}
							</>
						}
					/>
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
						loading={isLoading}
						primary
						floated='right'
					>
						Illustrate
					</Button>
					<Button
						type='button'
						basic
						floated='right'
						disabled={isLoading}
						onClick={onPrevious}
						style={{ marginRight: '1em' }}
					>
						Previous
					</Button>
				</Form>
			</FormProvider>
		</>
	)
}

export default LevelTermPlan
