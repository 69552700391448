import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Divider, Header } from 'semantic-ui-react'
import ProductBreadcrumb from '../../components/ProductBreadcrumb'

const ProductLayout = ({ qid, product, title, breadcrumbTitle }) => {
	return (
		<Container className='page'>
			<ProductBreadcrumb product={product} qid={qid} title={breadcrumbTitle} />
			<Divider hidden />
			<Header size='huge' as='h1'>
				{title}
				{!!qid && <Header.Subheader>{qid}</Header.Subheader>}
			</Header>
			<Divider hidden />
			<Outlet />
		</Container>
	)
}

export default ProductLayout
