import { createSlice } from '@reduxjs/toolkit'
import { merge } from 'lodash'
import { resetProduct } from '../../app/actions'
import { novaApi } from '../../services/novaApi'
import { logout } from '../auth/authSlice'

export const extendedApiSlice = novaApi.injectEndpoints({
	overrideExisting: true,
	endpoints: builder => ({
		getMppIllustration: builder.mutation({
			async queryFn(arg, api, extraOptions, baseQuery) {
				const { auth, mpp } = api.getState()
				const agent = auth.agent
				const is_fass_user = auth.isFassUser
				const current_agent = auth.currentAgent
				const qid = mpp.quote?.qid
				try {
					const response = await baseQuery(
						{
							url: 'mpp',
							method: 'POST',
							body: { ...arg, qid, agent, current_agent, is_fass_user },
						},
						api,
						extraOptions,
					)
					return response
				} catch (error) {
					return error
				}
			},
		}),
	}),
})

export const { useGetMppIllustrationMutation } = extendedApiSlice

const initialState = {
	plan: {
		sum_assured: '',
		type: '',
	},
	ratings: {
		mpp: {
			occrate: '0.00',
			medrate: '0.00',
			avorate: '0.00',
		},
	},
	quote: null,
	is_saved: false,
}

const mppSlice = createSlice({
	name: 'mpp',
	initialState,
	reducers: {
		mppPlanUpdated: (state, action) => {
			let newState = merge(state, action.payload)

			// prevents resetting quote when illustration is in an error state
			if (!!!newState.quote && !!state.quote) {
				newState.quote = { ...state.quote }
			}

			return newState
		},
	},
	extraReducers: builder => {
		builder
			.addCase(resetProduct, () => {
				return initialState
			})
			.addCase(logout, state => initialState)
	},
})

export const { mppPlanUpdated } = mppSlice.actions
export const selectMpp = state => state.mpp
export default mppSlice.reducer
