import React from 'react'
import { Container } from 'semantic-ui-react'

const Help = () => {
	return (
		<Container className='page'>
			<iframe
				src='https://app.tango.us/app/embed/3d4aeb3f-b93d-4fe0-b8f4-6b23ad106029?iframe'
				sandbox='allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin'
				security='restricted'
				title='Tango Workflow'
				width='100%'
				height='100%'
				referrerpolicy='strict-origin-when-cross-origin'
				frameborder='0'
				webkitallowfullscreen='webkitallowfullscreen'
				mozallowfullscreen='mozallowfullscreen'
				allowfullscreen='allowfullscreen'
			></iframe>
		</Container>
	)
}

export default Help
