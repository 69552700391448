import React, { useState } from 'react'
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import PersonalForm from '../personal/PersonalForm'
import TriflexAnnuityPlan from './TriflexAnnuityPlan'
import { useDispatch, useSelector } from 'react-redux'
import { personalFormUpdated, selectPersonal } from '../personal/personalSlice'
import {
	selectTriflexAnnuity,
	triflexAnnuityQuoteUpdated,
	useGetTriflexAnnuityIllustrationMutation,
	triflexAnnuityFormUpdated,
} from './triflexAnnuitySlice'
import { toast } from 'react-toastify'
import { resetProduct } from '../../app/actions'
import TriflexAnnuitySummary from './TriflexAnnuitySummary'
import { useScrollOnErrors } from '../../utils'
import ProductLayout from '../product/ProductLayout'

const TriflexAnnuity = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const personalFormValues = useSelector(selectPersonal)
	const planValues = useSelector(selectTriflexAnnuity)
	const [errors, setErrors] = useState([])
	const productName = 'triflex-annuity'

	const [illustrate, { isLoading, isError }] = useGetTriflexAnnuityIllustrationMutation({
		fixedCacheKey: 'triflex-annuity-quote',
	})

	const onPersonalSubmit = values => {
		dispatch(personalFormUpdated(values))
		navigate('/product/triflex-annuity/plan')
	}

	const onPersonalCancel = () => navigate('/')

	const onPlanSubmit = async values => {
		const qid = planValues.quote?.qid || ''

		// clear errors
		setErrors([])

		// update store
		dispatch(triflexAnnuityFormUpdated({ ...values, is_saved: planValues.is_saved }))

		try {
			const quote = await illustrate({ ...values, ...personalFormValues, qid }).unwrap()

			dispatch(triflexAnnuityQuoteUpdated({ quote }))

			navigate('/product/triflex-annuity/summary')
		} catch (err) {
			if (err?.status !== 400) {
				toast.error('Server error, please contact technology services')
			}

			if (err?.status === 400) {
				toast.error('There were some errors with your submission.')
			}

			if (err?.data) {
				const { messages, qid, frequency_premium, annual_premium } = err.data

				if (qid) {
					dispatch(triflexAnnuityQuoteUpdated({ qid, frequency_premium, annual_premium }))
				}

				setErrors(messages ?? [])
			}
		}
	}

	const onPlanPrevious = () => {
		dispatch(resetProduct())
		setErrors([])
		navigate('/product/triflex-annuity')
	}

	const onSummaryBack = () => {
		navigate('/product/triflex-annuity/plan')
	}

	useScrollOnErrors(isError, errors)

	return (
		<Routes>
			<Route
				path='/'
				element={
					<ProductLayout
						qid={planValues.quote?.qid}
						product={productName}
						title='Triflex Annuity'
						breadcrumbTitle='Triflex Annuity'
					/>
				}
			>
				<Route
					index
					element={
						<PersonalForm
							product={productName}
							onNext={onPersonalSubmit}
							onCancel={onPersonalCancel}
							defaultValues={personalFormValues}
						/>
					}
				/>
				<Route
					path='plan'
					element={
						<TriflexAnnuityPlan
							personal={personalFormValues}
							plan={planValues}
							onPrevious={onPlanPrevious}
							onSubmit={onPlanSubmit}
							isLoading={isLoading}
							errors={errors}
							clearErrors={() => setErrors([])}
						/>
					}
				/>
				<Route
					path='summary'
					element={
						<TriflexAnnuitySummary
							personal={personalFormValues}
							values={planValues}
							onBack={onSummaryBack}
						/>
					}
				/>
				<Route path='*' element={<Navigate to='/product/triflex-annuity' replace={true} />} />
			</Route>
		</Routes>
	)
}

export default TriflexAnnuity
