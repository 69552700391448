import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, Header, Label } from 'semantic-ui-react'
import { resetProduct } from '../../app/actions'
import './ProductCard.css'

const ProductCard = ({ product, fluid }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const issueAge = useSelector(state => state.personal.issue_age)
	const disabled = !product.validate(issueAge)
	const onClick = () => {
		if (!disabled) {
			dispatch(resetProduct())
			navigate(product.path)
		}
	}
	return (
		<Card className={`product-card ${disabled ? 'disabled' : ''}`} fluid={fluid} onClick={onClick}>
			<Card.Content>
				{product.icon}
				<Header as='h3' style={{ marginTop: '1rem' }}>
					{product.title}
				</Header>
				<Card.Description>{product.description}</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<Header sub>Ages</Header>
				<Label circular>{product.ages}</Label>
			</Card.Content>
		</Card>
	)
}

export default ProductCard
